import { IApplicative, IFunctor, IMonad } from '../control'
import { Just, Maybe, Nothing } from './Maybe'

// NOTE: List should also be a subclass of ICaseOf
// TODO: add implementations for ICaseOf
export class Queue<T> implements IFunctor<T>, IApplicative<T>, IMonad<T> {
  static of<S>(xs: S[]): Queue<S> {
    return new Queue<S>(xs)
  }

  readonly tag: string = 'Queue'
  private readonly data: T[] = []

  constructor(ts: T[]) {
    this.data = ts
  }

  public map<S = T>(f: (val: T) => S): Queue<S> {
    return new Queue(this.data.map(f))
  }

  public ap<S = T>(f: Queue<(val: T) => S>): Queue<S> {
    return new Queue<S>(f.data.reduce((result: S[], func) =>
      result.concat(this.data.map(func)).reduce((flat: S[], ap) =>
        flat.concat(ap), []), []))
  }

  public chain<S = T>(f: (val: T) => Queue<S>): Queue<S> {
    return new Queue<S>(this.data.reduce((result: S[], val) =>
      result.concat(f(val).data), []))
  }

  public isEmpty() { return this.data.length === 0 }

  public peek(): Maybe<T> {
    if (this.isEmpty()) {
      return new Nothing()
    } else {
      return new Just(this.data[this.data.length - 1])
    }
  }

  public enqueue(element: T): Queue<T> {
    return new Queue([element].concat(this.data))
  }

  public dequeue(): [Queue<T>, Maybe<T>] {
    if (this.isEmpty()) {
      return [this, new Nothing()]
    } else {
      return [new Queue(this.data.slice(0, -1)), this.peek()]
    }
  }
}
