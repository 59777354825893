import { Context, World } from '@cog/ecs'

type Plugin<C extends Context> = {
  Create(world: World<C>): void
  Delete(world: World<C>): void
}

export abstract class Scene<C extends Context> {
  protected world!: World<C>
  private dependencies: Set<Plugin<C>> = new Set()

  public create(world: World<C>) {}
  public delete(world: World<C>) {}

  public using(...plugins: Array<Plugin<C>>) {
    for (const plugin of plugins) {
      plugin.Create(this.world)
      this.dependencies.add(plugin)
    }
  }

  public performCreate(world: World<C>) {
    this.create(world)
  }

  public performDelete(world: World<C>) {
    this.dependencies.forEach((dep) => dep.Delete(world))
    this.delete(world)
  }
}
