import { Vec3 } from './Vec3'

export class AABBox {
  private readonly min: Vec3
  private readonly max: Vec3

  constructor(min: Vec3, max: Vec3) {
    this.min = new Vec3(min.x < max.x ? min.x : max.x, min.y < max.y ? min.y : max.y, min.z < max.z ? min.z : max.z)
    this.max = new Vec3(min.x > max.x ? min.x : max.x, min.y > max.y ? min.y : max.y, min.z > max.z ? min.z : max.z)
  }

  public intersects(other: AABBox): boolean {
    return !(
      (this.min.x > other.max.x) ||
      (this.max.x < other.min.x) ||
      (this.min.y > other.max.y) ||
      (this.max.y < other.min.y) ||
      (this.min.z > other.max.z) ||
      (this.max.z < other.min.z)
    )
  }

  public intersection(other: AABBox): AABBox | null {
    if (this.intersects(other)) {
      return new AABBox(
        new Vec3(Math.max(this.min.x, other.min.x), Math.max(this.min.y, other.min.y), Math.max(this.min.z, other.min.z)),
        new Vec3(Math.min(this.max.x, other.max.x), Math.min(this.max.y, other.max.y), Math.min(this.max.z, other.max.z)),
      )
    } else {
      return null
    }
  }

  public contains(other: AABBox): boolean {
    return (
      (other.min.x >= this.min.x) &&
      (other.max.x <= this.max.x) &&
      (other.min.y >= this.min.y) &&
      (other.max.y <= this.max.y) &&
      (other.min.z >= this.min.z) &&
      (other.max.z <= this.max.z)
    )
  }

  public split(): Readonly<[AABBox, AABBox, AABBox, AABBox, AABBox, AABBox, AABBox, AABBox]> {
    const { x: left,  y: bottom, z: inFront } = this.min
    const { x: right, y: top,    z: behind  } = this.max
    const { x, y, z } = this.center()

    return [
      new AABBox(new Vec3(x,         y,      z), new Vec3(right, top, inFront)),
      new AABBox(new Vec3(x,         y, behind), new Vec3(right, top,       z)),
      new AABBox(new Vec3(left,      y, behind), new Vec3(    x, top,       z)),
      new AABBox(new Vec3(left,      y,      z), new Vec3(    x, top, inFront)),
      new AABBox(new Vec3(x,    bottom,      z), new Vec3(right,   y, inFront)),
      new AABBox(new Vec3(x,    bottom, behind), new Vec3(right,   y,       z)),
      new AABBox(new Vec3(left, bottom, behind), new Vec3(    x,   y,       z)),
      new AABBox(new Vec3(left, bottom,      z), new Vec3(    x,   y, inFront)),
    ]
  }

  public center(): Vec3 {
    return this.min.add(this.max).scale(0.5)
  }
}
