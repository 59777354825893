import { CanvasContext } from '@cog/ecs-canvas-2d-plugin'

import { Vec2 } from '@cog/math'
import { EndEffector } from '../components/EndEffector'
import { Joint } from '../components/Joint'
import { KeyboardMovedTag, MouseMovedTag, RandomWiggle } from '../components/Tags'


export const controlEndEffectorWithMouse = (ctx: CanvasContext, [endEffector]: [EndEffector, MouseMovedTag]) => {
  endEffector.position = CanvasContext.GetMousePos(ctx)
}

export const controlEndEffectorWithKeyboard = (ctx: CanvasContext, [endEffector]: [EndEffector, KeyboardMovedTag]) => {
  const dt = ctx.timer.delta
  const keyboard = ctx.io.keyboard
  let dx = 0
  let dy = 0
  if (keyboard.key('w').isDown) { dy -= 30.0 * dt }
  if (keyboard.key('a').isDown) { dx -= 30.0 * dt }
  if (keyboard.key('s').isDown) { dy += 30.0 * dt }
  if (keyboard.key('d').isDown) { dx += 30.0 * dt }

  if (dx || dy) {
    endEffector.position = endEffector.position.add(new Vec2(dx, dy))
  }
}

export const controlEndEffectorWithRandomWiggle = (ctx: CanvasContext, [endEffector]: [EndEffector, RandomWiggle]) => {
  const dt = ctx.timer.delta
  const dx = 40.0 * dt * (Math.random() * 2.0 - 1.0)
  const dy = 40.0 * dt * (Math.random() * 2.0 - 1.0)
  endEffector.position = endEffector.position.add(new Vec2(dx, dy))
}

export const renderEndEffector = (ctx: CanvasContext, [endEffector]: [EndEffector]) => {
  ctx.world.queryEntity([Joint], endEffector.joint, (_, [joint]) => {
    const g = ctx.graphics.gfx
    g.strokeStyle = 'rgba(255, 0, 0, 1.0)'
    g.beginPath()
    g.moveTo(endEffector.position.x, endEffector.position.y)
    g.arc(endEffector.position.x, endEffector.position.y, 2, 0, Math.PI * 2)
    g.stroke()

    if (joint.position.distSq(endEffector.position) > 1) {
      g.save()
      g.beginPath()
      g.setLineDash([2, 2])
      g.moveTo(endEffector.position.x, endEffector.position.y)
      g.lineTo(joint.position.x, joint.position.y)
      g.stroke()
      g.restore()
    }
  })
}
