import { Vec3 } from './Vec3'


export class BoundingSphere {
  constructor(
    public readonly position: Vec3,
    public readonly radius: number,
  ) {}

  public intersects(other: BoundingSphere) {
    return this.position.dist(other.position) <= this.radius + other.radius
  }
}
