import { World } from '@cog/ecs'
import { CanvasContext, HEIGHT, WIDTH } from '@cog/ecs-canvas-2d-plugin'
import { Vec2 } from '@cog/math'

import { Material, Physics, Transform } from '../Components/Common'
import { PaddleProps } from '../Components/Paddle'
import { Paddle } from '../Components/Tags'


export const makePaddle = (
  world: World<CanvasContext>,
  position: Vec2 = new Vec2(WIDTH >> 1, HEIGHT - 20),
  physics: Physics = new Physics(Vec2.Null(), 160.0),
  props: PaddleProps = new PaddleProps(40.0),
  material: Material = new Material(255, 255, 255),
) => world.entity(
  [Transform, Physics, PaddleProps, Material, Paddle],
  (args) => {
    args[0].position = position
    args[1].speed = physics.speed
    args[1].velocity = physics.velocity
    args[2].width = props.width
    args[3].r = material.r
    args[3].g = material.g
    args[3].b = material.b
  },
)

export const deletePaddle = (world: World<CanvasContext>) =>
  world.deleteWhere([Transform, Physics, PaddleProps, Material, Paddle], () => true)