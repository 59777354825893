import { Vec2 } from './Vec2'
import { Vec3 } from './Vec3'


export class Mat3 {

  public static Null(): Readonly<Mat3> {
    return NULL
  }

  public static Identity(): Readonly<Mat3> {
    return IDENTITY
  }

  public static Rotation2d(angle: number): Readonly<Mat3> {
    const a = angle / 180 * Math.PI
    const cosA = Math.cos(a)
    const sinA = Math.sin(a)

    return new Mat3(
      cosA, -sinA, 0,
      sinA, cosA, 0,
      0, 0, 1,
    )
  }

  public static Translation2d(pos: Vec2): Readonly<Mat3> {
    return new Mat3(
      1, 0, 0,
      0, 1, 0,
      pos.x, pos.y, 1,
    )
  }

  public static Scale2d(s: number, t: number): Readonly<Mat3> {
    return new Mat3(
      s, 0, 0,
      0, t, 0,
      0, 0, 1,
    )
  }

  public static Rotation3d(axis: Vec3, angle: number): Readonly<Mat3> {
    const a = angle / 180 * Math.PI
    const normalizedAxis = axis.normalize()
    const c = Math.cos(a)
    const s = Math.sin(a)
    const onec = 1.0 - c
    const u = normalizedAxis.x
    const v = normalizedAxis.y
    const w = normalizedAxis.z

    return new Mat3(
      u * u + (1 - u * u) * c, u * v * onec - w * s, u * w * onec + v * s,
      u * v * onec + w * s, v * v + (1 - v * v) * c, v * w * onec - u * s,
      u * w * onec - v * s, v * w * onec + u * s, w * w + (1 - w * w) * c,
    )
  }

  public static Scale3d(s: number, t: number, u: number): Readonly<Mat3> {
    return new Mat3(
      s, 0, 0,
      0, t, 0,
      0, 0, u,
    )
  }

  public static Reflection2d(pos: Vec2, normal: Vec2): Readonly<Mat3> {
    const translateToOrigin = Mat3.Translation2d(pos)
    const translateBack = Mat3.Translation2d(Vec2.Null().sub(pos))
    const n = normal.normalize()
    const reflection = new Mat3(
      1 - 2 * n.x * n.x, -2 * n.x * n.y, 0,
      -2 * n.x * n.y, 1 - 2 * n.y * n.y, 0,
      0, 0, 1,
    )

    return translateToOrigin.mul(reflection).mul(translateBack)
  }

  public readonly m: [
    number, number, number,
    number, number, number,
    number, number, number
  ]

  constructor(
    a00: number = 1, a01: number = 0, a02: number = 0,
    a10: number = 0, a11: number = 1, a12: number = 0,
    a20: number = 0, a21: number = 0, a22: number = 1,
  ) {
    this.m = [a00, a01, a02, a10, a11, a12, a20, a21, a22]
  }

  public transpose(): Readonly<Mat3> {
    return new Mat3(
      this.m[0], this.m[3], this.m[6],
      this.m[1], this.m[4], this.m[7],
      this.m[2], this.m[5], this.m[8],
    )
  }

  public inverse(): Readonly<Mat3> {
    const d00 = this.m[4] * this.m[8] - this.m[5] * this.m[7]
    const d01 = this.m[3] * this.m[8] - this.m[5] * this.m[6]
    const d02 = this.m[3] * this.m[7] - this.m[4] * this.m[6]

    const d10 = this.m[1] * this.m[8] - this.m[2] * this.m[7]
    const d11 = this.m[0] * this.m[8] - this.m[2] * this.m[6]
    const d12 = this.m[0] * this.m[7] - this.m[1] * this.m[6]

    const d20 = this.m[1] * this.m[5] - this.m[2] * this.m[4]
    const d21 = this.m[0] * this.m[5] - this.m[2] * this.m[6]
    const d22 = this.m[0] * this.m[4] - this.m[1] * this.m[3]

    const d = 1.0 / (this.m[0] * d00 + this.m[1] * d01 + this.m[2] * d02)

    return new Mat3(
      +d00 * d, -d10 * d, +d20 * d,
      -d01 * d, +d11 * d, -d21 * d,
      +d02 * d, -d12 * d, +d22 * d,
    )
  }

  public mul(other: Mat3): Readonly<Mat3> {
    return new Mat3(
      this.m[0] * other.m[0] + this.m[3] * other.m[1] + this.m[6] * other.m[2],
      this.m[1] * other.m[0] + this.m[4] * other.m[1] + this.m[7] * other.m[2],
      this.m[2] * other.m[0] + this.m[5] * other.m[1] + this.m[8] * other.m[2],

      this.m[0] * other.m[3] + this.m[3] * other.m[4] + this.m[6] * other.m[5],
      this.m[1] * other.m[3] + this.m[4] * other.m[4] + this.m[7] * other.m[5],
      this.m[2] * other.m[3] + this.m[5] * other.m[4] + this.m[8] * other.m[5],

      this.m[0] * other.m[6] + this.m[3] * other.m[7] + this.m[6] * other.m[8],
      this.m[1] * other.m[6] + this.m[4] * other.m[7] + this.m[7] * other.m[8],
      this.m[2] * other.m[6] + this.m[5] * other.m[7] + this.m[8] * other.m[8],
    )
  }

  public transform2d(vec: Vec2): Readonly<Vec2> {
    return new Vec2(
      this.m[0] * vec.x + this.m[3] * vec.y + this.m[6],
      this.m[1] * vec.x + this.m[4] * vec.y + this.m[7],
    )
  }

  public transformBatch2d(vectors: Vec2[]): Readonly<Vec2[]> {
    return vectors.map((vec) => new Vec2(
      this.m[0] * vec.x + this.m[3] * vec.y + this.m[6],
      this.m[1] * vec.x + this.m[4] * vec.y + this.m[7],
    ))
  }

  public transform3d(vec: Vec3): Readonly<Vec3> {
    return new Vec3(
      this.m[0] * vec.x + this.m[3] * vec.y + this.m[6] * vec.z,
      this.m[1] * vec.x + this.m[4] * vec.y + this.m[7] * vec.z,
      this.m[2] * vec.x + this.m[5] * vec.y + this.m[8] * vec.z,
    )
  }

  public transformBatch3d(vectors: Vec3[]): Readonly<Vec3[]> {
    return vectors.map((vec) => new Vec3(
      this.m[0] * vec.x + this.m[3] * vec.y + this.m[6] * vec.z,
      this.m[1] * vec.x + this.m[4] * vec.y + this.m[7] * vec.z,
      this.m[2] * vec.x + this.m[5] * vec.y + this.m[8] * vec.z,
    ))
  }
}

const NULL = Object.freeze(new Mat3(0, 0, 0, 0, 0, 0, 0, 0, 0))
const IDENTITY = Object.freeze(new Mat3())
