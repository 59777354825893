import { World } from '@cog/ecs'

import { CanvasContext } from '../../Context/CanvasContext'
import { Label } from '../Components/Label'
import { State } from '../Components/State'
import { Style } from '../Components/Style'


export const makeLabel = <C extends CanvasContext>(
  world: World<C>,
  props: Partial<{ style: Partial<Parameters<typeof Style.prototype.init>[0]>, label: Partial<Parameters<typeof Label.prototype.init>[0]> }>,
) =>
  world.entity([Style, Label, State], (args) => {
    const style = args[0]
    const label = args[1]
    const state = args[2]

    style.init(props.style)
    label.init(props.label)
    state.init()
  })
