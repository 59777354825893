import { Reference, World } from '@cog/ecs'

import { CanvasContext } from '../Context/CanvasContext'
import { Button } from './Components/Button'
import { ElementState, State } from './Components/State'
import { Style } from './Components/Style/Style'
import { makeButton } from './Entities/button'
import { makeLabel } from './Entities/label'
import { makePane } from './Entities/pane'
import { GuiRepository } from './GuiRepository'
import { buttonSystem } from './Systems/buttonSystem'
import { render } from './Systems/render'
import { stateSystem } from './Systems/stateSystem'

const GUI_PRIORITY = -10000
const GUI_RENDER_PRIORITY = 10000

type GuiElementApi = {
  readonly Button: typeof makeButton,
  readonly Label: typeof makeLabel,
  readonly Pane: typeof makePane,

  readonly enable: (world: World<any>, id: number) => void
  readonly disable: (world: World<any>, id: number) => void
}

const enableElement = (ref: Reference<[typeof Style, typeof State]>) => ref.components[1].state = ElementState.Idle
const disableElement = (ref: Reference<[typeof Style, typeof State]>) => ref.components[1].state = ElementState.Disabled

export class Gui {
  static readonly Element: GuiElementApi = {
    Button: makeButton,
    Label: makeLabel,
    Pane: makePane,

    disable: <C extends CanvasContext>(world: World<C>, id: number) => world.reference([Style, State], id).map(disableElement),
    enable: <C extends CanvasContext>(world: World<C>, id: number) => world.reference([Style, State], id).map(enableElement),
  }

  static readonly Repository = GuiRepository

  static Clear = <C extends CanvasContext>(world: World<C>) => {
    world.deleteWhere([Style], () => true)
  }

  static Create = <C extends CanvasContext>(world: World<C>) => {
    world.task.on.after(render, GUI_RENDER_PRIORITY)

    world.system.on.before([Style, State], stateSystem, GUI_PRIORITY)
    world.system.on.before([Button, State, Style], buttonSystem, GUI_PRIORITY + 0x1)
  }

  static Enable = <C extends CanvasContext>(world: World<C>) => {
    world.system.on.before([Style, State], stateSystem, GUI_PRIORITY)
    world.system.on.before([Button, State, Style], buttonSystem, GUI_PRIORITY + 0x1)
  }

  static Disable = <C extends CanvasContext>(world: World<C>) => {
    world.system.delete(stateSystem)
    world.system.delete(buttonSystem)
  }

  static Delete = <C extends CanvasContext>(world: World<C>) => {
    Gui.Clear(world)

    world.task.delete(render)
    world.system.delete(stateSystem)
    world.system.delete(buttonSystem)
  }
}
