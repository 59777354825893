import { Vec2 } from './Vec2'

const INSIDE = 0 // 0x0000
const LEFT = 1 // 0x0001
const RIGHT = 2 // 0x0010
const BOTTOM = 4 // 0x0100
const TOP = 8 // 0x1000
type ClipPositionCode = typeof INSIDE | typeof LEFT | typeof RIGHT | typeof BOTTOM | typeof TOP

const computeClipPositionCode = (vec: Vec2, rect: AABRect): ClipPositionCode => {
  let code: ClipPositionCode = INSIDE

  // to the left of clip window
  if (vec.x < rect.min.x) {
    code |= LEFT
    // to the right of clip window
  } else if (vec.x > rect.max.x) {
    code |= RIGHT
  }

  // below the clip window
  if (vec.y < rect.min.y) {
    code |= BOTTOM
    // above the clip window
  } else if (vec.y > rect.max.y) {
    code |= TOP
  }

  return code as ClipPositionCode
}

export class AABRect {
  public readonly min: Vec2
  public readonly max: Vec2

  constructor(min: Vec2, max: Vec2) {
    this.min = new Vec2(min.x < max.x ? min.x : max.x, min.y < max.y ? min.y : max.y)
    this.max = new Vec2(min.x > max.x ? min.x : max.x, min.y > max.y ? min.y : max.y)
  }

  public get width() { return this.max.x - this.min.x }
  public get height() { return this.max.y - this.min.y }

  public intersects(other: AABRect): boolean {
    return (
      (this.min.x <= other.max.x) &&
      (this.max.x >= other.min.x) &&
      (this.min.y <= other.max.y) &&
      (this.max.y >= other.min.y)
    )
  }

  public intersection(other: AABRect): Readonly<AABRect> | null {
    if (this.intersects(other)) {
      return new AABRect(
        new Vec2(Math.max(this.min.x, other.min.x), Math.max(this.min.y, other.min.y)),
        new Vec2(Math.min(this.max.x, other.max.x), Math.min(this.max.y, other.max.y)),
      )
    } else {
      return null
    }
  }

  public contains(other: AABRect): boolean {
    return (
      (other.min.x >= this.min.x) &&
      (other.max.x <= this.max.x) &&
      (other.min.y >= this.min.y) &&
      (other.max.y <= this.max.y)
    )
  }

  public clipLine(src: Vec2, dst: Vec2): Readonly<[Vec2, Vec2]> | null {
    // Cohen-Shuterland line-clip in 2d real-vector space
    let x
    let y
    let endpointOut
    let s = new Vec2(src.x, src.y)
    let d = new Vec2(dst.x, dst.y)
    let accept = false
    let scode = computeClipPositionCode(src, this) // source point position code
    let dcode = computeClipPositionCode(dst, this) // destination point position code;

    while (true) { // eslint-disable-line no-constant-condition
      // source and destination points are inside clipping rect - accept and finish
      if (!(scode | dcode)) {
        accept = true
        break

        // source and destination points are outside clipping rect
        // and are not instersecting clipping rectangle - reject and finish
      } else if (scode & dcode) {
        break

        // failed both tests, so calculate the line segment to clip
        // from an outside point to an intersection with clip edge
      } else {
        // At least one endpoint is outside the clip rectangle; pick it.
        endpointOut = scode || dcode

        // Now find the intersection point;
        // use formulas y = y0 + slope * (x - x0), x = x0 + (1 / slope) * (y - y0)
        if (endpointOut & TOP) { // point is above the clip rectangle
          x = s.x + (d.x - s.x) * (this.max.y - s.y) / (d.y - s.y)
          y = this.max.y
        } else if (endpointOut & BOTTOM) { // point is below the clip rectangle
          x = s.x + (d.x - s.x) * (this.min.y - s.y) / (d.y - s.y)
          y = this.min.y
        } else if (endpointOut & RIGHT) { // point is to the right of clip rectangle
          y = s.y + (d.y - s.y) * (this.max.x - s.x) / (d.x - s.x)
          x = this.max.x
        } else if (endpointOut & LEFT) { // point is to the left of clip rectangle
          y = s.y + (d.y - s.y) * (this.min.x - s.x) / (d.x - s.x)
          x = this.min.x
        }

        // Now we move outside point to intersection point to clip
        // and get ready for next pass.
        if (endpointOut === scode) {
          s = new Vec2(x, y)
          scode = computeClipPositionCode(s, this)
        } else {
          d = new Vec2(x, y)
          dcode = computeClipPositionCode(d, this)
        }
      }
    }
    if (accept && s.dist(d) > 0) {
      return [s, d]
    } else {
      return null
    }
  }

  public split(): [AABRect, AABRect, AABRect, AABRect] {
    const { x: l, y: t } = this.min
    const { x: r, y: b } = this.max
    const { x, y } = this.center()

    return [
      new AABRect(new Vec2(l, t), new Vec2(x, y)),
      new AABRect(new Vec2(x, t), new Vec2(r, y)),
      new AABRect(new Vec2(x, y), new Vec2(r, b)),
      new AABRect(new Vec2(l, y), new Vec2(x, b)),
    ]
  }

  public center(): Vec2 {
    return this.min.add(this.max).scale(0.5)
  }
}
