import { PositionMode, Style } from './Components/Style'

export { Gui } from './Gui'
export { GuiRepository } from './GuiRepository'
export { Button } from './Components/Button'
export { State } from './Components/State'
export { Style, PositionMode }

// tslint:disable-next-line:interface-name
export interface Styles extends NonNullable<Partial<Parameters<typeof Style.prototype.init>[0]>> {}
