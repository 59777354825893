import { IApplicative, IFunctor, IMonad } from '../control'

// NOTE: List should also be a subclass of ICaseOf
// TODO: add implementations for ICaseOf
export class List<T> implements IFunctor<T>, IApplicative<T>, IMonad<T> {
  static of<S>(xs: S[]): List<S> {
    return new List<S>(xs)
  }

  public readonly tag: string = 'List'

  private readonly data: T[] = []

  constructor(xs: T[]) {
    this.data = xs
  }

  public map<S = T>(f: (val: T) => S): List<S> {
    return new List(this.data.map(f))
  }

  public ap<S = T>(f: List<(val: T) => S>): List<S> {
    return new List<S>(f.data.reduce((result: S[], func) =>
      result.concat(this.data.map(func)).reduce((flat: S[], ap) =>
        flat.concat(ap), []), []))
  }

  public chain<S = T>(f: (val: T) => List<S>): List<S> {
    return new List<S>(this.data.reduce((result: S[], val) =>
      result.concat(f(val).data), []))
  }
}
