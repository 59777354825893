import { World } from '@cog/ecs'
import {
  CanvasContext,
  Gui,
  PositionMode,
  Scene,
  Sequence,
  Style,
  Timers,
  Transitions,
} from '@cog/ecs-canvas-2d-plugin'

import { Resource } from '../Resource'
import { clearScreen } from '../commonSystems'

import {
  ClassType,
  FadeFromBlack,
  FadeToBlack,
  ReplaceCurrentScene,
} from '../Actions'
import { SceneRegistry } from '../sceneRegistry'
import { background, defaultIdleStyle, defaultModifiers } from './commonStyles'

export class MainMenu extends Scene<CanvasContext> {
  public create(world: World<CanvasContext>) {
    Gui.Create(world)
    Timers.Create(world)
    Transitions.Create(world)

    world.task.on.before(clearScreen)

    const overlay = Gui.Element.Pane(world, { style: { ...background, idle: { ...background.idle, background: { color: 0xff }}} })
    world.context.actionManager.run(new FadeFromBlack(overlay))


    Gui.Element.Pane(world, { style: { idle: { background: { sprite: Resource.Sprite.NinePatch.Waterfall }, position: { mode: PositionMode.Relative, right: 1.0, bottom: 1.0 } } } })

    const onEnterEffect = (ctx: CanvasContext, entity: number) => {
      Transitions.Stop(world, entity)
      ctx.world.reference([Style], entity).map((reference) => {
        Transitions.Transition(ctx.world, {
          duration: 0.1,
          mutation: (t, ref) => {
            const tt = t ** 2
            ref.components[0].idle.position.rightOffset = tt * 8
          },
          reference,
        })
      })
    }

    const onLeaveEffect = (ctx: CanvasContext, entity: number) => {
      Transitions.Stop(world, entity)
      ctx.world.reference([Style], entity).map((reference) => {
        Transitions.Transition(ctx.world, {
          duration: 0.1,
          mutation: (t, ref) => {
            const style = ref.components[0]
            const tt = (1.0 - t) ** 2
            style.idle.position.rightOffset = tt * 8

          },
          reference,
        })
      })
    }

    const makeOnClick = (scene: ClassType<Scene<CanvasContext>>) => () => {
      world.context.actionManager.run(
        new Sequence([
          new FadeToBlack(overlay),
          new ReplaceCurrentScene(scene),
        ]),
      )
    }

    ;[
      { text: 'Basic @cog/ecs example', onClick: makeOnClick(SceneRegistry.BasicEcsScene) },
      { text: '2d physics with @cog/ecs example', onClick: makeOnClick(SceneRegistry.PhysicsECSExample) },
      { text: 'Arkanoid example', onClick: makeOnClick(SceneRegistry.ArkanoidExample) },
      { text: 'Cave example', onClick: makeOnClick(SceneRegistry.Cave) },
      { text: 'IK example', onClick: makeOnClick(SceneRegistry.IK) },
      { text: 'IK Procedural Biped', onClick: makeOnClick(SceneRegistry.IKProceduralBiped) },
    ].reduce((x: number, { text, onClick}) => {
        Gui.Element.Button(world, {
          label: { text },
          onClick,
          style: { ...defaultModifiers, idle: {
            ...defaultIdleStyle().idle,
            position: { left: 10, top: x, right: 180, bottom: x + 20 },
          }},

          onEnter: (ctx, entity) => {
            onEnterEffect(ctx, entity)
          },
          onLeave: (ctx, entity) => {
            onLeaveEffect(ctx, entity)
          },
        })
        return x + 20
      },
      20,
    )
  }

  public delete(world: World<CanvasContext>) {
    world.task.delete(clearScreen)

    Timers.Delete(world)
    Gui.Delete(world)
  }
}
