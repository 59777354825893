import { IApplicative, ICaseOf, IFunctor, IMonad } from '../control'

enum MaybeTypes {
  Just = 'Just',
  Nothing = 'Nothing',
}

interface IMaybePatterns<T, S> {
  Just: (val: T) => S | Maybe<S>,
  Nothing: () => S | Maybe<S>
}

export abstract class Maybe<T> implements IFunctor<T>, IApplicative<T>, IMonad<T>, ICaseOf<T> {
  readonly tag: string = 'Maybe'
  abstract map<S = T>(f: (val: T) => S): Maybe<S>
  abstract ap<S = T>(f: Maybe<(val: T) => S>): Maybe<S>
  abstract chain<S = T>(f: (val: T) => Maybe<S>): Maybe<S>
  abstract caseOf<S = T>(patterns?: IMaybePatterns<T, S>): Maybe<S>
  abstract extract(): Readonly<T>

  isNothing() { return this.tag === MaybeTypes.Nothing }
  isJust() { return this.tag === MaybeTypes.Just }
}

let CACHED_NOTHING: Nothing<any>
class Nothing<T> extends Maybe<T> {
  static of<S>() {
    return CACHED_NOTHING as Nothing<S>
  }

  readonly tag: string = MaybeTypes.Nothing

  constructor() {
    super()
    return CACHED_NOTHING as Nothing<T>
  }

  map<S = T>(f: (val: T) => S): Maybe<S> {
    return (this as any) as Nothing<S>
  }

  ap<S = T>(f: Maybe<(val: T) => S>): Maybe<S> {
    return (this as any) as Nothing<S>
  }

  chain<S = T>(f: (val: T) => Maybe<S>): Maybe<S> {
    return (this as any) as Nothing<S>
  }

  caseOf<S = T>(patterns: IMaybePatterns<T, S>): Maybe<S> {
    const ret = patterns.Nothing()
    return ret instanceof Maybe ? ret : new Nothing()
  }

  extract(): Readonly<T> {
    throw new Error('Can\'t extract a value from Nothing')
  }
}
CACHED_NOTHING = new Nothing<any>()
export { Nothing }

export class Just<T> extends Maybe<T> {
  static of<S>(val: S) {
    return new Just<S>(val)
  }

  readonly tag: string = MaybeTypes.Just
  readonly val: T

  constructor(val: T) {
    super()
    this.val = val
  }


  map<S = T>(f: (val: T) => S): Maybe<S> {
    return Just.of(f(this.val))
  }

  ap<S = T>(f: Maybe<(val: T) => S>): Maybe<S> {
    if (f.isNothing()) {
      return Nothing.of<S>()
    } else {
      return Just.of<S>((f as Just<(val: T) => S>).val(this.val))
    }
  }

  chain<S = T>(f: (val: T) => Maybe<S>): Maybe<S> {
    return f(this.val)
  }

  caseOf<S = T>(patterns: IMaybePatterns<T, S>): Maybe<S> {
    const ret = patterns.Just(this.val)
    return ret instanceof Maybe ? ret : new Just(ret)
  }

  extract(): Readonly<T> {
    return this.val
  }
}
