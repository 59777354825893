import { CanvasContext } from '../../Context/CanvasContext'
import { State } from '../Components/State'
import { PositionMode } from '../Components/Style/Attributes'
import { Style } from '../Components/Style/Style'


export const stateSystem = <C extends CanvasContext>(ctx: C, args: [Style, State<C>], entity: number) => {
  const style = args[0]
  const state = args[1]

  if (state.isEnabled) {
    const mousePos = CanvasContext.GetMousePos(ctx)

    let l = style.positionLeft
    let t = style.positionTop
    let r = style.positionRight
    let b = style.positionBottom

    if (style.positionMode === PositionMode.Relative) {
      const w = ctx.graphics.canvas.width
      const h = ctx.graphics.canvas.height

      l *= w
      r *= w
      t *= h
      b *= h
    }

    if (
      l <= mousePos.x &&
      t <= mousePos.y &&
      r >= mousePos.x &&
      b >= mousePos.y
    ) {
      state.hover()
    } else {
      state.leave()
      state.blur()
      state.deactivate()
    }

    if (state.didEnter) {
      state.onEnter(ctx, entity)
    }

    if (state.didLeave) {
      state.onLeave(ctx, entity)
    }

    style.state = state.state
    state.swap()
  }
}
