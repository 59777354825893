import {
  Action,
  CanvasContext,
  Scene,
  Style,
  Timers,
  Transitions,
} from '@cog/ecs-canvas-2d-plugin'

// TODO: move this guy to common types lib or something
export declare type ClassType<T = any> = {
  new (): T
}

export class FadeFromBlack extends Action<CanvasContext> {
  constructor(
    private overlayPaneId: number,
    private duration: number = 0.3,
  ) {
    super()
  }

  public onStart(ctx: CanvasContext) {
    Transitions.Stop(ctx.world, this.overlayPaneId)
    ctx.world
      .reference([Style], this.overlayPaneId)
      .map((reference) =>
        Transitions.Transition(ctx.world, {
          duration: this.duration,
          mutation: (t, ref) => {
            const alpha = ((1.0 - t * 255)|0) & 0xff
            ref.components[0].idle.background.color = alpha
          },
          onEnd: this.finish,
          reference
        }),
      )
  }
}

export class FadeToBlack extends Action<CanvasContext> {
  constructor(
    private overlayPaneId: number,
    private duration: number = 0.3,
  ) {
    super()
  }

  public onStart(ctx: CanvasContext) {
    Transitions.Stop(ctx.world, this.overlayPaneId)
    ctx.world
      .reference([Style], this.overlayPaneId)
      .map((reference) =>
        Transitions.Transition(ctx.world, {
          duration: this.duration,
          mutation: (t, ref) => {
            const alpha = ((t * 255)|0) & 0xff
            ref.components[0].idle.background.color = alpha
          },
          onEnd: this.finish,
          reference
        }),
      )
  }
}

export class WaitOrCancel extends Action<CanvasContext> {
  constructor(private duration: number = 1) {
    super()
  }

  public onStart(ctx: CanvasContext) {
    Timers.Timer(ctx.world, this.duration, this.finish)
  }

  public onUpdate(ctx: CanvasContext) {
    if (ctx.io.keyboard.key('Escape').isDown || ctx.io.mouse.left.isDown) {
      this.finish()
    }
    return this.isDone
  }
}

export class ReplaceCurrentScene extends Action<CanvasContext> {
  constructor(private scene: ClassType<Scene<CanvasContext>>) {
    super()
  }

  public onStart(ctx: CanvasContext) {
    ctx.sceneManager.pop()
    ctx.sceneManager.push(this.scene)
    this.finish()
  }
}

export class LoadAsset extends Action<CanvasContext> {
  constructor(
    private load: () => Promise<any>
  ) {
    super()
  }

  public onStart(ctx: CanvasContext) {
    this.load().then(this.finish)
  }
}

export class ProgressLoading extends Action<CanvasContext> {
  constructor(
    private paneId: number,
    private from: number,
    private to: number,
    private duration: number = 0.0001,
  ) {
    super()
  }

  public onStart(ctx: CanvasContext) {
    Transitions.Stop(ctx.world, this.paneId)
    ctx.world
      .reference([Style], this.paneId)
      .map((reference) =>
        Transitions.Transition(ctx.world, {
          duration: this.duration,
          mutation: (t, ref) => {
            const right = this.from + t * (this.to - this.from)
            ref.components[0].idle.position.right = right
            ref.components[0].idle.background.color = 0xff
          },
          onEnd: this.newFinish,
          reference
        }),
      )
  }

  private newFinish = () => {
    this.finish()
  }
}
