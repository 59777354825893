import { Init } from '@cog/ecs'
import { CanvasContext } from '@cog/ecs-canvas-2d-plugin'
import { Vec2 } from '@cog/math'

import { Bone2d } from './Bone2d'
import { EndEffector2d } from './EndEffector2d'

const COLORS = [
  'rgb(255,   0,   0)',
  'rgb(  0, 255,   0)',
  'rgb(  0,   0, 255)',
  'rgb(  0, 255, 255)',
  'rgb(255,   0, 255)',
  'rgb(255, 255,   0)',
  'rgb(128,   0,   0)',
  'rgb(  0, 128,   0)',
  'rgb(  0,   0, 128)',
  'rgb(  0, 128, 128)',
  'rgb(128,   0, 128)',
  'rgb(128, 128,   0)',
]

const FACTOR = 1 / 6
const JOINT_RADIUS = 4
const ROOT_RADIUS = (JOINT_RADIUS * 2)|0

export class Skeleton2d implements Init<Skeleton2d> {
  static Render = (ctx: CanvasContext, args: [Skeleton2d]) => {
    const g = ctx.graphics.gfx
    const skeleton = args[0]

    g.strokeStyle = 'rgba(255, 255, 255, 0.5)'
    g.beginPath()
    for (const bone of skeleton.bones) {
      g.moveTo(bone.head.x + JOINT_RADIUS, bone.head.y)
      g.arc(bone.head.x, bone.head.y, JOINT_RADIUS, 0, Math.PI * 2)
      const headToTail = bone.tail.sub(bone.head)
      const tangent = bone.tail.sub(bone.head).cross().normalize()

      g.moveTo(bone.head.x, bone.head.y)
      const cross = bone.head.add(headToTail.scale(FACTOR))
      const p0 = cross.add(tangent.scale(FACTOR * bone.length))
      const p1 = cross.sub(tangent.scale(FACTOR * bone.length))
      g.lineTo(p0.x, p0.y)
      g.lineTo(bone.tail.x, bone.tail.y)
      g.lineTo(p1.x, p1.y)
      g.lineTo(bone.head.x, bone.head.y)

      g.moveTo(bone.tail.x + JOINT_RADIUS, bone.tail.y)
      g.arc(bone.tail.x, bone.tail.y, JOINT_RADIUS, 0, Math.PI * 2)
    }

    if (skeleton.root != null) {
      const bone = skeleton.root
      g.moveTo(bone.head.x + ROOT_RADIUS, bone.head.y)
      g.arc(bone.head.x, bone.head.y, ROOT_RADIUS, 0, Math.PI * 2)
      const cross = bone.head
      const normal = bone.tail.sub(bone.head).normalize().scale(ROOT_RADIUS)
      const tangent = normal.cross().normalize().scale(ROOT_RADIUS)
      const p0 = cross.sub(normal)
      const p1 = cross.add(normal)
      const p2 = cross.sub(tangent)
      const p3 = cross.add(tangent)
      g.moveTo(p0.x, p0.y)
      g.lineTo(p1.x, p1.y)
      g.moveTo(p2.x, p2.y)
      g.lineTo(p3.x, p3.y)
    }
    g.stroke()

    let color = 0
    for (const endEffector of skeleton.endEffectors) {
      g.strokeStyle = COLORS[color]
      g.beginPath()
      g.setLineDash([2, 2])
      const target = endEffector.goal.add(endEffector.offset)
      for (const bone of endEffector.bones) {
        g.moveTo(target.x, target.y)
        g.lineTo(bone.tail.x, bone.tail.y)
      }
      g.moveTo(target.x, target.y)
      g.arc(target.x, target.y, 5, 0, Math.PI * 2)
      g.stroke()
      g.setLineDash([])
      ++color
    }
  }

  static SolveIK = (ctx: CanvasContext, args: [Skeleton2d]) => {
    const skeleton = args[0]
    let bonesHeads: Vec2[] = []
    let bonesTails: Vec2[] = []

    const chainRoots: Map<Bone2d[], Vec2> = new Map()

    // FABRIK
    for (const [effector, chains] of skeleton.kinematicChains) {
      for (const chain of chains) {
        if (chain.length > 0) {
          chainRoots.set(chain, chain[chain.length - 1].head)
          // Forward
          let goal = effector.goal.sub(effector.offset)
          let delta: Vec2
          let head: Vec2

          // copy the chain bones heads and tails
          for (let i = 0; i < chain.length; ++i) {
            const bone = chain[i]
            bonesHeads.push(bone.head)
            bonesTails.push(bone.tail)
          }

          for (let i = 0; i < chain.length; ++i) {
            const bone = chain[i]
            head = bonesHeads[i]

            delta = goal.sub(head).normalize().scale(bone.length)
            bonesTails[i] = goal
            goal = delta.len() > 0.01 ? goal.sub(delta) : goal
            bonesHeads[i] = goal
          }

          for (let i = 0; i < chain.length; ++i) {
            const bone = chain[i]
            bone.stageHead(bonesHeads[i])
            bone.stageTail(bonesTails[i])
          }

          bonesHeads = []
          bonesTails = []
        }
      }
    }

    for (const bone of skeleton.bones) {
      bone.commit()
    }

    for (const [effector, chains] of skeleton.kinematicChains) {
      for (const chain of chains) {
        if (chain.length > 0) {
          const hinge = chainRoots.get(chain)!
          let goal = effector.goal.sub(effector.offset)
          let delta: Vec2
          let tail: Vec2

          // copy the chain bones heads and tails
          for (let i = 0; i < chain.length; ++i) {
            const bone = chain[i]
            bonesHeads.push(bone.head)
            bonesTails.push(bone.tail)
          }

          // Backward
          goal = hinge
          for (let i = chain.length - 1; i >= 0; --i) {
            const bone = chain[i]
            tail = bonesTails[i]

            delta = goal.sub(tail).normalize().scale(bone.length)
            bonesHeads[i] = goal
            goal = delta.len() > 0.01 ? goal.sub(delta) : goal
            bonesTails[i] = goal
          }

          for (let i = 0; i < chain.length; ++i) {
            const bone = chain[i]
            bone.stageHead(bonesHeads[i])
            bone.stageTail(bonesTails[i])
          }

          bonesHeads = []
          bonesTails = []
        }
      }
    }

    for (const bone of skeleton.bones) {
      bone.commit()
    }
  }

  public bones: Bone2d[] = []
  public endEffectors: EndEffector2d[] = []
  public kinematicChains: Map<EndEffector2d, Bone2d[][]> = new Map()

  constructor(
    public root: Bone2d | null = null,
  ) {}

  // calculates kinematic chains
  public compile() {
    this.bones = []
    this.endEffectors = []
    if (this.root != null) {
      const boneQueue = [this.root]
      let bone: Bone2d
      while (boneQueue.length > 0) {
        bone =boneQueue.shift()!
        this.bones.push(bone)
        if (
          bone.endEffector != null &&
          this.endEffectors.find((e) => e === bone.endEffector) == null
        ) {
          this.endEffectors.push(bone.endEffector)
        }
        boneQueue.push(...bone.children)
      }
    }

    this.kinematicChains.clear()
    for (const endEffector of this.endEffectors) {
      this.kinematicChains.set(endEffector, [])
      for (const root of endEffector.bones) {
        const chain: Bone2d[] = []
        let bone = root
        while (bone != null) {
          chain.push(bone)
          if (bone.parent != null) {
            bone = bone.parent
          } else {
            break
          }
        }
        this.kinematicChains.get(endEffector)!.push(chain)
      }
    }
  }

  public init(props: Skeleton2d) {
    this.root = props.root
    this.bones = props.bones
    this.endEffectors = props.endEffectors
    this.kinematicChains = props.kinematicChains
    return this
  }

  public reset() {
    this.root = null
    this.bones = []
    this.endEffectors = []
    this.kinematicChains = new Map()
  }
}
