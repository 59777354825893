import { CanvasContext } from '../../Context'
import { Init } from '../types'

export enum ElementState {
  Idle      = 0b00000000,
  Disabled  = 0b00000001,
  Hovered   = 0b00000010,
  Focused   = 0b00000100,
  Active    = 0b00001000,
}

export const isFocused = (state: ElementState) => state & ElementState.Focused
export const isBlurred = (state: ElementState) => !(state & ElementState.Focused)

export const isActive = (state: ElementState) => (state & ElementState.Active)
export const isInactive = (state: ElementState) => !(state & ElementState.Active)

export const isHovered = (state: ElementState) => (state & ElementState.Hovered)
export const isIdle = (state: ElementState) => !(state & ElementState.Hovered)

export const isDisabled = (state: ElementState) => (state & ElementState.Disabled)
export const isEnabled = (state: ElementState) => !(state & ElementState.Disabled)

const noop = () => {}

type StateProps<C extends CanvasContext> = { state: ElementState, onEnter: (ctx: C, id: number) => void, onLeave: (ctx: C, id: number) => void }
export class State<C extends CanvasContext> implements Init<StateProps<C>> {
  public onEnter!: (ctx: C, id: number) => void
  public onLeave!: (ctx: C, id: number) => void
  public state!: ElementState
  private prevState!: ElementState

  constructor(props?: Partial<StateProps<C>>) {
    this.init(props)
  }

  public init(props?: Partial<StateProps<C>>) {
    this.prevState = this.state = props?.state ?? 0x0
    this.onEnter = props?.onEnter ?? noop
    this.onLeave = props?.onLeave ?? noop
  }

  public focus() { this.state |= ElementState.Focused }
  public blur() { this.state &= ~ElementState.Focused }
  public get didFocus() { return (this.state & ElementState.Focused) && !(this.prevState & ElementState.Focused) }
  public get didBlur() { return !(this.state & ElementState.Focused) && (this.prevState & ElementState.Focused) }
  public get isFocused() { return isFocused(this.state) }
  public get isBlurred() { return isBlurred(this.state) }

  public activate() { this.state |= ElementState.Active }
  public deactivate() { this.state &= ~ElementState.Active }
  public get didActivate() { return (this.state & ElementState.Active) && !(this.prevState & ElementState.Active) }
  public get didDeactivate() { return !(this.state & ElementState.Active) && (this.prevState & ElementState.Active) }
  public get isActive() { return isActive(this.state) }
  public get isInactive() { return isInactive(this.state) }

  public hover() { this.state |= ElementState.Hovered }
  public leave() { this.state &= ~ElementState.Hovered }
  public get didEnter() { return (this.state & ElementState.Hovered) && !(this.prevState & ElementState.Hovered) }
  public get didLeave() { return !(this.state & ElementState.Hovered) && (this.prevState & ElementState.Hovered) }
  public get isHovered() { return isHovered(this.state) }
  public get isIdle() { return isIdle(this.state) }

  public disable() { this.state |= ElementState.Disabled }
  public enable() { this.state &= ~ElementState.Disabled }
  public get didDisable() { return (this.state & ElementState.Disabled) && !(this.prevState & ElementState.Disabled) }
  public get didEnable() { return !(this.state & ElementState.Disabled) && (this.prevState & ElementState.Disabled) }
  public get isDisabled() { return isDisabled(this.state) }
  public get isEnabled() { return isEnabled(this.state) }

  public swap() {
    this.prevState = this.state
  }
}
