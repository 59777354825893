import { Just, Maybe, Nothing } from '@cog/func'

import { Archetype } from './Archetype'
import { Context } from './Context'
import { Reference } from './Reference'
import {
  Systems,
  SystemFunction,
  SystemFunctionPredicate,
  Trigger,
} from './Systems'
import {
  ClassArgs,
  ClassType,
  ComponentArgsFromSignature,
  Entity,
  Pair,
  QuerySignature,
  ReadonlyComponentArgsFromSignature,
} from './internal'

type WorldConfig<C extends Context> = {
  initialEntityTableSize: number,
  context: C,
}

export class World<C extends Context> {
  private archetypes: Array<Archetype<C>>
  private entityIdToArchetypeSignature: Map<Entity, number> = new Map()
  private freeEntityIdPool: number[]
  private nextId: number = 0
  private nextArchetypeId = 0
  private archetypeSignatureIndex: Array<Pair<QuerySignature, number>> = []
  private systems: Systems<C>
  private config: WorldConfig<C>

  constructor(
    config: Partial<WorldConfig<C>> = {
      context: new Context() as any,
      initialEntityTableSize: 1024,
    },
  ) {
    this.config = {
      context: (config.context || new Context() as any),
      initialEntityTableSize: Math.max(config.initialEntityTableSize || 1, 1),
    }
    this.archetypes = []
    this.systems = new Systems(this.archetypes, this.config.context, this.getArchetypeId)
    this.freeEntityIdPool = []

    // Inject the world into the context
    ;(this.config.context as any)._world = this
  }

  // =================
  // Entity management
  // =================
  /**
   * Creates a new entity with a given signature and initializes it
   *
   * @param signature - an array of class constructors entity consists of
   * @param init - an initializer function receiving an array of constructed instances corresponding to the order laid out in signature
   * @example
   * const id = world.entity([Position, Sprite], (args) => {
   *   const position = args[0]
   *   const sprite = args[1]
   *
   *   position.x = 50
   *   position.y = 100
   *
   *   sprite.sheet = Repository.get<SpriteSheet>('player')
   * })
   */
  public entity<T extends ClassArgs>(signature: T, init: (components: ComponentArgsFromSignature<T>, id: Entity) => void): Entity {
    if (signature.length < 1) {
      throw new Error('Entity _must_ have at least one component')
    }

    const index = this.getArchetypeId(signature, true)
    let archetype: Archetype<C>
    if (index < this.archetypes.length) {
      archetype = this.archetypes[index]
    } else {
      archetype = new Archetype(signature, this.generateId, this.config.initialEntityTableSize)
      this.archetypes[index] = archetype
    }
    // NOTE: as any is intentional since archetypes don't care about types at compiletime, only runtime
    const id = archetype.createAndInit(init as any)
    this.entityIdToArchetypeSignature.set(id, index)
    this.systems.on(Trigger.Create, id, archetype)
    return id
  }

  public delete(entity: Entity) {
    if (this.entityIdToArchetypeSignature.has(entity)) {
      const archetype = this.archetypes[this.entityIdToArchetypeSignature.get(entity)!]!
      this.systems.on(Trigger.Delete, entity, archetype)
      archetype.delete(entity, this.freeEntityIdPool)
      this.entityIdToArchetypeSignature.delete(entity)
    }
  }

  public deleteWhere<T extends ClassArgs>(query: T, predicate: (args: ReadonlyComponentArgsFromSignature<T>) => boolean) {
    for (const archetype of this.archetypes.values()) {
      archetype.deleteWhere(this.systems, query, predicate, this.entityIdToArchetypeSignature, this.freeEntityIdPool)
    }
  }

  public assign<T extends ClassType>(entity: Entity, Component: T, init: (component: InstanceType<T>, entity: Entity) => void): Entity {
    if (this.entityIdToArchetypeSignature.has(entity)) {
      const archetype = this.archetypes[this.entityIdToArchetypeSignature.get(entity)!]!
      if (archetype.has(Component)) {
        archetype.mutate(entity, [Component], (args, entity) => init(args[0], entity))
      } else {
        // entity migration to other archetype required
        const newSignature = [...archetype.getSignature(), Component]
        const index = this.getArchetypeId(newSignature, true)
        const targetArchetype = (this.archetypes.length === index
          ? new Archetype(newSignature, this.generateId, this.config.initialEntityTableSize)
          : this.archetypes[index]) as Archetype<any>

        targetArchetype.receiveFromOtherArchetype(entity, archetype, Component, init)
        this.entityIdToArchetypeSignature.set(entity, index)
        this.archetypes[index] = targetArchetype
      }
    }
    return entity
  }

  public query<T extends ClassArgs>(query: T, body: SystemFunction<C, T>) {
    for (const archetype of this.archetypes) {
      archetype.run(this.config.context, query, body)
    }
  }

  public queryWhere<T extends ClassArgs>(query: T, predicate: SystemFunctionPredicate<C ,T>, body: SystemFunction<C, T>) {
    for (const archetype of this.archetypes) {
      archetype.runWhere(this.config.context, query, predicate, body)
    }
  }

  public queryEntity<T extends ClassArgs>(query: T, entity: Entity, body: (context: C, components: ComponentArgsFromSignature<T>, entity: Entity) => void) {
    if (this.entityIdToArchetypeSignature.has(entity)) {
      this.archetypes[this.entityIdToArchetypeSignature.get(entity)!].runForEntity(this.config.context, query, entity, body)
    }
  }

  public reference<T extends ClassArgs>(query: T, entity: Entity): Maybe<Reference<T>> {
    let ref: Maybe<Reference<T>> = new Nothing()

    if (this.entityIdToArchetypeSignature.has(entity)) {
      this.archetypes[this.entityIdToArchetypeSignature.get(entity)!].runForEntity(this.config.context, query, entity, (_, components) => {
        ref = new Just(new Reference(entity, components.splice(0, query.length))) as any
      })
    }

    return ref
  }

  public count<T extends ClassArgs>(query: T) {
    return this.archetypes.reduce((total, archetype) => total + archetype.count(query), 0)
  }

  public countWhere<T extends ClassArgs>(query: T, predicate: (context: C, components: ComponentArgsFromSignature<T>, entity: Entity) => boolean) {
    return this.archetypes.reduce((total, archetype) => total + archetype.countWhere(this.config.context, query, predicate), 0)
  }

  public *view<T extends ClassArgs>(query: T) {
    for (const archetype of this.archetypes) {
      if (archetype.satisfies(query)) {
        const index = new Array(query.length)
        const entities = archetype.getEntities()
        for (let i = 0; i < archetype.getSize(); ++i) {
          for (let j = 0; j < query.length; ++j) {
            index[j] = entities.get(query[j] as any)![i]
          }
          yield (index as unknown as ComponentArgsFromSignature<T>)
        }
      }
    }
  }

  public get context() {
    return this.config.context
  }

  public tick(elapsed: number) {
    this.systems.tick(elapsed)
  }

  // =================
  // System management
  // =================
  public get system() { return this.systems.systems }

  // ===============
  // Task management
  // ===============
  public get task() { return this.systems.tasks }

  // =========
  // Internals
  // =========
  private generateId = () => {
    if (this.freeEntityIdPool.length > 0) {
      return this.freeEntityIdPool.pop()!
    }
    return this.nextId++ as Entity
  }

  private generateArchetypeId = () => {
    return this.nextArchetypeId++
  }

  private getArchetypeId = (signature: QuerySignature, createArchetype: boolean = false) => {
    const found = this.archetypeSignatureIndex.find((val) => {
      if (signature.length === val.fst.length) {
        for (let i = 0; i < signature.length; ++i) {
          if (signature[i] !== val.fst[i]) {
            return false
          }
        }
        return true
      } else {
        return false
      }
    })

    if (createArchetype && found == null) {
      const id = this.generateArchetypeId()
      this.archetypeSignatureIndex.push(new Pair(signature, id))
      return id
    } else if (found == null) {
      return -1
    } else {
      return found.snd
    }
  }
}
