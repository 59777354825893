import { World } from '@cog/ecs'
import { CanvasContext, Gui, Scene, Sequence, Transitions } from '@cog/ecs-canvas-2d-plugin'
import { Vec2 } from '@cog/math'

import { FadeFromBlack, FadeToBlack, ReplaceCurrentScene } from '../../Actions'
import { background, defaultIdleStyle, defaultModifiers } from '../../Scenes/commonStyles'
import { clearScreen } from '../../commonSystems'
import { SceneRegistry } from '../../sceneRegistry'
import { EndEffector } from './components/EndEffector'
import { Joint } from './components/Joint'
import { Relationship } from './components/Relationship'
import { Skeleton } from './components/Skeleton'
import { KeyboardMovedTag, MouseMovedTag, RandomWiggle } from './components/Tags'
import { controlEndEffectorWithKeyboard, controlEndEffectorWithMouse, controlEndEffectorWithRandomWiggle, renderEndEffector } from './systems/endEffector'
import { renderSkeleton, updateSkeleton } from './systems/skeleton'


const SCALE = 0

export class IKScene extends Scene<CanvasContext> {

  public create(world: World<CanvasContext>) {
    this.using(Gui, Transitions)
    world.context.resize(world.context.width << SCALE, world.context.height << SCALE)

    const overlay = Gui.Element.Pane(world, { style: { ...background, idle: { ...background.idle, background: { color: 0xff }}} })
    world.context.actionManager.run(new FadeFromBlack(overlay))

    Gui.Element.Button(world, {
      onClick: () => {
        world.context.actionManager.run(
          new Sequence([
            new FadeToBlack(overlay),
            new ReplaceCurrentScene(SceneRegistry.MainMenu),
          ]),
        )
      },

      label: { text: '<-' },
      style: { ...defaultModifiers, idle: {
        ...defaultIdleStyle().idle,
        position: { left: 5, top: 5, right: 25, bottom: 25 },
      }},
    })

    const skeleton = world.entity([Skeleton], () => {})

    const rootJoint = world.entity([Joint, Relationship], ([joint, relationship], id) => {
      joint.skeleton = skeleton
      joint.position = new Vec2(0, 0)
      joint.lengthConstraintMin = 0
      joint.lengthConstraintMax = 0
      joint.angleConstraintMin = 0
      joint.angleConstraintMax = 0
      relationship.parent = -1
      relationship.id = id
      relationship.children = []
    })

    for (let tentacle = 0; tentacle < 20; ++tentacle) {
      let prevJoint = rootJoint
      for (let i = 0; i < 200; ++i) {
        const cur = world.entity([Joint, Relationship], ([joint, relationship], curId) => {
          joint.skeleton = skeleton
          joint.position = new Vec2(
            world.context.width * 0.625 - 0.25 * Math.random() * world.context.width,
            world.context.height * 0.625 - 0.25 * Math.random() * world.context.height,
          )
          joint.lengthConstraintMin = 1 + SCALE
          joint.lengthConstraintMax = 1 + SCALE
          joint.angleConstraintMin = 0
          joint.angleConstraintMax = Math.PI * 2.0
          relationship.parent = prevJoint
          relationship.id = curId
          relationship.children = []
        })

        world.queryEntity([Relationship], prevJoint, (_, [relationship]) => {
          relationship.children.push(cur)
        })

        prevJoint = cur
      }

      world.entity([EndEffector, KeyboardMovedTag, RandomWiggle], ([endEffector]) => {
        endEffector.position = new Vec2(
          world.context.width * 0.625 - 0.25 * Math.random() * world.context.width,
          world.context.height * 0.625 - 0.25 * Math.random() * world.context.height,
        )
        endEffector.skeleton = skeleton
        endEffector.joint = prevJoint
      })
    }

    world.entity([EndEffector, MouseMovedTag], ([endEffector]) => {
      endEffector.position = new Vec2(100, 100)
      endEffector.skeleton = skeleton
      endEffector.joint = rootJoint
    })

    world.queryEntity([Skeleton], skeleton, (_, [skeleton]) => {
      skeleton.root = rootJoint
    })

    world.task.on.before(clearScreen)
    world.system.on.before([Skeleton], updateSkeleton)
    world.system.on.after([Skeleton], renderSkeleton)
    world.system.on.before([EndEffector, MouseMovedTag], controlEndEffectorWithMouse)
    world.system.on.before([EndEffector, KeyboardMovedTag], controlEndEffectorWithKeyboard)
    world.system.on.before([EndEffector, RandomWiggle], controlEndEffectorWithRandomWiggle)
    world.system.on.after([EndEffector], renderEndEffector)
  }

  public delete(world: World<CanvasContext>) {
    world.context.resize()
    world.task.delete(clearScreen)
    world.system.delete(updateSkeleton)
    world.system.delete(renderSkeleton)
    world.system.delete(controlEndEffectorWithMouse)
    world.system.delete(controlEndEffectorWithKeyboard)
    world.system.delete(controlEndEffectorWithRandomWiggle)
    world.system.delete(renderEndEffector)
    world.deleteWhere([EndEffector], () => true)
    world.deleteWhere([Skeleton], () => true)
    world.deleteWhere([Joint], () => true)
  }
}
