import { World } from '@cog/ecs'
import { CanvasContext } from '@cog/ecs-canvas-2d-plugin'

import { SparseSpatialGrid } from '../SpatialPartitioning/SparseSpatialGrid'
import { Collision } from './Collision'


export const makeCollisionDetector = (grid: SparseSpatialGrid, layer: number, world: World<CanvasContext>) => (ctx: CanvasContext) => {
  // Clear the collision pool
  let collision: Collision
  world.deleteWhere([Collision], () => true)
    // Do the collision detection
  grid.potentialCollisions(layer, (fst, snd, distSq) => {
    const dist = Math.sqrt(distSq)
    const rad = fst.collider.radius + snd.collider.radius
    const penetration = rad - dist

    if (penetration > 0.9) {
      world.entity([Collision], (args) => {
        const normal = snd.transform.position.sub(fst.transform.position).normalize()
        collision = args[0]
        collision.fst = fst.entity
        collision.snd = snd.entity
        collision.normal = normal
        collision.penetration = penetration
        collision.middle = fst.transform.position.addScaled(normal, fst.collider.radius - penetration * 0.5)
      })
    }
  })
}
