import { World } from '@cog/ecs'
import { CanvasContext, Gui, Scene, Sequence, Transitions } from '@cog/ecs-canvas-2d-plugin'
import { Vec2 } from '@cog/math'

import { FadeFromBlack, FadeToBlack, ReplaceCurrentScene } from '../../Actions'
import { background, defaultIdleStyle, defaultModifiers } from '../../Scenes/commonStyles'
import { clearScreen } from '../../commonSystems'
import { SceneRegistry } from '../../sceneRegistry'

import { Bone2d } from './components/Skeleton2d/Bone2d'
import { EndEffector2d } from './components/Skeleton2d/EndEffector2d'
import { Skeleton2d } from './components/Skeleton2d/Skeleton2d'


export class IKProceduralBiped extends Scene<CanvasContext> {

  public create(world: World<CanvasContext>) {
    this.using(Gui, Transitions)
    world.context.resize(640, 640 * 10 / 16)

    const overlay = Gui.Element.Pane(world, { style: { ...background, idle: { ...background.idle, background: { color: 0xff }}} })
    world.context.actionManager.run(new FadeFromBlack(overlay))

    Gui.Element.Button(world, {
      onClick: () => {
        world.context.actionManager.run(
          new Sequence([
            new FadeToBlack(overlay),
            new ReplaceCurrentScene(SceneRegistry.MainMenu),
          ]),
        )
      },

      label: { text: '<-' },
      style: { ...defaultModifiers, idle: {
        ...defaultIdleStyle().idle,
        position: { left: 5, top: 5, right: 25, bottom: 25 },
      }},
    })

    // Stickman
    const root = new Bone2d(new Vec2(160, 100))

    const head = new Bone2d(root.tail, new Vec2(10, -20)).setParent(root)
    const backbone = new Bone2d(root.tail, new Vec2(-10, 60)).setParent(root)

    const upperArmR = new Bone2d(root.tail, new Vec2(-10, -45)).setParent(root)
    const forearmR = new Bone2d(upperArmR.tail, new Vec2(0, 45)).setParent(upperArmR)

    const upperArmL = new Bone2d(root.tail, new Vec2(-10, -45)).setParent(root)
    const forearmL = new Bone2d(upperArmL.tail, new Vec2(0, 45)).setParent(upperArmL)

    const thighR = new Bone2d(backbone.tail, new Vec2(0, 50)).setParent(backbone)
    const calfR = new Bone2d(thighR.tail, new Vec2(0, 50)).setParent(thighR)

    const thighL = new Bone2d(backbone.tail, new Vec2(0, 50)).setParent(backbone)
    const calfL = new Bone2d(thighR.tail, new Vec2(0, 50)).setParent(thighL)

    const headController = new EndEffector2d(head.tail).addBone(head)
    const hipsController = new EndEffector2d(backbone.tail.add(new Vec2(-20, 0))).addBone(backbone)

    const elbowRController = new EndEffector2d(upperArmR.tail).addBone(upperArmR)
    const elbowLController = new EndEffector2d(upperArmL.tail).addBone(upperArmL)

    const handRController = new EndEffector2d(forearmR.tail).addBone(forearmR)
    const handLController = new EndEffector2d(forearmL.tail).addBone(forearmL)

    const kneeRController = new EndEffector2d(thighR.tail).addBone(thighR)
    const ankleRController = new EndEffector2d(calfR.tail).addBone(calfR)

    const kneeLController = new EndEffector2d(thighR.tail).addBone(thighL)
    const ankleLController = new EndEffector2d(calfR.tail).addBone(calfL)

    console.log(headController, hipsController, forearmL, forearmR, handLController, handRController)

    world.task.on.before((ctx) => {
      const HAND_DELAY = Math.PI * 0.21
      const elapsed = -ctx.timer.elapsed * 1.5 * 2

      if (ctx.io.mouse.left.isDown) {
        root.head = CanvasContext.GetMousePos(ctx)
      } else {
        root.head = new Vec2(160, 100 + Math.sin(elapsed * 2 + HAND_DELAY) * 4)
      }

      headController.goal = root.head.add(new Vec2(10, -20))

      hipsController.goal = new Vec2(150, 160).add(new Vec2(-5, 20))

      ankleRController.goal = new Vec2(150, 260).add(new Vec2(Math.sin(elapsed) * 20, Math.min(Math.cos(elapsed) * 20, 10)))
      kneeRController.goal = thighR.tail.add(new Vec2(5, 0))
      handLController.goal = new Vec2(170 + Math.sin(elapsed - HAND_DELAY) * 20, 170 + Math.abs(Math.cos(elapsed - HAND_DELAY) * 10))
      if (handLController.goal.y <= root.head.y + 35) {
        handLController.goal = new Vec2(handLController.goal.x, root.head.y + 35)
      }
      elbowLController.goal = handLController.goal.add(new Vec2(-30, -20))

      ankleLController.goal = new Vec2(150, 260).add(new Vec2(Math.sin(elapsed + Math.PI) * 20, Math.min(Math.cos(elapsed + Math.PI) * 20, 10)))
      kneeLController.goal = thighL.tail.add(new Vec2(5, 0))
      handRController.goal = new Vec2(170 + Math.sin(elapsed + Math.PI - HAND_DELAY) * 20, 170 + Math.abs(Math.cos(elapsed + Math.PI - HAND_DELAY) * 10))
      if (handRController.goal.y <= root.head.y + 35) {
        handRController.goal = new Vec2(handRController.goal.x, root.head.y + 35)
      }
      elbowRController.goal = handRController.goal.add(new Vec2(-30, -20))
    })

    const skeleton = world.entity([Skeleton2d], ([skeleton]) => {
      skeleton.reset()
      skeleton.root = root
      skeleton.compile()
    })

    console.log(skeleton)

    world.task.on.before(clearScreen)
    world.system.on.after([Skeleton2d], Skeleton2d.Render)
    world.system.on.before([Skeleton2d], Skeleton2d.SolveIK)
  }

  public delete(world: World<CanvasContext>) {
    world.context.resize()
    world.task.delete(clearScreen)
    world.system.delete(Skeleton2d.Render)
    world.system.delete(Skeleton2d.SolveIK)
    world.deleteWhere([Skeleton2d], () => true)
  }
}
