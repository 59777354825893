import { Entity } from '@cog/ecs'
import { Init } from '@cog/ecs-canvas-2d-plugin/dist/GUI/types'

export class Skeleton implements Init<Skeleton> {
  constructor(
    public root: Entity = -1,
    // public animations: Animations = new Animations()
  ) {}

  public init(props: Skeleton) {
    this.root = props.root
    return this
  }
}
