import { Entity } from '@cog/ecs'
import { CanvasContext } from '@cog/ecs-canvas-2d-plugin'
import { Vec2 } from '@cog/math'

import { EndEffector } from '../components/EndEffector'
import { Joint } from '../components/Joint'
import { Relationship } from '../components/Relationship'
import { Skeleton } from '../components/Skeleton'
import { walk, walkUp } from '../util'


export const solveIK = (ctx: CanvasContext, skeleton: Entity, endEffectors: EndEffector[]) => {
  // for every EndEffector solve the IK chain
  for (const endEffector of endEffectors) {
    const chain: Array<[Joint, Relationship]> = []
    walkUp(ctx, skeleton, endEffector.joint, (args) => {
      if (args != null) {
        chain.push(args)
        // terminate the chain if another end effector is found operating on a joint in the chain
        return endEffectors.find((end) => end !== endEffector && end.joint === args[1].id) == null
      } else {
        return false
      }
    })

    if (chain.length === 0) {
      continue
    }

    // This approach is called FABRIK (Forward And Backward Reaching Inverse Kinematics)
    // go forward from the joint affected by the end effector and solve only for positions
    // because i'm lazy and don't yet want to deal with constraints
    let head = chain[0][0]
    let tail: Joint
    head.position = endEffector.position
    for (let i = 1; i < chain.length - 1; ++i) {
      head = chain[i - 1][0]
      tail = chain[i][0]
      let delta = tail.position.sub(head.position)
      delta = (delta.len() < 0.01
        ? new Vec2(0.1, 0.1)
        : delta
      ).normalize().scale(head.lengthConstraintMin)
      tail.position = head.position.add(delta)
    }

    // and then solve the same chain from back to front so no joints get dislocated if end effector is not free
    for (let i = chain.length - 1; i > 0; --i) {
      head = chain[i][0]
      tail = chain[i - 1][0]
      let delta = tail.position.sub(head.position)
      delta = (delta.len() < 0.01
        ? new Vec2(0.1, 0.1)
        : delta
      ).normalize().scale(head.lengthConstraintMin)
      tail.position = head.position.add(delta)
    }
  }
}

export const updateSkeleton = (ctx: CanvasContext, _: [Skeleton], id: Entity) => {
  const endEffectors: EndEffector[] = []
  ctx.world.queryWhere(
    [EndEffector],
    (_, args) => args[0].skeleton === id,
    (_, args) => endEffectors.push(args[0]),
  )

  solveIK(ctx, id, endEffectors)
}

export const renderSkeleton = (ctx: CanvasContext, [skeleton]: [Skeleton], id: Entity) => {
  const g = ctx.graphics.gfx
  g.strokeStyle = 'rgba(255, 255, 255, 0.2)'
  g.beginPath()

  walk(ctx, id, skeleton.root, (node, parent) => {
    const joint = node[0]
    if (parent != null) {
      let start = parent[0].position
      let end = joint.position
      const delta = end.sub(start).scale(0.1)
      start = start.sub(delta)
      end = end.add(delta)
      g.moveTo(start.x|0, start.y|0)
      g.lineTo(end.x|0, end.y|0)
    }
  })

  g.stroke()
}