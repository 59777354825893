import { Entity, Init } from '@cog/ecs'
import { Vec2 } from '@cog/math'

export class EndEffector implements Init<EndEffector>{
  constructor(
    public skeleton: Entity = -1,
    public joint: Entity = -1,
    public position = new Vec2(),
    public chainLength = 0,
  ) {}

  public init(props: EndEffector) {
    this.skeleton = props.skeleton
    this.joint = props.joint
    this.position = props.position
    this.chainLength = props.chainLength
    return this
  }
}
