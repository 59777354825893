import { Entity } from '@cog/ecs'
import { CanvasContext } from '@cog/ecs-canvas-2d-plugin'

import { Joint } from './components/Joint'
import { Relationship } from './components/Relationship'


export const walk = (ctx: CanvasContext, skeleton: Entity, node: Entity, cb: (node: [Joint, Relationship], parentNode?: [Joint, Relationship]) => void) => {
  if (node === -1) {
    return
  }

  const jointMap: Map<Entity, [Joint, Relationship]> = new Map()
  ctx.world.queryWhere(
    [Joint, Relationship],
    (_, args) => args[0].skeleton === skeleton,
    (_, args) => {
      const joint = args[0]
      const relationship = args[1]
      jointMap.set(relationship.id, [joint, relationship])
    },
  )

  const jointQueue = [jointMap.get(node)!]
  while (jointQueue.length > 0) {
    const components = jointQueue.shift()!
    cb(components, jointMap.get(components[1].parent))
    for (const child of components[1].children) {
      jointQueue.push(jointMap.get(child)!)
    }
  }
}

export const walkUp = (ctx: CanvasContext, skeleton: Entity, node: Entity, cb: (args: [Joint, Relationship], parent: Entity) => boolean) => {
  if (node === -1) {
    return
  }

  const jointMap: Map<Entity, [Joint, Relationship]> = new Map()
  ctx.world.queryWhere(
    [Joint, Relationship],
    (_, args) => args[0].skeleton === skeleton,
    (_, args) => {
      const joint = args[0]
      const relationship = args[1]
      jointMap.set(relationship.id, [joint, relationship])
    },
  )

  let id = node
  do {
    const components = jointMap.get(id)!
    cb(components, node)
    id = components[1].parent
  } while (id !== -1)
}
