import { Archetype } from './Archetype'
import { Context } from './Context'
import {
  ClassArgs,
  ComponentArgsFromSignature,
  Entity,
  QuerySignature,
} from './internal'

export enum Trigger {
  BeforeUpdate  = 0,
  Update        = 1,
  AfterUpdate   = 2,
  Create        = 3,
  Delete        = 4,
}

export type TaskTrigger = Trigger.BeforeUpdate | Trigger.Update | Trigger.AfterUpdate

export type SystemFunction<C extends Context, T extends ClassArgs> = (context: C, components: ComponentArgsFromSignature<T>, entity: Entity) => void
export type SystemFunctionPredicate<C extends Context, T extends ClassArgs> = (context: C, components: ComponentArgsFromSignature<T>, entity: Entity) => boolean
export type ContextlessSystemFunction<T extends ClassArgs> = (components: ComponentArgsFromSignature<T>, entity: Entity) => void
export type TaskFunction<C extends Context> = (context: C) => void

const DEFAULT_PRIORITY = 0

class System<C extends Context, T extends ClassArgs> {
  constructor(
    public signature: T,
    public system: SystemFunction<C, T> | ContextlessSystemFunction<T>,
    public priority: number,
  ) {}
}

const priorityComparator = (a: { priority: number }, b: { priority: number }) => {
  if (a.priority < b.priority) {
    return 1
  } else if (a.priority > b.priority) {
    return -1
  } else {
    return 0
  }
}

class Task<C extends Context> {
  constructor(
    public task: TaskFunction<C>,
    public priority: number,
  ) {}
}

class TimedSystem<C extends Context, T extends ClassArgs> {
  public age: number
  constructor(
    public signature: T,
    public system: SystemFunction<C, T>,
    public readonly time: number,
    public readonly repeat: boolean,
  ) {
    this.age = time
  }
}

class TimedTask<C extends Context> {
  public age: number
  constructor(
    public task: TaskFunction<C>,
    public readonly time: number,
    public readonly repeat: boolean,
  ) {
    this.age = time
  }
}

type Lifecycle<T> = [T, T, T, T, T]
type SimpleLifecycle<T> = [T, T, T]

type SystemRegistry<C extends Context> = Map<number, Array<System<C, any>>>
type SystemsTuple<C extends Context> = Lifecycle<SystemRegistry<C>>
type TimedSystemRegistry<C extends Context> = Array<TimedSystem<C, any>>

type TaskRegistry<C extends Context> = Array<Task<C>>
type TasksTuple<C extends Context> = SimpleLifecycle<TaskRegistry<C>>
type TimedTaskRegistry<C extends Context> = Array<TimedTask<C>>

export class Systems<C extends Context> {
  private systemsRegistry: SystemsTuple<C> = [new Map(), new Map(), new Map(), new Map(), new Map]
  private timedSystemsRegistry: TimedSystemRegistry<C> = []

  private taskRegistry: TasksTuple<C> = [[], [], []]
  private timedTasksRegistry: TimedTaskRegistry<C> = []

  private _systems = {
    on: {
      after: <T extends ClassArgs>(signature: T, system: SystemFunction<C, T>, priority: number = DEFAULT_PRIORITY) => this.system(Trigger.AfterUpdate, signature, system, priority),
      before: <T extends ClassArgs>(signature: T, system: SystemFunction<C, T>, priority: number = DEFAULT_PRIORITY) => this.system(Trigger.BeforeUpdate, signature, system, priority),
      update: <T extends ClassArgs>(signature: T, system: SystemFunction<C, T>, priority: number = DEFAULT_PRIORITY) => this.system(Trigger.Update, signature, system, priority),

      create: <T extends ClassArgs>(signature: T, system: ContextlessSystemFunction<T>, priority: number = DEFAULT_PRIORITY) => this.system(Trigger.Create, signature, system, priority),
      delete: <T extends ClassArgs>(signature: T, system: ContextlessSystemFunction<T>, priority: number = DEFAULT_PRIORITY) => this.system(Trigger.Delete, signature, system, priority),
    },

    execute: {
      after: <T extends ClassArgs>(signature: T, system: SystemFunction<C, T>, interval: number) => this.timedSystem(signature, system, interval, false),
      every: <T extends ClassArgs>(signature: T, system: SystemFunction<C, T>, interval: number) => this.timedSystem(signature, system, interval, true),
      immediate: <T extends ClassArgs>(signature: T, system: SystemFunction<C, T>) => this.timedSystem(signature, system, 0, false),
    },

    delete: <T extends any[], CT>(system: CT extends Context ? SystemFunction<CT, T> : ContextlessSystemFunction<T>) => this.deleteSystem(system as any),
  }

  private _tasks = {
    on: {
      after: (task: TaskFunction<C>, priority: number = DEFAULT_PRIORITY) => this.task(Trigger.AfterUpdate, task, priority),
      before: (task: TaskFunction<C>, priority: number = DEFAULT_PRIORITY) => this.task(Trigger.BeforeUpdate, task, priority),
      update: (task: TaskFunction<C>, priority: number = DEFAULT_PRIORITY) => this.task(Trigger.Update, task, priority),
    },

    execute: {
      after: (task: TaskFunction<C>, interval: number) => this.timedTask(task, interval, false),
      every: (task: TaskFunction<C>, interval: number) => this.timedTask(task, interval, true),
      immediate: (task: TaskFunction<C>) => this.timedTask(task, 0, false),
    },

    delete: (task: TaskFunction<C>) => this.deleteTask(task),
  }

  constructor(
    private archetypes: Array<Archetype<C>>,
    private context: C,
    private getArchetypeId: (signature: QuerySignature) => number
  ) {}

  // =======
  // Systems
  // =======
  public get systems() {
    return this._systems
  }

  public timedSystem<T extends ClassArgs>(signature: T, system: SystemFunction<C, T>, time: number, repeat: boolean) {
    const thunk = new TimedSystem(signature, system, time, repeat)
    this.timedSystemsRegistry.push(thunk)
  }

  public system<T extends ClassArgs>(trigger: Trigger, signature: T, system: SystemFunction<C, T> | ContextlessSystemFunction<T>, priority: number) {
    const hash = this.getArchetypeId(signature)
    const registry = this.systemsRegistry[trigger]

    let bucket = registry.get(hash)
    if (bucket == null) {
      bucket = []
      registry.set(hash, bucket)
    }

    const thunk = new System(signature, system, priority)

    if (!bucket.find((sys) => sys.system === system)) {
      bucket.push(thunk)
      bucket.sort(priorityComparator)
    }
  }

  public deleteSystem<T extends ClassArgs>(system: SystemFunction<C, T> | ContextlessSystemFunction<T>) {
    for (const registry of this.systemsRegistry.values()) {
      for (const bucket of registry.values()) {
        const index = bucket.findIndex((thunk) => thunk.system === system)
        if (index !== -1) {
          bucket.splice(index, 1)
          return
        }
      }
    }
  }

  public on(trigger: Trigger.Create | Trigger.Delete, entity: Entity, archetype: Archetype<C>) {
    const registry = this.systemsRegistry[trigger]
    if (registry != null) {
      for (const systems of registry.values()) {
        for (const system of systems) {
          if (archetype.satisfies(system.signature)) {
            archetype.mutate(entity, system.signature, system.system as any)
          }
        }
      }
    }
  }

  // =====
  // Tasks
  // =====
  public get tasks() {
    return this._tasks
  }

  public timedTask(task: TaskFunction<C>, time: number, repeat: boolean) {
    const thunk = new TimedTask(task, time, repeat)
    this.timedTasksRegistry.push(thunk)
  }

  public task(trigger: TaskTrigger, task: TaskFunction<C>, priority: number) {
    const bucket = this.taskRegistry[trigger]
    const thunk = new Task(task, priority)
    bucket.push(thunk)
    bucket.sort(priorityComparator)
  }

  public deleteTask(task: TaskFunction<C>) {
    for (const registry of this.taskRegistry.values()) {
      const index = registry.findIndex((thunk) => thunk.task === task)
      if (index !== -1) {
        registry.splice(index, 1)
        return
      }
    }
  }

  // ======
  // Ticker
  // ======
  public tick = (elapsed: number) => {
    this.context.tick(elapsed)
    const dt = this.context.timer.delta

    // Timed
    for (const thunk of this.timedTasksRegistry) {
      thunk.age -= dt
      if (thunk.age <= 0.0) {
        if (thunk.repeat) {
          thunk.age = thunk.time
        }
        thunk.task(this.context)
      }
    }
    for (const thunk of this.timedSystemsRegistry) {
      thunk.age -= dt
      if (thunk.age <= 0.0) {
        if (thunk.repeat) {
          thunk.age = thunk.time
        }
        for (const archetype of this.archetypes.values()) {
          archetype.run(this.context, thunk.signature, thunk.system)
        }
      }
    }
    // even though this happens every frame, it is ok to allocate a new array and trash the old
    // since there is fare far les tasks nd systems than are the components, no need to optimize now
    this.timedTasksRegistry = this.timedTasksRegistry.filter((thunk) => thunk.age > 0.0)
    this.timedSystemsRegistry = this.timedSystemsRegistry.filter((thunk) => thunk.age > 0.0)

    // Before Update
    const tasksWillUpdate = this.taskRegistry[Trigger.BeforeUpdate]
    if (tasksWillUpdate) {
      for (const thunk of tasksWillUpdate) {
        thunk.task(this.context)
      }
    }

    const systemsWillUpdate = this.systemsRegistry[Trigger.BeforeUpdate]
    if (systemsWillUpdate != null) {
      for (const systems of systemsWillUpdate.values()) {
        for (const entry of systems) {
          for (const archetype of this.archetypes.values()) {
            archetype.run(this.context, entry.signature, entry.system as SystemFunction<C, any>)
          }
        }
      }
    }

    // Update
    const tasksUpdate = this.taskRegistry[Trigger.Update]
    if (tasksUpdate != null) {
      for (const thunk of tasksUpdate) {
        thunk.task(this.context)
      }
    }

    const systemsUpdate = this.systemsRegistry[Trigger.Update]
    if (systemsUpdate != null) {
      for (const systems of systemsUpdate.values()) {
        for (const entry of systems) {
          for (const archetype of this.archetypes.values()) {
            archetype.run(this.context, entry.signature, entry.system as SystemFunction<C, any>)
          }
        }
      }
    }

    // After Update
    const tasksDidUpdate = this.taskRegistry[Trigger.AfterUpdate]
    if (tasksDidUpdate != null) {
      for (const thunk of tasksDidUpdate) {
        thunk.task(this.context)
      }
    }

    const systemsDidUpdate = this.systemsRegistry[Trigger.AfterUpdate]
    if (systemsDidUpdate != null) {
      for (const systems of systemsDidUpdate.values()) {
        for (const entry of systems) {
          for (const archetype of this.archetypes.values()) {
            archetype.run(this.context, entry.signature, entry.system as SystemFunction<C, any>)
          }
        }
      }
    }

    this.context.swap()
  }
}
