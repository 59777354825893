import { CanvasContext } from '@cog/ecs-canvas-2d-plugin'
import { Vec2 } from '@cog/math'

import { Collider } from '../CollisionDetection/Collider'
import { Collision } from '../CollisionDetection/Collision'
import { Transform } from '../Common/Transform'
import { SparseSpatialGrid } from '../SpatialPartitioning/SparseSpatialGrid'

export class Physics {
  private _inverseMass: number
  private _mass: number
  private _velocity: Vec2
  private _dirty: boolean

  constructor(
    velocity = Vec2.Null(),
    mass = 1.0,
  ) {
    this._mass = mass
    this._inverseMass = 1.0 / mass
    this._velocity = velocity
    this._dirty = true
  }

  public get velocity() { return this._velocity }
  public set velocity(val) { this._dirty = true; this._velocity = val }

  public get mass() { return this._mass }
  public set mass(val) { this._inverseMass = 1.0 / val; this._mass = val }

  public get inverseMass() { return this._inverseMass }

  public get dirty() { return this._dirty }

  public process() { this._dirty = true }
}

export const resolveCollisions = (ctx: CanvasContext) => {
  ctx.world.query([Collision], (_, args) => {
    const collision = args[0]
    if (collision.fst != null && collision.snd != null) {
      ctx.world.queryEntity([Transform, Physics, Collider], collision.fst, (_, fst) => {
        // const fstTransform = fst[0]
        const fstPhysics = fst[1]
        ctx.world.queryEntity([Transform, Physics, Collider], collision.snd!, (_, snd) => {
          // const sndTransform = snd[0]
          const sndPhysics = snd[1]
          fstPhysics.velocity = fstPhysics.velocity.addScaled(collision.normal, -collision.penetration * fstPhysics.inverseMass)
          sndPhysics.velocity = sndPhysics.velocity.addScaled(collision.normal, collision.penetration * sndPhysics.inverseMass)
        })
      })
    }
  })
}

export const makeIntegrator = (grid: SparseSpatialGrid, layer: number) => (_: CanvasContext, args: [Transform, Physics, Collider], entity: number) => {
  const transform = args[0]
  const physics = args[1]
  const collider = args[2]

  if (physics.dirty) {
    transform.position = transform.position.add(physics.velocity)
    physics.velocity = Vec2.Null()

    if (transform.moved) {
      grid.insert(entity, transform, collider, layer)
    }

    physics.process()
  }
}
