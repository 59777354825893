import {
  loadImage,
  loadJSON,
  loadNinePatch,
  loadSprite,
  loadSpriteAtlas
} from './loaders'

export { Repository } from './Repository'

export const Loaders = {
  loadImage,
  loadJSON,
  loadNinePatch,
  loadSprite,
  loadSpriteAtlas,
}
