import { Reference } from '@cog/ecs'
import { ClassArgs } from '@cog/ecs/dist/internal'

export enum TransitionRepeatMode {
  NoRepeat = 0,
  Loop,
  BackAndForth,
}

const noop = () => {}

export type TransitionProps<T extends ClassArgs> = {
  duration: number
  delayStart?: number
  delayEnd?: number
  type?: TransitionRepeatMode,
  repeats?: number,
  reference: Reference<T>,
  mutation: (t: number, reference: Reference<T>) => void,
  onStart?: (reference: Reference<T>) => void
  onEnd?: (reference: Reference<T>) => void
}

export class Transition<T extends ClassArgs> {
  public current: number
  public loopCount: number
  public direction: -1 | 1
  public t: number

  constructor(
    public duration: number = 1.0,
    public delayStart: number = 0.0,
    public delayEnd: number = 0.0,
    public type: TransitionRepeatMode = TransitionRepeatMode.NoRepeat,
    public repeats: number = 0,
    public reference: Reference<T> = Reference.Null,
    public mutation: (t: number, reference: Reference<T>) => void = noop,
    public onStart: (reference: Reference<T>) => void = noop,
    public onEnd: (reference: Reference<T>) => void = noop,
  ) {
    this.current = 0.0
    this.t = 0.0
    this.direction = 1
    this.loopCount = 0

    if (onStart != null) {
      onStart(reference)
    }
  }

  public get total() { return this.delayStart + this.duration + this.delayEnd }

  public reset() {
    this.duration = 1.0
    this.delayStart = 0.0
    this.delayEnd = 0.0
    this.type = TransitionRepeatMode.NoRepeat
    this.repeats = 0
    this.reference = Reference.Null
    this.mutation = noop
    this.onStart = noop
    this.onEnd = noop
    this.current = 0.0
    this.t = 0.0
    this.direction = 1
    this.loopCount = 0
  }
}
