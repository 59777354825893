import { Vec2, Vec3 } from '@cog/math'

export enum Button {
  Left = 0,
  Middle = 1,
  Right = 2,
  Back = 3,
  Forward = 4,
}

class MouseButtonState {
  constructor(
    public isDown: boolean = false,
    public wasPressed: boolean = false,
    public wasReleased: boolean = false,
  ) {}

  public get isUp() { return !this.isDown }
}

class MouseButtonsState {
  public buttons: [MouseButtonState, MouseButtonState, MouseButtonState, MouseButtonState, MouseButtonState]
  constructor(
    left = new MouseButtonState(),
    middle = new MouseButtonState(),
    right = new MouseButtonState(),
    back = new MouseButtonState(),
    forward = new MouseButtonState(),
  ) {
    this.buttons = [left, middle, right, back, forward]
  }
}

class MousePointerState {
  constructor(
    public delta = new Vec2(),
    public position = new Vec2(),
    public wasMoved = false,
  ) {}

  public get wasIdle() { return !this.wasMoved }
}

class MouseWheelState {
  constructor(
    public position = new Vec3(),
    public delta = new Vec3(),
    public wasScrolledDown = false,
    public wasScrolledUp = false,
    public wasScrolledLeft = false,
    public wasScrolledRight = false,
    public wasScrolledForward = false,
    public wasScrolledBack = false,
  ) {}
}

class MouseState {
  constructor(
    public buttons = new MouseButtonsState(),
    public pointer = new MousePointerState(),
    public wheel = new MouseWheelState(),
  ) {}
}

export class Mouse {
  private state = [new MouseState(), new MouseState()]
  private activeBuffer: 0|1 = 0
  private buttonStateView = new MouseButtonState()
  private pointerStateView = new MousePointerState()
  private wheelStateView = new MouseWheelState()

  constructor(private readonly document: HTMLDocument) {
    this.subscribe()
  }

  public get left(): Readonly<MouseButtonState> { return this.getButtonView(0) }
  public get middle(): Readonly<MouseButtonState> { return this.getButtonView(1) }
  public get right(): Readonly<MouseButtonState> { return this.getButtonView(2) }
  public get back(): Readonly<MouseButtonState> { return this.getButtonView(3) }
  public get forward(): Readonly<MouseButtonState> { return this.getButtonView(4) }

  public get pointer(): Readonly<MousePointerState> {
    const current = this.state[this.activeBuffer].pointer

    this.pointerStateView.delta = current.delta
    this.pointerStateView.position = current.position
    this.pointerStateView.wasMoved = current.delta.x !== 0 || current.delta.y !== 0

    return this.pointerStateView
  }

  public get wheel(): Readonly<MouseWheelState> {
    const prevIndex = ((this.activeBuffer + 1) % 2) as (0 | 1)
    const previous = this.state[prevIndex].wheel
    const current = this.state[this.activeBuffer].wheel

    this.wheelStateView.delta = current.delta

    this.wheelStateView.wasScrolledDown = current.position.y < previous.position.y
    this.wheelStateView.wasScrolledUp = current.position.y > previous.position.y

    this.wheelStateView.wasScrolledLeft = current.position.x < previous.position.x
    this.wheelStateView.wasScrolledRight = current.position.x > previous.position.x

    this.wheelStateView.wasScrolledBack = current.position.z < previous.position.z
    this.wheelStateView.wasScrolledForward = current.position.z > previous.position.z

    return this.wheelStateView
  }

  public swap() {
    const prevIndex = ((this.activeBuffer + 1) % 2) as (0 | 1)
    const previous = this.state[prevIndex]
    const next = previous
    const current = this.state[this.activeBuffer]

    for (let i = 0; i < 5; ++i) {
      next.buttons.buttons[i].isDown = current.buttons.buttons[i].isDown
      next.buttons.buttons[i].wasPressed = false
      next.buttons.buttons[i].wasReleased = false
    }

    next.pointer.delta = Vec2.Null()
    next.pointer.position = current.pointer.position
    next.pointer.wasMoved = false

    next.wheel.delta = Vec3.Null()
    next.wheel.position = current.wheel.position

    this.activeBuffer = prevIndex
  }

  private getButtonView(button: Button) {
    const view = this.buttonStateView
    const current = this.state[this.activeBuffer].buttons.buttons
    const previous = this.state[(this.activeBuffer + 1) % 2].buttons.buttons

    view.isDown = current[button].isDown
    view.wasPressed = current[button].isDown && previous[button].isUp || current[button].wasPressed
    view.wasReleased = current[button].isUp && previous[button].isDown || current[button].wasReleased

    return view
  }

  private subscribe() {
    this.document.addEventListener('mousedown', this.onButtonDown, true)
    this.document.addEventListener('mouseup', this.onButtonUp, true)
    this.document.addEventListener('wheel', this.onMouseWheel, { passive: true, capture: true })
    this.document.addEventListener('mousemove', this.onMouseMove, true)
  }

  // TODO: handle cleanup
  // private unsubscribe() {
  //   this.document.removeEventListener('mousedown', this.onButtonDown)
  //   this.document.removeEventListener('mouseup', this.onButtonUp)
  //   this.document.removeEventListener('wheel', this.onMouseWheel)
  //   this.document.removeEventListener('mousemove', this.onMouseMove)
  // }

  private onButtonDown = (event: MouseEvent): void => {
    const button = event.button as Button
    const current = this.state[this.activeBuffer].buttons.buttons[button] as MouseButtonState
    current.isDown = true
    current.wasPressed = true
  }

  private onButtonUp = (event: MouseEvent): void => {
    const button = event.button as Button
    const current = this.state[this.activeBuffer].buttons.buttons[button] as MouseButtonState
    current.isDown = false
    current.wasReleased = true
  }

  private onMouseWheel = (event: WheelEvent): void => {
    const current = this.state[this.activeBuffer].wheel
    current.position = current.position.add(new Vec3(event.deltaX, event.deltaY, event.deltaZ))
    current.delta = current.delta.add(new Vec3(event.deltaX, event.deltaY, event.deltaZ))
  }

  private onMouseMove = (event: MouseEvent): void => {
    const current = this.state[this.activeBuffer].pointer
    const previous = this.state[(this.activeBuffer + 1) % 2].pointer
    current.delta = current.delta.add(current.position.sub(previous.position))
    current.position = new Vec2(event.clientX, event.clientY)
  }
}
