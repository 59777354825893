import { Context } from '@cog/ecs'

import { Scene } from './Scene'

// TODO: move this guy to common types lib or something
export declare type ClassType<T = any> = {
  new (): T
}

// tslint:disable-next-line:ban-types
type Constructor<T> = Function & { prototype: T; new(): T }

export class SceneManager<C extends Context> {
  private _stack: Array<Scene<C>> = []

  constructor(
    private context: C
  ) {}

  public push<S extends Scene<C>>(ctor: Constructor<S>) {
    const scene = new ctor()
    ;(scene as any).world = this.context.world
    this._stack.push(scene)
    scene.performCreate(this.context.world)
  }

  public pop() {
    const scene = this._stack.pop()
    if (scene == null) {
      throw new Error(`Scene stack is empty!`)
    } else {
      scene.performDelete(this.context.world)
    }
  }

  public popAll() {
    while (this._stack.length > 0) {
      const scene = this._stack.pop()!
      scene.performDelete(this.context.world)
    }
  }
}
