import { BLANK_CANVAS, BLANK_IMAGE_DATA } from './common'

export class AtlasEntry {
  constructor(
    public name: string,
    public x: number,
    public y: number,
    public w: number,
    public h: number,
  ) {}
}

type AtlasDict = { [key: string]: AtlasEntry }

export class SpriteAtlas {
  constructor(
    public sprite: HTMLCanvasElement = BLANK_CANVAS,
    public buffer: ImageData = BLANK_IMAGE_DATA,
    public atlas: AtlasDict = {}
  ) {}
}
