import { World } from '@cog/ecs'

import { CanvasContext } from '../../Context/CanvasContext'
import { Button } from '../Components/Button'
import { Label } from '../Components/Label'
import { ElementState, State } from '../Components/State'
import { Style } from '../Components/Style'


type Props<C extends CanvasContext> = Partial<{
  onClick: (ctx: C, entity: number) => void,
  onEnter: (ctx: C, entity: number) => void,
  onLeave: (ctx: C, entity: number) => void,
  style: Partial<Parameters<typeof Style.prototype.init>[0]>,
  label: Partial<Parameters<typeof Label.prototype.init>[0]>,
  disabled: boolean,
}>
export const makeButton = <C extends CanvasContext>(
  world: World<C>,
  props: Props<C>,
) =>
  world.entity([Style, Button, Label, State], (args) => {
    const style = args[0]
    const button = args[1]
    const label = args[2]
    const state = args[3]

    style.init(props.style)
    button.init({ onClick: props?.onClick })
    label.init(props.label)
    state.init({
      onEnter: props?.onEnter as any,
      onLeave: props?.onLeave as any,
      state: props?.disabled ? ElementState.Disabled : ElementState.Idle,
    })
  })
