import { Entity, Init } from '@cog/ecs'
import { Vec2 } from '@cog/math'


export class Joint implements Init<Joint> {
  constructor(
    public skeleton: Entity = -1,
    public position: Vec2 = new Vec2(),
    public lengthConstraintMin = 0,
    public lengthConstraintMax = 0,
    public angleConstraintMin = 0,
    public angleConstraintMax = 0,
  ) {}

  public init(props: Joint) {
    this.position = props.position
    this.lengthConstraintMin = props.lengthConstraintMin
    this.lengthConstraintMax = props.lengthConstraintMax
    this.angleConstraintMin = props.angleConstraintMin
    this.angleConstraintMax = props.angleConstraintMax
    return this
  }
}
