import { CanvasContext } from '../../Context/CanvasContext'
import { Label } from '../Components/Label'
import { State } from '../Components/State'
import { Style } from '../Components/Style/Style'
import { renderElement } from './renderElement'


export const render = <C extends CanvasContext>(ctx: C) => {
  const sortedByZIndex: Array<[Style, State<C>, Label]> = []

  ctx.world.query([Style, State, Label], (_, args) =>
    sortedByZIndex.push([args[0], args[1], args[2]]))

  sortedByZIndex.sort((a, b) => a[0].positionZ < b[0].positionZ ? -1 : 1)

  for (const it of sortedByZIndex) {
    const style = it[0]
    const state = it[1]
    const label = it[2]
    renderElement(ctx, style, state, label)
  }
}
