import { World } from '@cog/ecs'
import {
  CanvasContext,
  Gui,
  Scene,
  Sequence,
  Timers,
  Transitions,
} from '@cog/ecs-canvas-2d-plugin'

import {
  FadeFromBlack,
  FadeToBlack,
  ReplaceCurrentScene,
  WaitOrCancel,
} from '../Actions'
import { clearScreen } from '../commonSystems'
import { SceneRegistry } from '../sceneRegistry'
import { background, splashText } from './commonStyles'

export class Splash extends Scene<CanvasContext> {
  public create(world: World<CanvasContext>) {
    Gui.Create(world)
    Timers.Create(world)
    Transitions.Create(world)

    Gui.Element.Label(world, { style: splashText(), label: { text: '#TODO: Splash text' } })
    Gui.Element.Pane(world, { style: background })
    const overlay = Gui.Element.Pane(world, { style: { ...background, idle: { ...background.idle, background: { color: 0xff }}} })

    world.context.actionManager.run(
      new Sequence([
        new FadeFromBlack(overlay),
        new WaitOrCancel(2),
        new FadeToBlack(overlay),
        new ReplaceCurrentScene(SceneRegistry.MainMenu),
      ]),
    )

    world.task.on.before(clearScreen)
  }

  public delete(world: World<CanvasContext>) {
    Gui.Delete(world)
    Timers.Delete(world)
    Transitions.Delete(world)

    world.task.delete(clearScreen)
  }
}
