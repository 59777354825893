import { Just, Maybe, Nothing } from '../data/Maybe'

type Zip = <T, S>(t: T[], s: S[]) => Array<[T, S]>
const zipImpl: any = (t: any, s: any) => {
  if (t.length !== s.length) {
    throw new Error('zip arguments must be of the same size')
  } else {
    return t.map((te: any, i: any) => [te, s[i]])
  }
}
export const zip: Zip = zipImpl

export const head = <T>(list: T[]): Maybe<T> =>
  list.length
    ? new Just(list[0])
    : new Nothing()

export const tail = <T>(list: T[]): Maybe<T> =>
  list.length
    ? new Just(list[list.length - 1])
    : new Nothing()
