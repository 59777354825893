import { CanvasContext } from '../../Context'
import { Action } from './Action'

export class ActionManager<C extends CanvasContext> {
  private _actions: Array<Action<C>> = []

  constructor(
    private _context: C
  ) {}

  public run(action: Action<C>) {
    this._actions.push(action)
  }

  public clear() {
    this._actions = []
  }

  public swap() {
    this._actions.forEach((action) => action.run(this._context))
    this._actions = this._actions.filter((action) => !action.isDone)
  }
}
