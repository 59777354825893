import { BLANK_CANVAS, BLANK_IMAGE_DATA } from './common'

export class Sprite {
  constructor(
    public sprite: HTMLCanvasElement = BLANK_CANVAS,
    public imageData: ImageData = BLANK_IMAGE_DATA,
    public width: number = 1,
    public height: number = 1,
  ) {}
}
