import { CanvasContext, Scene } from '@cog/ecs-canvas-2d-plugin'


// TODO: move this guy to common types lib or something
export type ClassType<T = any> = {
  new (): T
}

export const SceneRegistry: {
  Splash: ClassType<Scene<CanvasContext>>,
  LoadAssets: ClassType<Scene<CanvasContext>>,
  MainMenu: ClassType<Scene<CanvasContext>>,
  ArkanoidExample: ClassType<Scene<CanvasContext>>,
  BasicEcsScene: ClassType<Scene<CanvasContext>>
  PhysicsECSExample: ClassType<Scene<CanvasContext>>,
  Cave: ClassType<Scene<CanvasContext>>,
  IK: ClassType<Scene<CanvasContext>>,
  IKProceduralBiped: ClassType<Scene<CanvasContext>>,
} = {
} as any
