import { BLANK_CANVAS, BLANK_IMAGE_DATA } from './common'

export class NinePatchSprite {
  constructor(
    public sprite: HTMLCanvasElement = BLANK_CANVAS,
    public buffer: ImageData = BLANK_IMAGE_DATA,
    public width: number = 1,
    public height: number = 1,
    public left: number = 0,
    public top: number = 0,
    public right: number = 0,
    public bottom: number = 0,
  ) {}
}
