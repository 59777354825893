import { Vec2 } from '@cog/math'

import { Bone2d } from './Bone2d'


export class EndEffector2d {
  public bones: Bone2d[] = []

  constructor(
    public goal: Vec2,
    public offset: Vec2 = Vec2.Null(),
  ) {}

  public addBone(bone: Bone2d) {
    if (this.bones.find((j) => j === bone) == null) {
      this.bones.push(bone)
      bone.endEffector = this
    }
    return this
  }
}
