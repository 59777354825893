import { CanvasContext, HEIGHT, WIDTH } from '@cog/ecs-canvas-2d-plugin'

import { BallProps } from '../Components/Ball'
import { BrickProps } from '../Components/Brick'
import { Material, Transform } from '../Components/Common'
import { PaddleProps } from '../Components/Paddle'
import { Ball, Brick, Paddle } from '../Components/Tags'


export const clearScreen = (ctx: CanvasContext) => {
  ctx.graphics.gfx.clearRect(0, 0, WIDTH, HEIGHT)
}

export const renderPaddle = (ctx: CanvasContext, [transform, material, props]: [Transform, Material, PaddleProps, Paddle]) => {
  const g = ctx.graphics.gfx

  g.strokeStyle = `rgb(${material.r}, ${material.g}, ${material.b})`
  g.beginPath()
  g.rect(transform.position.x - (props.width >> 1), transform.position.y, props.width, 3)
  g.stroke()
}

export const renderBall = (ctx: CanvasContext, [transform, material, props]: [Transform, Material, BallProps, Ball]) => {
  const g = ctx.graphics.gfx

  g.strokeStyle = `rgb(${material.r}, ${material.g}, ${material.b})`
  g.beginPath()
  g.moveTo(transform.position.x + props.radius, transform.position.y)
  g.arc(transform.position.x, transform.position.y, props.radius, 0, Math.PI * 2)
  g.stroke()
}

export const renderBrick = (ctx: CanvasContext, [transform, material, props]: [Transform, Material, BrickProps, Brick]) => {
  const g = ctx.graphics.gfx

  g.strokeStyle = `rgb(${material.r}, ${material.g}, ${material.b})`
  g.beginPath()
  g.rect(transform.position.x, transform.position.y, props.aabr.width, props.aabr.height)
  g.stroke()
}
