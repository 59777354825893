export const Resource = {
  Sprite: {
    FontAtlas: {
      DefaultFont: -1,
    },
    NinePatch: {
      DefaultButton: -1,
      OutlineButton: -1,
      Waterfall: -1,
    },
  },
}
