import { CanvasContext, WIDTH } from '@cog/ecs-canvas-2d-plugin'
import { Vec2 } from '@cog/math'

import { Physics, Transform } from '../Components/Common'
import { PaddleProps } from '../Components/Paddle'
import { Paddle } from '../Components/Tags'


export const updatePaddle = (ctx: CanvasContext, [transform, physics, props]: [Transform, Physics, PaddleProps, Paddle]) => {
  const dt = ctx.timer.delta
  const keyboard = ctx.io.keyboard

  let dx = transform.position.x
  if (keyboard.key('a').isDown) { dx -= physics.speed * dt }
  if (keyboard.key('d').isDown) { dx += physics.speed * dt }

  const halfWidth = props.width >> 1
  if (dx - halfWidth < 0) {
    dx = halfWidth
  } else if (dx + halfWidth > WIDTH) {
    dx = WIDTH - halfWidth
  }

  transform.position = new Vec2(dx, transform.position.y)
}
