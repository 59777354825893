import { Entity, World } from '@cog/ecs'
import { CanvasContext, Gui, Scene, Sequence, Transitions } from '@cog/ecs-canvas-2d-plugin'
import { Vec2 } from '@cog/math'

import { FadeFromBlack, FadeToBlack, ReplaceCurrentScene } from '../../Actions'
import { background, defaultIdleStyle, defaultModifiers } from '../../Scenes/commonStyles'
import { SceneRegistry } from '../../sceneRegistry'
import { Collider } from './CollisionDetection/Collider'
import { Collision } from './CollisionDetection/Collision'
import { makeCollisionDetector } from './CollisionDetection/collisionDetector'
import { Transform } from './Common/Transform'
import { makeIntegrator, resolveCollisions, Physics } from './Physics/Physics'
import { Material } from './Renderer'
import { SparseSpatialGrid } from './SpatialPartitioning/SparseSpatialGrid'


// Tags
class Player {}
class Metric {
  constructor(
    public dt = 0.0,
    public collisions = 0,
    public age = 0.0,
  ) {}
}

const GAME_LAYER = 0

const beginRender = (ctx: CanvasContext) => {
  ctx.graphics.gfx.fillStyle = 'black'
  ctx.graphics.gfx.fillRect(0, 0, ctx.graphics.canvas.width, ctx.graphics.canvas.height)
}

const renderCollidersAsTask = (ctx: CanvasContext) => {
  const g = ctx.graphics.gfx

  g.beginPath()
  g.strokeStyle = 'rgba(255, 0, 0, 0.5)'

  ctx.world.query([Collider, Transform], (_, it) => {
    const collider = it[0]
    const transform = it[1]

    const x = transform.position.x
    const y = transform.position.y

    g.moveTo(x + collider.radius, y)
    g.arc(x, y, collider.radius, 0.0, 2.0 * Math.PI)
  })

  g.stroke()
}

const renderCollisions = (ctx: CanvasContext) => {
  let collision
  ctx.world.query([Collision], (_, args) => {
    collision = args[0]
    if (collision.fst != null && collision.snd != null) {
      const g = ctx.graphics.gfx

      g.beginPath()
      g.strokeStyle = 'rgba(0, 128, 255, 0.5)'

      const x = collision.middle.x
      const y = collision.middle.y

      g.moveTo(x, y)
      g.rect(x - 1, y - 1, 3, 3)

      g.stroke()
    }
  })
}

const playerMovement = (ctx: CanvasContext, args: [Transform, Physics, Player]) => {
  const transform = args[0]
  const phys = args[1]

  let x = 0
  let y = 0
  if (ctx.io.mouse.left.isDown) {
    const pos = CanvasContext.GetMousePos(ctx)
    const toPointer = pos.sub(transform.position).normalize()
    x = toPointer.x * ctx.timer.delta
    y = toPointer.y * ctx.timer.delta
  } else {
    if (ctx.io.keyboard.key('w').isDown) { y -= ctx.timer.delta }
    if (ctx.io.keyboard.key('s').isDown) { y += ctx.timer.delta }
    if (ctx.io.keyboard.key('a').isDown) { x -= ctx.timer.delta }
    if (ctx.io.keyboard.key('d').isDown) { x += ctx.timer.delta }
  }

  phys.velocity = phys.velocity.addScaled(new Vec2(x, y), 100.0)
}

const instrumentation = (ctx: CanvasContext) => {
  let sum = 0.0
  let samples = 0
  let max = 0
  let collisions = 0

  ctx.world.query([Metric], (_, it) => {
    if (max < it[0].dt) { max = it[0].dt }
    sum += it[0].dt
    collisions += it[0].collisions
    it[0].age -= ctx.timer.delta
    ++samples
  })

  ctx.world.deleteWhere([Metric], (it) => it[0].age <= 0)

  ctx.world.entity([Metric], (it) => {
    it[0].dt = ctx.timer.delta
    it[0].age = 1.0
    it[0].collisions = ctx.world.count([Collision])
  })

  const fps = 1.0 / (sum / samples)
  ctx.graphics.gfx.fillStyle = 'rgba(128, 128, 128, 0.8)'
  ctx.graphics.gfx.fillRect(0, ctx.width - 36, 90, 36)
  ctx.graphics.gfx.fillStyle = 'white'
  ctx.graphics.gfx.fillText(`${fps.toFixed(2)} fps`, 2, ctx.height - 26)
  ctx.graphics.gfx.fillText(`${(sum / samples).toFixed(4)} ms/f`, 2, ctx.height - 14)
  ctx.graphics.gfx.fillText(`${(collisions / samples).toFixed(0)} collisions/f`, 2, ctx.height - 2)
}

export class Physics2dScene extends Scene<CanvasContext> {
  private player?: Entity
  private blobs: Entity[] = []
  private grid?: SparseSpatialGrid
  private gameObjectCollisionDetector?: ReturnType<typeof makeCollisionDetector>
  private gameObjectIntegrator?: ReturnType<typeof makeIntegrator>

  public create(world: World<CanvasContext>) {
    this.using(Gui, Transitions)

    this.grid = new SparseSpatialGrid(16, 16)

    this.gameObjectCollisionDetector = makeCollisionDetector(this.grid, GAME_LAYER, world)
    this.gameObjectIntegrator = makeIntegrator(this.grid, GAME_LAYER)

    world.system.on.create([Transform, Collider], this.registerColliderToGameLayer)
    world.system.on.delete([Transform, Collider], this.unregisterColliderFromGameLayer)

    const overlay = Gui.Element.Pane(world, { style: { ...background, idle: { ...background.idle, background: { color: 0xff }}} })
    world.context.actionManager.run(new FadeFromBlack(overlay))

    Gui.Element.Button(world, {
      onClick: () => {
        world.context.actionManager.run(
          new Sequence([
            new FadeToBlack(overlay),
            new ReplaceCurrentScene(SceneRegistry.MainMenu),
          ]),
        )
      },

      label: { text: '<-' },
      style: { ...defaultModifiers, idle: {
        ...defaultIdleStyle().idle,
        position: { left: 5, top: 5, right: 25, bottom: 25 },
      }},
    })

    const nx = 4 * 16
    const ny = 4 * 9
    for (let i = 0; i < nx; ++i) {
      for (let j = 0; j < ny; ++j) {
        this.blobs.push(world.entity([Transform, Material, Collider, Physics], (args) => {
          const transform = args[0]
          // const material = args[1]
          const collider = args[2]
          const physics = args[3]

          const x = 0.1 * world.context.width + i * world.context.width * 0.8 / nx
          const y = 0.1 * world.context.height + j * world.context.height * 0.8 / ny
          const r = 2 // + Math.random() * 4.0
          const m = 2.0 + r
          transform.position = new Vec2(x, y)
          collider.radius = r
          physics.mass = m
        }))
      }
    }

    console.log(`Created ${nx * ny} entities with [Transform, Material, Collider, Physics] components`)

    this.player = world.entity([Transform, Material, Collider, Physics, Player], (args) => {
      const transform = args[0]
      // const material = args[1]
      const collider = args[2]
      const physics = args[3]

      const x = 0.5 * world.context.width
      const y = 0.0
      const r = 16.0
      const m = 50.0
      transform.position = new Vec2(x, y)
      collider.radius = r
      physics.mass = m
    })

    world.task.on.before(this.gameObjectCollisionDetector)
    world.system.on.before([Transform, Physics, Player], playerMovement)
    world.task.on.update(resolveCollisions)
    world.system.on.update([Transform, Physics, Collider], this.gameObjectIntegrator)
    world.task.on.update(beginRender)
    world.task.on.update(renderCollidersAsTask)
    world.task.on.update(renderCollisions)
    world.task.on.after(this.renderCells)
    world.task.on.update(instrumentation)
  }

  public delete(world: World<CanvasContext>) {
    world.delete(this.player!)
    this.blobs.forEach((id) => world.delete(id))

    // TODO: remove as any after tsc gets fixed to correctly infer nested type params
    world.system.delete(this.registerColliderToGameLayer as any)
    world.system.delete(this.unregisterColliderFromGameLayer as any)

    world.system.delete(this.gameObjectIntegrator!)
    world.system.delete(playerMovement)

    world.task.delete(this.gameObjectCollisionDetector!)

    world.task.delete(resolveCollisions)
    world.task.delete(beginRender)
    world.task.delete(renderCollidersAsTask)
    world.task.delete(renderCollisions)
    world.task.delete(this.renderCells)
    world.task.delete(instrumentation)
  }

  private registerColliderToGameLayer = (args: [Transform, Collider], entity: Entity) => {
    const transform = args[0]
    const collider = args[1]
    this.grid!.insert(entity, transform, collider, GAME_LAYER)
  }

  private unregisterColliderFromGameLayer = (_args: [Transform, Collider], entity: Entity) => {
    this.grid!.remove(entity)
  }

  private renderCells = (ctx: CanvasContext) => {
    const g = ctx.graphics.gfx
    g.strokeStyle = 'rgba(255, 255, 255, 0.2)'
    g.lineWidth = 1

    const grid = this.grid!

    g.beginPath()
    grid.cells(0, (_layer, x, y) => {
      g.rect((x * grid.cellSizeX)|0, (y * grid.cellSizeY)|0, grid.cellSizeX, grid.cellSizeY)
    })
    g.stroke()
  }
}
