import { Context } from '@cog/ecs'
import { Vec2 } from '@cog/math'

import { ActionManager } from '../Driver/ActionManager/ActionManager'
import { IO } from '../Driver/IO'
import { SceneManager } from '../Driver/SceneManagement/SceneManager'

export const SCALE = 2.0
export const WIDTH = 192 * SCALE
export const HEIGHT = 108 * SCALE
const MAX_WIDTH = 1920

export class CanvasContext extends Context {
  static GetMousePos = (ctx: CanvasContext) => {
    const mouse = ctx.io.mouse
    const rect = ctx.graphics.canvas.getBoundingClientRect()
    const dx = rect.left
    const dy = rect.top
    const w = rect.width
    const h = rect.height

    const mpx = Math.min(Math.max((mouse.pointer.position.x - dx) / w, 0.0), 1.0) * ctx.width
    const mpy = Math.min(Math.max((mouse.pointer.position.y - dy) / h, 0.0), 1.0) * ctx.height

    return new Vec2(mpx, mpy)
  }

  private _graphics: {
    gfx: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
  }
  private _io: IO
  private _sceneManager: SceneManager<this>
  private _actionManager: ActionManager<this>

  private _width: number
  private _height: number
  private _maxWidth: number

  constructor(
    width: number = WIDTH,
    height: number = HEIGHT,
    maxWidth: number = MAX_WIDTH,
  ) {
    super()

    this._width = width
    this._height = height
    this._maxWidth = maxWidth

    this._io = new IO(window.document)

    const canvas = window.document.getElementById('root') as unknown as HTMLCanvasElement
    this._graphics = {
      canvas,
      gfx: canvas.getContext('2d')!
    }
    canvas.width = this.width
    canvas.height = this.height
    window.addEventListener('resize', this.onResize, { passive: true })
    this.onResize()

    document.body.oncontextmenu = () => false

    this._sceneManager = new SceneManager(this)
    this._actionManager = new ActionManager(this)
  }

  public get width() { return this._width }
  public get height() { return this._height }
  public get maxWidth() { return this._maxWidth }

  public resize(
    width: number = WIDTH,
    height: number = HEIGHT,
    maxWidth: number = MAX_WIDTH,
  ) {
    this._width = width
    this._height = height
    this._maxWidth = maxWidth

    this._graphics.canvas.width = width
    this._graphics.canvas.height = height

    this.onResize()
  }

  public swap() {
    this._io.swap()
    this._actionManager.swap()
  }

  private onResize = () => {
    const w = window.innerWidth
    const h = window.innerHeight

    const ratio = this.width / this.height

    const cw = Math.min(ratio * h < w ? ratio * h : w, this.maxWidth)
    const ch = cw / ratio

    this.graphics.canvas.style.width = `${cw}px`
    this.graphics.canvas.style.height = `${ch}px`
  }

  public get graphics() { return this._graphics }
  public get io(): Readonly<Omit<IO, 'swap'>> { return this._io }
  public get sceneManager() { return this._sceneManager }
  public get actionManager() { return this._actionManager }
}
