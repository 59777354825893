import { CanvasContext } from '../../Context'


export abstract class Action<C extends CanvasContext> {
  protected _isDone: boolean = false
  protected _isRunning: boolean = false

  public run(ctx: C) {
    if (!this.isDone) {
      if (!this._isRunning) {
        this.onStart(ctx)
        this._isRunning = true
      }
      const done = this.onUpdate(ctx)
      if (done && !this.isDone) {
        this.onEnd(ctx)
        this._isDone = true
      }
    }
  }

  public get isDone() { return this._isDone }
  public finish = () => { this._isDone = true }

  public onStart(ctx: C) {}
  public onUpdate(ctx: C) { return this.isDone }
  public onEnd(ctx: C) {}
}

export class Parallel<C extends CanvasContext> extends Action<C> {
  private _actions: Array<Action<C>>

  constructor(actions?: Array<Action<C>>) {
    super()
    this._actions = actions ?? []
  }

  public run(ctx: C) {
    if (!this.isDone) {
      if (!this._isRunning) {
        this.onStart(ctx)
        this._isRunning = true
      }
      if (!this.isDone && this.onUpdate(ctx)) {
        this.onEnd(ctx)
      }
    }
  }

  public finish = () => {
    this._actions.forEach((action) => action.finish())
    this._actions = []
    this._isDone = true
  }

  public onStart(ctx: C) {}
  public onUpdate(ctx: C) {
    this._actions.forEach((action) => action.run(ctx))
    this._actions = this._actions.filter((action) => !action.isDone)
    if (this._actions.length === 0) {
      this.finish()
    }
    return this.isDone
  }
  public onEnd(ctx: C) {}
}

export class Sequence<C extends CanvasContext> extends Action<C> {
  private _actions: Array<Action<C>>

  constructor(actions?: Array<Action<C>>) {
    super()
    this._actions = actions ?? []
  }

  public run(ctx: C) {
    if (!this.isDone) {
      if (!this._isRunning) {
        this.onStart(ctx)
        this._isRunning = true
      }
      if (!this.isDone && this.onUpdate(ctx)) {
        this.onEnd(ctx)
      }
    }
  }

  public finish = () => {
    this._actions.forEach((action) => action.finish())
    this._actions = []
    this._isDone = true
  }

  public onStart(ctx: C) {}
  public onUpdate(ctx: C) {
    this._actions[0].run(ctx)
    this._actions = this._actions.filter((action) => !action.isDone)
    if (this._actions.length === 0) {
      this.finish()
    }
    return this.isDone
  }
  public onEnd(ctx: C) {}
}
