import { Vec3 } from './Vec3'
import { clamp, EPSILON } from './utils'

export class CatmullRomSpline3 {
  private arcs: number[] = []
  private len: number = 0.0

  constructor(
    private controlPoints: Vec3[],
    closed: boolean = false,
    segmentResolution: number = 100,
  ) {
    if (this.controlPoints.length < 4) {
      throw new Error('[CatmullRom3] Can not construct a spline - at least 4 control poligon points must be provided')
    }
    if (segmentResolution <= 0) {
      throw new Error('[CatmullRom3] Can not construct a spline - segmentResolution must be greater than 0')
    }

    if (closed) {
      this.controlPoints.push(this.controlPoints[0])
      this.controlPoints.push(this.controlPoints[1])
      this.controlPoints.push(this.controlPoints[2])
    }

    const numSegments = this.controlPoints.length - 3
    const step = 1.0 / segmentResolution
    let len: number
    let tmp: Vec3
    let t: number
    let start: Vec3
    let end: Vec3

    // iterate over segments
    for (let i = 0; i < numSegments; ++i) {
      len = 0
      start = end = this.evalSegmentPosition(0.0, i)
      // aproximate lenghts for current segment
      for (let j = 1; j <= segmentResolution; ++j) {
        t = j * step

        // protect from extrapolation
        if (t >= 1.0) {
          t = 1.0
        }

        end = this.evalSegmentPosition(t, i)
        tmp = end.sub(start)
        len += tmp.len()
        start = end
      }

      this.len += len
      this.arcs.push(len)
    }
  }

  public evaluatePosition(t: number): Vec3 {
    // first we have to find in which segment our t lies
    const mlen = clamp(t, 0.0, 1.0) * this.len
    let tlen = 0.0
    let i

    for (i = 0; i < this.arcs.length; ++i) {
      // if target len is in between curent segments start len and end len we have found a target segment
      if (mlen <= (tlen + this.arcs[i])) {
        break
      } else {
        tlen += this.arcs[i]
      }
    }

    const nt = (mlen - tlen) / this.arcs[i]
    // get the position at target interpolation factor for target segment
    return this.evalSegmentPosition(nt, i)
  }

  public evaluateGradientNumeric(t: number, epsilon: number = EPSILON): Vec3 {
    // first we have to find in which segment our t lies
    const mlen = clamp(t, 0.0, 1.0) * this.len
    let tlen = 0.0
    let i

    for (i = 0; i < this.arcs.length; ++i) {
      // if target len is in between curent segments start len and end len we have found a target segment
      if (mlen <= (tlen + this.arcs[i])) {
        break
      } else {
        tlen += this.arcs[i]
      }
    }

    // transform our interpolation factor to target segment's lenght span
    const nt = (mlen - tlen) / (this.arcs[i])

    // get the position at target interpolation factor for target segment
    return this.evalSegmentGradientNumeric(nt, epsilon, i)
  }

  public evaluateGradient(t: number): Vec3 {
    // first we have to find in which segment our t lies
    const mlen = clamp(t, 0.0, 1.0) * this.len
    let tlen = 0.0
    let i

    for (i = 0; i < this.arcs.length; ++i) {
      // if target len is in between curent segments start len and end len we have found a target segment
      if (mlen <= (tlen + this.arcs[i])) {
        break
      } else {
        tlen += this.arcs[i]
      }
    }

    // transform our interpolation factor to target segment's lenght span
    const nt = (mlen - tlen) / (this.arcs[i])

    // get the position at target interpolation factor for target segment
    return this.evalSegmentGradient(nt, i)
  }

  public samplePositions(start: number, end: number, samples: number) {
    const ret: Vec3[] = []
    for (let t = start; t <= end; t += ((end - start) / samples)) {
      ret.push(this.evaluatePosition(t))
    }
    return ret
  }

  private evalSegmentPosition(t: number, segment: number): Vec3 {
    const i = segment + 1
    const t2 = t * t
    const t3 = t2 * t

    return new Vec3(
      0.5*( (2.0 * this.controlPoints[i].x) +
            (     -this.controlPoints[i-1].x +       this.controlPoints[i+1].x) * t +
            (2.0 * this.controlPoints[i-1].x - 5.0 * this.controlPoints[i].x + 4.0 * this.controlPoints[i+1].x - this.controlPoints[i+2].x) * t2 +
            (     -this.controlPoints[i-1].x + 3.0 * this.controlPoints[i].x - 3.0 * this.controlPoints[i+1].x + this.controlPoints[i+2].x) * t3 ),
      0.5*( (2.0 * this.controlPoints[i].y) +
            (     -this.controlPoints[i-1].y +       this.controlPoints[i+1].y) * t +
            (2.0 * this.controlPoints[i-1].y - 5.0 * this.controlPoints[i].y + 4.0 * this.controlPoints[i+1].y - this.controlPoints[i+2].y) * t2 +
            (     -this.controlPoints[i-1].y + 3.0 * this.controlPoints[i].y - 3.0 * this.controlPoints[i+1].y + this.controlPoints[i+2].y) * t3 ),
      0.5*( (2.0 * this.controlPoints[i].z) +
            (     -this.controlPoints[i-1].z +       this.controlPoints[i+1].z) * t +
            (2.0 * this.controlPoints[i-1].z - 5.0 * this.controlPoints[i].z + 4.0 * this.controlPoints[i+1].z - this.controlPoints[i+2].z) * t2 +
            (     -this.controlPoints[i-1].z + 3.0 * this.controlPoints[i].z - 3.0 * this.controlPoints[i+1].z + this.controlPoints[i+2].z) * t3 )
    )
  }

  private evalSegmentGradient(t: number, segment: number): Vec3 {
    const i = segment + 1
    const t2 = t * t

    return new Vec3(
      0.5*( (       -this.controlPoints[i-1].x +        this.controlPoints[i+1].x) +
            (  4.0 * this.controlPoints[i-1].x - 10.0 * this.controlPoints[i].x + 8.0 * this.controlPoints[i+1].x - 2.0 * this.controlPoints[i+2].x) * t +
            ( -3.0 * this.controlPoints[i-1].x +  9.0 * this.controlPoints[i].x - 9.0 * this.controlPoints[i+1].x + 3.0 * this.controlPoints[i+2].x) * t2 ),
      0.5*( (       -this.controlPoints[i-1].y +        this.controlPoints[i+1].y) +
            (  4.0 * this.controlPoints[i-1].y - 10.0 * this.controlPoints[i].y + 8.0 * this.controlPoints[i+1].y - 2.0 * this.controlPoints[i+2].y) * t +
            ( -3.0 * this.controlPoints[i-1].y +  9.0 * this.controlPoints[i].y - 9.0 * this.controlPoints[i+1].y + 3.0 * this.controlPoints[i+2].y) * t2 ),
      0.5*( (       -this.controlPoints[i-1].z +        this.controlPoints[i+1].z) +
            (  4.0 * this.controlPoints[i-1].z - 10.0 * this.controlPoints[i].z + 8.0 * this.controlPoints[i+1].z - 2.0 * this.controlPoints[i+2].z) * t +
            ( -3.0 * this.controlPoints[i-1].z +  9.0 * this.controlPoints[i].z - 9.0 * this.controlPoints[i+1].z + 3.0 * this.controlPoints[i+2].z) * t2 )
    ).normalize()
  }

  private evalSegmentGradientNumeric(t: number, eps: number, segment: number): Vec3 {
    const i = segment + 1
    if (t <= 0.0) {
      return this.controlPoints[i+1].sub(this.controlPoints[i-1]).normalize()
    } else if (t >= (1.0 - eps)) {
      return this.controlPoints[i+2].sub(this.controlPoints[i]).normalize()
    } else {
      const src = this.evalSegmentPosition(t, segment)
      const dest = this.evalSegmentPosition(t + eps, segment)
      return dest.sub(src).normalize()
    }
  }
}
