import { Keyboard } from './Keyboard'
import { Mouse } from './Mouse'

export class IO {
  private _keyboard: Keyboard
  private _mouse:  Mouse

  constructor(document: HTMLDocument) {
    this._keyboard = new Keyboard(document)
    this._mouse = new Mouse(document)
  }

  public swap() {
    this._keyboard.swap()
    this._mouse.swap()
  }

  public get keyboard(): Readonly<Omit<Keyboard, 'swap'>> { return this._keyboard }
  public get mouse(): Readonly<Omit<Mouse, 'swap'>> { return this._mouse }
}
