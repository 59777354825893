import { Vec3 } from './Vec3'

export const clamp = (val: number, low: number, high: number): number =>
  Math.min(Math.max(val, low), high)

export const between = (val: number, low: number, high: number): boolean =>
  ((val >= low) && (val <= high))

export const sphericalToCartesian3 = (r: number, theta: number, phi: number): Vec3 => {
  const sintheta = Math.sin(theta)
  return new Vec3(
    r * sintheta * Math.cos(phi),
    -r * Math.cos(theta),
    r * sintheta * Math.sin(phi),
  )
}

export const wrap = (val: number, min: number, max: number) => {
  const length = (max - min)
  const wrapped = (val - min) % length
  return wrapped < 0
    ? wrapped + length + min // wrap around right bound
    : wrapped + min
}

export const EPSILON = 0.000001
