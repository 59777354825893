const RATIO = 1.61803398875

interface IGolden {
  base(): number,
  smaller(): number,
  bigger(): number,
  next(): IGolden,
}

export const Golden = (base: number): Readonly<IGolden> => {
  const biggerPart = base / RATIO
  const smallerPart = base - biggerPart

  return {
    base: () => base,
    bigger: () => biggerPart,
    next: () => Golden(base * RATIO),
    smaller: () => smallerPart,
  }
}

export default Golden
