import { Init } from '../../types'
import {
  AttributesProps,
  BackgroundProps,
  BorderProps,
  FontProps,
  MarginProps,
  PaddingProps,
  PositionMode,
  PositionProps,
} from './Attributes'


export class BackgroundOverride implements Init<Partial<BackgroundProps>> {
  public color?: number
  public sprite?: number

  constructor(props?: Partial<BackgroundProps>) {
    this.init(props)
  }

  public init(props?: Partial<BackgroundProps>) {
    this.color = props?.color
    this.sprite = props?.sprite
  }
}


export class BorderOverride implements Init<Partial<BorderProps>> {
  public left?: number
  public top?: number
  public right?: number
  public bottom?: number
  public width?: number
  public color?: number

  constructor(props?: Partial<BorderProps>) {
    this.init(props)
  }

  public init(props?: Partial<BorderProps>) {
    this.left = props?.left
    this.top = props?.top
    this.right = props?.right
    this.bottom = props?.bottom
    this.width = props?.width
    this.color = props?.color
  }
}


export class FontOverride implements Init<Partial<FontProps>> {
  public atlas?: number
  public letterSpacing?: number
  public color?: number

  constructor(props?: Partial<FontProps>) {
    this.init(props)
  }

  public init(props?: Partial<FontProps>) {
    this.atlas = props?.atlas
    this.letterSpacing = props?.letterSpacing
    this.color = props?.color
  }
}


export class MarginOverride implements Init<Partial<MarginProps>> {
  public left?: number
  public top?: number
  public right?: number
  public bottom?: number

  constructor(props?: Partial<MarginProps>) {
    this.init(props)
  }

  public init(props?: Partial<MarginProps>) {
    this.left = props?.left
    this.top = props?.top
    this.right = props?.right
    this.bottom = props?.bottom
  }
}


export class PaddingOverride implements Init<Partial<PaddingProps>> {
  public left?: number
  public top?: number
  public right?: number
  public bottom?: number

  constructor(props?: Partial<PaddingProps>) {
    this.init(props)
  }

  public init(props?: Partial<PaddingProps>) {
    this.left = props?.left
    this.top = props?.top
    this.right = props?.right
    this.bottom = props?.bottom
  }
}


export class PositionOverride implements Init<Partial<PositionProps>> {
  public left?: number
  public leftOffset?: number
  public top?: number
  public topOffset?: number
  public right?: number
  public rightOffset?: number
  public bottom?: number
  public bottomOffset?: number
  public z?: number
  public mode?: PositionMode

  constructor(props?: Partial<PositionProps>) {
    this.init(props)
  }

  public init(props?: Partial<PositionProps>) {
    this.left = props?.left
    this.leftOffset = props?.leftOffset
    this.top = props?.top
    this.topOffset = props?.topOffset
    this.right = props?.right
    this.rightOffset = props?.rightOffset
    this.bottom = props?.bottom
    this.bottomOffset = props?.bottomOffset
    this.z = props?.z
    this.mode = props?.mode
  }
}


export class AttributesOverrides implements Init<Partial<AttributesProps>> {
  public position: PositionOverride = new PositionOverride()
  public padding: PaddingOverride = new PaddingOverride()
  public margin: MarginOverride = new MarginOverride()
  public background: BackgroundOverride = new BackgroundOverride()
  public border: BorderOverride = new BorderOverride()
  public font: FontOverride = new FontOverride()

  constructor(props?: Partial<AttributesProps>) {
    this.init(props)
  }

  public init(props?: Partial<AttributesProps>) {
    this.position.init(props?.position)
    this.padding.init(props?.padding)
    this.margin.init(props?.margin)
    this.background.init(props?.background)
    this.border.init(props?.border)
    this.font.init(props?.font)
  }
}
