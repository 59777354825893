import { Just, Maybe, Nothing } from '@cog/func'

// TODO: extract all type helpers to @cog/type
export declare type ClassType<T = any> = {
  new (): any;
}

type DataStore = Array<Map<number, Just<any>>>

export class Repository {
  private dataStore: DataStore = []
  private typeToIndex: Map<any, number> = new Map()
  private lastTypeIndex = 0
  private lastResourceId = 0

  public store<C>(type: ClassType<C>, record: C): number {
    if (!this.typeToIndex.has(type)) {
      this.typeToIndex.set(type, this.lastTypeIndex++)
      this.dataStore.push(new Map<number, Just<C>>())
    }

    const dataStore = this.dataStore[this.typeToIndex.get(type)!] as Map<number, Just<C>>

    const id = this.lastResourceId++
    dataStore.set(id, new Just(record))

    return id
  }

  public get<C>(type: ClassType<C>, id: number): Maybe<C> {
    const index = this.typeToIndex.get(type)
    if (index == null) {
      return new Nothing()
    }

    const dataStore = this.dataStore[index] as Map<number, Just<C>>
    const record = dataStore.get(id)

    if (record == null) {
      return new Nothing()
    }

    return record
  }
}
