import { CanvasContext } from '../../Context/CanvasContext'
import { Button } from '../Components/Button'
import { State } from '../Components/State'
import { Style } from '../Components/Style'

export const buttonSystem = <C extends CanvasContext>(ctx: C, args: [Button<C>, State<C>, Style], entity: number) => {
  const button = args[0]
  const state = args[1]
  const style = args[2]

  if (state.isHovered && ctx.io.mouse.left.isDown) {
    state.focus()
    state.activate()
  }

  if (state.isHovered && ctx.io.mouse.left.wasReleased) {
    state.focus()
    state.deactivate()
  }

  if (state.isHovered && state.isFocused && ctx.io.mouse.left.wasReleased) {
    state.blur()
    button.onClick(ctx, entity)
  }

  style.state = state.state
}
