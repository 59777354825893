import { Vec2 } from './Vec2'


export class Mat2 {
  public static Rotation(angle: number): Readonly<Mat2> {
    const a = angle / 180 * Math.PI
    const cosA = Math.cos(a)
    const sinA = Math.sin(a)
    return new Mat2(cosA, -sinA, sinA, cosA)
  }

  public static Reflection(angle: number): Readonly<Mat2> {
    const a = (2.0 * angle) / 180 * Math.PI
    const cosA = Math.cos(a)
    const sinA = Math.sin(a)
    return new Mat2(cosA, sinA, sinA, -cosA)
  }

  public static Scale(u: number, v: number): Readonly<Mat2> {
    return new Mat2(u, 0, 0, v)
  }

  public static Identity(): Readonly<Mat2> {
    return IDENTITY
  }

  public static Null(): Readonly<Mat2> {
    return NULL
  }

  public readonly m: [
    number, number,
    number, number
  ]

  constructor(a: number = 1, b: number = 0, c: number = 0, d: number = 1) {
    this.m = [a, b, c, d]
  }

  public transform(vec: Vec2): Readonly<Vec2> {
    return new Vec2(
      this.m[0] * vec.x + this.m[2] * vec.y,
      this.m[1] * vec.x + this.m[3] * vec.y,
    )
  }

  public transformBatch(vectors: Vec2[]): Readonly<Vec2[]> {
    return vectors.map((vector) => new Vec2(
      this.m[0] * vector.x + this.m[2] * vector.y,
      this.m[1] * vector.x + this.m[3] * vector.y,
    ))
  }

  public mul(other: Mat2): Readonly<Mat2> {
    return new Mat2(
      this.m[0] * other.m[0] + this.m[1] * other.m[2], this.m[0] * other.m[1] + this.m[1] * other.m[3],
      this.m[2] * other.m[0] + this.m[3] * other.m[2], this.m[2] * other.m[1] + this.m[3] * other.m[3],
    )
  }

  public transpose(): Readonly<Mat2> {
    return new Mat2(
      this.m[0], this.m[2],
      this.m[1], this.m[3],
    )
  }

  public inverse(): Readonly<Mat2> {
    const f = 1 / (this.m[0] * this.m[3] - this.m[1] * this.m[2])
    return new Mat2(this.m[3] * f, -this.m[1] * f, -this.m[2] * f, this.m[0] * f)
  }
}

const IDENTITY = Object.freeze(new Mat2())
const NULL = Object.freeze(new Mat2(0, 0, 0, 0))
