import { Mat4 } from './Mat4'
import { Vec3 } from './Vec3'
import { EPSILON } from './utils'

export class Quaternion {
  public static Identity(): Readonly<Quaternion> {
    return IDENTITY
  }

  public static Rotation(axis: Vec3, angle: number): Readonly<Quaternion> {
    const a = angle / 360 * Math.PI
    const sinhalf = Math.sin(a)
    const coshalf = Math.cos(a)
    const naxis = axis.normalize()
    return new Quaternion(sinhalf * naxis.x, sinhalf * naxis.y, sinhalf * naxis.z, coshalf)
  }

  public static LookAt(from: Vec3, to: Vec3): Readonly<Quaternion> {
    if (from.equals(to)) {
      return Quaternion.Identity()
    }
    const fwd = to.sub(from).normalize()

    const worldFwd = Vec3.Z().reverse()

    const dot = worldFwd.dot(fwd)

    if (Math.abs(dot - (-1.0)) < EPSILON) {
      return new Quaternion(0.0, 1.0, 0.0, Math.PI)
    }

    if (Math.abs(dot - 1.0) < EPSILON) {
      return Quaternion.Identity()
    }

    const angle = Math.acos(dot)
    const axis = worldFwd.cross(fwd).normalize()
    return Quaternion.Rotation(axis, angle)
  }

  readonly x: number
  readonly y: number
  readonly z: number
  readonly w: number

  constructor(x: number = 0, y: number = 0, z: number = 0, w: number = 1) {
    this.x = x
    this.y = y
    this.z = z
    this.w = w
  }

  public conjugate(): Readonly<Quaternion> {
    return new Quaternion(-this.x, -this.y, -this.z, this.w)
  }

  public equals(other: Quaternion): boolean {
    if (this === other) {
      return true
    } else {
      return this.x === other.x && this.y === other.y && this.z === other.z && this.w === other.w
    }
  }

  public mul(other: Quaternion): Readonly<Quaternion> {
    return new Quaternion(
      this.w * other.x + this.x * other.w + this.y * other.z - this.z * other.y,
      this.w * other.y + this.y * other.w + this.z * other.x - this.x * other.z,
      this.w * other.z + this.z * other.w + this.x * other.y - this.y * other.x,
      this.w * other.w - this.x * other.x - this.y * other.y - this.z * other.z,
    )
  }

  public transform(vec: Vec3): Readonly<Vec3> {
    const qc = this.conjugate()
    const qv = new Quaternion(vec.x, vec.y, vec.z, 0)
    const qp = this.mul(qv).mul(qc)
    return new Vec3(qp.x, qp.y, qp.z)
  }

  public transformBatch(vectors: Vec3[]): Readonly<Vec3[]> {
    const qc = this.conjugate()
    return vectors.map((vec) => {
      const qv = new Quaternion(vec.x, vec.y, vec.z, 0)
      const qp = this.mul(qv).mul(qc)
      return new Vec3(qp.x, qp.y, qp.z)
    })
  }

  public normalize(): Readonly<Quaternion> {
    const l = 1 / Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z + this.w * this.w)
    return new Quaternion(this.x * l, this.y * l, this.z * l, this.w * l)
  }

  public lerp(other: Quaternion, t: number): Readonly<Quaternion> {
    if (t <= 0) {
      return this
    } else if (t >= 1.0) {
      return other
    } else {
      return new Quaternion(
        this.x + (other.x - this.x) * t,
        this.y + (other.y - this.y) * t,
        this.z + (other.z - this.z) * t,
        this.w + (other.w - this.w) * t,
      )
    }
  }

  public toMat4(): Readonly<Mat4> {
    const q = this.normalize()
    return new Mat4(
      1 - 2 * q.y * q.y - 2 * q.z * q.z, 2 * q.x * q.y - 2 * q.z * q.w,     2 * q.x * q.z + 2 * q.y * q.w,     0,
      2 * q.x * q.y + 2 * q.z * q.w,     1 - 2 * q.x * q.x - 2 * q.z * q.z, 2 * q.y * q.z - 2 * q.x * q.w,     0,
      2 * q.x * q.z - 2 * q.y * q.w,     2 * q.y * q.z + 2 * q.x * q.w,     1 - 2 * q.x * q.x - 2 * q.y * q.y, 0,
      0,                                 0,                                 0,                                 1,
    )
  }
}

const IDENTITY = new Quaternion(0, 0, 0, 1)
