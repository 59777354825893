import { Context } from '@cog/ecs'

import { Timer } from '../Components/Timer'


export const tickTimers = <C extends Context>(ctx: C, args: [Timer<C>]) => {
  const timer = args[0]
  timer.current = Math.max(0.0, args[0].current - ctx.timer.delta)
  if (timer.current <= 0.0) {
    timer.callback(ctx, timer)
  }
}
