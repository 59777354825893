export type ClassType<T = any> = { new(): any }
export type Entity = number
export type QuerySignature = Array<ClassType<any>>
export type Signature = Set<ClassType<any>>
export type ClassArgs =
  | [ClassType]
  | ClassType[]

export type ComponentArgsFromSignature<T> = {
  [P in keyof T]: T[P] extends ClassType<T[P]>
    ? InstanceType<T[P]>
    : never
}

export type ReadonlyComponentArgsFromSignature<T> = {
  [P in keyof T]: T[P] extends ClassType<T[P]>
    ? Readonly<InstanceType<T[P]>>
    : never
}

export const shallowCloneInto = <T extends object>(source: T, target: T): void => {
  let prop

  // FIXME: components _MUST_ implement Init<P> type/interface in the future
  if ((target as any).init != null) {
    (target as any).init(source)
  } else {
    for (prop of Object.getOwnPropertyNames(source)) {
      target[prop] = source[prop]
    }
  }
}

export class Pair<S, T> {
  constructor(
    public fst: S,
    public snd: T,
  ) {}
}

export interface Init<P extends {} = any> {
  init: (props: P) => this
}
