import { ClassArgs, ComponentArgsFromSignature, Entity } from './internal'

export class Reference<T extends ClassArgs> {
  static Null = new Reference<any>(-1, [])

  constructor(
    public readonly entity: Entity,
    public readonly components: ComponentArgsFromSignature<T>,
  ) {}
}
