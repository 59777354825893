export class Vec2 {

  public static Null(): Readonly<Vec2> {
    return Null
  }

  public static X(): Readonly<Vec2> {
    return X
  }

  public static Y(): Readonly<Vec2> {
    return Y
  }

  public static Left(): Readonly<Vec2> {
    return Left
  }

  public static Right(): Readonly<Vec2> {
    return Right
  }

  public static Up(): Readonly<Vec2> {
    return Up
  }

  public static Down(): Readonly<Vec2> {
    return Down
  }

  public readonly x: number
  public readonly y: number

  constructor(x: number = 0, y: number = 0) {
    this.x = x
    this.y = y
  }

  public equals(other: Vec2): boolean {
    return this === other || (this.x === other.x && this.y === other.y)
  }

  public add(other: Vec2): Readonly<Vec2> {
    return new Vec2(this.x + other.x, this.y + other.y)
  }

  public sub(other: Vec2): Readonly<Vec2> {
    return new Vec2(this.x - other.x, this.y - other.y)
  }

  public scale(f: number): Readonly<Vec2> {
    return new Vec2(this.x * f, this.y * f)
  }

  public addScaled(other: Vec2, scale: number): Readonly<Vec2> {
    return new Vec2(this.x + other.x * scale, this.y + other.y * scale)
  }

  public reverse(): Readonly<Vec2> {
    return new Vec2(-this.x, -this.y)
  }

  public dot(other: Vec2): number {
    return this.x * other.x + this.y * other.y
  }

  public cross(): Readonly<Vec2> {
    return new Vec2(this.y, -this.x)
  }

  public lenSq(): number {
    return this.x ** 2 + this.y ** 2
  }

  public len(): number {
    return Math.sqrt(this.x ** 2 + this.y ** 2)
  }

  public normalize(): Readonly<Vec2> {
    const l = 1.0 / this.len()
    return new Vec2(this.x * l, this.y * l)
  }

  public distSq(other: Vec2): number {
    return (other.x - this.x) ** 2 + (other.y - this.y) ** 2
  }

  public dist(other: Vec2): number {
    return Math.sqrt((other.x - this.x) ** 2 + (other.y - this.y) ** 2)
  }

  public angle(): number {
    return Math.acos(this.x / this.len())
  }

  public angleTo(other: Vec2): number {
    return Math.acos(this.dot(other) / (this.len() * other.len()))
  }

  public lerp(other: Vec2, t: number): Readonly<Vec2> {
    if (t <= 0) {
      return this
    } else if (t >= 1.0) {
      return other
    } else {
      return new Vec2(this.x + (other.x - this.x) * t, this.y + (other.y - this.y) * t)
    }
  }

  public nlerp(other: Vec2, t: number): Readonly<Vec2> {
    return this.lerp(other, t).normalize()
  }

  public slerp(other: Vec2, t: number): Readonly<Vec2> {
    if (t <= 0) {
      return new Vec2(this.x, this.y)
    } else if (t >= 1.0) {
      return new Vec2(other.x, other.y)
    } else {
      const omega = this.angleTo(other)
      const sinOmega = Math.sin(omega)
      const tOmega = t * omega
      const fSrc = Math.sin(omega - tOmega) / sinOmega
      const fDst = Math.sin(tOmega) / sinOmega
      const pSrc = this.scale(fSrc)
      const pDst = other.scale(fDst)
      return pSrc.add(pDst)
    }
  }

  public project(other: Vec2): Readonly<Vec2> {
    return other.scale(this.dot(other) / other.lenSq())
  }

}

const Null = Object.freeze(new Vec2())
const X = Object.freeze(new Vec2(1, 0))
const Y = Object.freeze(new Vec2(0, 1))
const Right = X
const Left = Object.freeze(new Vec2(-1, 0))
const Up = Y
const Down = Object.freeze(new Vec2(0, -1))