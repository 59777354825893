const BLANK_CANVAS = document.createElement('canvas')
BLANK_CANVAS.width = 1
BLANK_CANVAS.height = 1

const BLANK_IMAGE_DATA = BLANK_CANVAS.getContext('2d')?.getImageData(0, 0, 1, 1) ?? new ImageData(1, 1)

export {
  BLANK_CANVAS,
  BLANK_IMAGE_DATA,
}
