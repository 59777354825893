import { Collider } from '../CollisionDetection/Collider'
import { Transform } from '../Common/Transform'

type Entity = number
type Cell = Array<[Entity, Transform, Collider]>
type Grid = Map<number, Map<number, Cell>>
type EntityToGridIndex = Map<Entity, Array<[number, number, number]>>

export class SparseSpatialGrid {
  private _layers: Grid[] = [new Map()]
  private _index: EntityToGridIndex = new Map()

  constructor(
    public readonly cellSizeX: number = 32,
    public readonly cellSizeY: number = 32,
  ) {}

  public insert(entity: Entity, transform: Transform, collider: Collider, layer: number) {
    const minX = ((transform.position.x + collider.aabb.minX) / this.cellSizeX)|0
    const maxX = ((transform.position.x + collider.aabb.maxX) / this.cellSizeX)|0
    const minY = ((transform.position.y + collider.aabb.minY) / this.cellSizeY)|0
    const maxY = ((transform.position.y + collider.aabb.maxY) / this.cellSizeY)|0

    this.remove(entity)
    for (let x = minX; x <= maxX; ++x) {
      for (let y = minY; y <= maxY; ++y) {
        const cell = this.getCell(layer, x, y)
        cell.push([entity, transform, collider])
        this.indexEntity(entity, layer, x, y)
      }
    }
  }

  public remove(entity: Entity) {
    const index = this._index.get(entity)
    const toDelete: Array<[number, number, number, number]> = []
    if (index != null) {
      for (const it of index) {
        this.removeEntityFromCell(entity, it[0], it[1], it[2])
        toDelete.push([entity, it[0], it[1], it[2]])
      }
      while (toDelete.length) {
        const e = toDelete.pop()!
        this.unlistEntity(e[0], e[1], e[2], e[3])
      }
    }
  }

  public update(entity: Entity, transform: Transform, collider: Collider, layer: number) {
    // TODO: will do for now, should only update, not do a reinsert relying
    // the fact that insert takes care not to produce duplicates
    this.insert(entity, transform, collider, layer)
  }

  public potentialCollisions(
    layer: number,
    mutation: (
      fst: { entity: number, transform: Transform, collider: Collider },
      snd: { entity: number, transform: Transform, collider: Collider },
      distSq: number,
    ) => void,
  ) {
    if (layer < this._layers.length) {
      const fst: { entity: number, transform: Transform, collider: Collider } = {} as any
      const snd: { entity: number, transform: Transform, collider: Collider } = {} as any
      let fstMoved = false
      let sndMoved = false

      const grid = this._layers[layer]

      for (const it of grid.entries()) {
        const buckets = it[1]
        for (const jt of buckets.entries()) {
          const entities = jt[1]
          for (let i = 0; i < entities.length - 1; ++i) {
            const fstEntity = entities[i]
            fst.entity = fstEntity[0]
            fst.transform = fstEntity[1]
            fst.collider = fstEntity[2]
            fstMoved = fst.transform.moved

            for (let j = i + 1; j < entities.length; ++j) {
              const sndEntity = entities[j]
              snd.entity = sndEntity[0]
              snd.transform = sndEntity[1]
              snd.collider = sndEntity[2]
              sndMoved = snd.transform.moved

              if (fstMoved || sndMoved) {
                // TODO: actually test axis aligned bounding boxes instead of circles
                const distSq = fst.transform.position.distSq(snd.transform.position)
                const rad = fst.collider.radius + snd.collider.radius
                if (distSq <= rad * rad) {
                  mutation(fst, snd, distSq)
                }
              }
            }
          }
        }
      }
    }
  }

  public cells(layer: number, mutation: (layer: number, x: number, y: number) => void) {
    if (layer < this._layers.length) {
      for (const it of this._layers[layer].entries()) {
        const buckets = it[1]
        for (const jt of buckets.entries()) {
          if (jt[1].length > 0) {
            mutation(layer, it[0], jt[0])
          }
        }
      }
    }
  }

  private getCell(layer: number, x: number, y: number) {
    if (layer === this._layers.length) {
      this._layers.push(new Map())
    }
    const grid = this._layers[layer]!

    let bucket = grid.get(x)
    if (bucket == null) {
      bucket = new Map()
      grid.set(x, bucket)
    }

    let cell = bucket.get(y)
    if (cell == null) {
      cell = []
      bucket.set(y, cell)
    }

    return cell
  }

  private indexEntity(entity: Entity, layer: number, x: number, y: number) {
    let index = this._index.get(entity)
    if (index == null) {
      index = []
      this._index.set(entity, index)
    }
    index.push([layer, x, y])
  }

  private unlistEntity(entity: Entity, layer: number, x: number, y: number) {
    const index = this._index.get(entity)
    if (index != null) {
      const entityIndex = index.findIndex((it) => layer === it[0] && it[1] === x && it[2] === y)
      if (entityIndex !== -1) {
        if (index.length > 0) {
          const e = index.pop()!
          if (index.length !== entityIndex) {
            index[entityIndex] = e
          }
        }
      }
    }
  }

  private removeEntityFromCell(entity: Entity, layer: number, x: number, y: number) {
    const grid = this._layers[layer]
    if (grid != null) {
      const bucket = grid.get(x)
      if (bucket != null) {
        const cell = bucket.get(y)
        if (cell != null) {
          const index = cell.findIndex((it) => it[0] === entity)
          if (index !== -1) {
            if (cell.length > 0) {
              const e = cell.pop()!
              if (cell.length !== index) {
                cell[index] = e
              }
            }
          }
        }
      }
    }
  }
}
