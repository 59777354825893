class KeyState {
  constructor(
    public isDown: boolean = false,
    public wasPressed: boolean = false,
    public wasReleased: boolean = false,
  ) {}

  public get isUp() { return !this.isDown }
}

const NULL_VIEW = new KeyState()

export class Keyboard {
  private view: KeyState = new KeyState()
  private activeBuffer: 1 | 0 = 0
  private keyboardState: Map<string, [KeyState, KeyState]> = new Map()

  constructor(private readonly document: HTMLDocument) {
    this.subscribe()
  }

  public swap() {
    const prevIndex = ((this.activeBuffer + 1) % 2) as (0 | 1)
    let next: KeyState
    let current: KeyState

    for (const key of this.keyboardState.values()) {
      next = key[prevIndex]
      current = key[this.activeBuffer]

      next.isDown = current.isDown
      next.wasPressed = false
      next.wasReleased = false
    }

    this.activeBuffer = prevIndex
  }

  public key(key: string): Readonly<KeyState> {
    const state = this.keyboardState.get(key)
    if (state == null) {
      return NULL_VIEW
    } else {
      const prevIndex = ((this.activeBuffer + 1) % 2) as (0 | 1)
      const previous = state[prevIndex]
      const current = state[this.activeBuffer]

      this.view.isDown = current.isDown
      this.view.wasPressed = current.isDown && previous.isUp || current.wasPressed
      this.view.wasReleased = current.isUp && previous.isDown || current.wasReleased
      return this.view
    }
  }

  private subscribe() {
    this.document.addEventListener('keydown', this.onKeyDown, true)
    this.document.addEventListener('keyup', this.onKeyUp, true)
  }

  // TODO: handle cleanup
  // private unsubscribe() {
  //   this.document.removeEventListener('keydown', this.onKeyDown)
  //   this.document.removeEventListener('keyup', this.onKeyUp)
  // }

  private onKeyDown = (event: KeyboardEvent): void => {
    event.preventDefault()
    // since key-repeat causes the keydown to be called multiple times we need
    // to back-off until repeat is no longer set
    if (event.repeat) {
      return
    }

    const key = event.key != null
      ? event.key
      : String.fromCharCode(event.keyCode)

    let keyState = this.keyboardState.get(key)
    if (keyState == null) {
      keyState = [new KeyState(true, true, false), new KeyState(true, true, false)]
      this.keyboardState.set(key, keyState)
    } else {
      keyState[this.activeBuffer].isDown = true
      keyState[this.activeBuffer].wasPressed = true
    }
  }

  private onKeyUp = (event: KeyboardEvent): void => {
    event.preventDefault()

    const key = event.key != null
      ? event.key
      : String.fromCharCode(event.keyCode)

    let keyState = this.keyboardState.get(key)
    if (keyState == null) {
      keyState = [new KeyState(false, false, true), new KeyState(false, false, true)]
      this.keyboardState.set(key, keyState)
    } else {
      keyState[this.activeBuffer].isDown = false
      keyState[this.activeBuffer].wasReleased = true
    }
  }
}
