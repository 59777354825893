import { Context, World } from '@cog/ecs'
import { ClassArgs } from '@cog/ecs/dist/internal'

import { Transition, TransitionProps, TransitionRepeatMode } from './Components/Transition'
import { cleanupDeadTransitions } from './Systems/cleanupDeadTransitions'
import { tickTransition } from './Systems/tickTransition'


const TRANSITION_TICK_PRIORITY = -100001
const TRANSITION_CLEANUP_PRIORITY = 100001

const noop = () => {}

export class Transitions {
  static Transition = <C extends Context, T extends ClassArgs>(
    world: World<C>,
    props: TransitionProps<T>
  ) =>
    world.entity([Transition], (args) => {
      const transition = args[0]
      transition.reset()
      transition.duration = props.duration
      transition.delayStart = props.delayStart ?? 0.0
      transition.delayEnd = props.delayEnd ?? 0.0
      transition.type = props.type ?? TransitionRepeatMode.NoRepeat
      transition.repeats = props.repeats ?? 0
      transition.reference = props.reference
      transition.mutation = props.mutation as any
      transition.onStart = props.onStart ?? noop as any
      transition.onEnd = props.onEnd ?? noop as any

      if (transition.onStart != null) {
        transition.onStart(transition.reference)
      }
    })

  static Stop<C extends Context>(world: World<C>, entity: number) {
    world.deleteWhere([Transition], (transition) => {
      const tr = transition[0]
      if (tr.reference.entity === entity) {
        tr.onEnd(tr.reference)
        return true
      }
      return false
    })
  }

  static Clear<C extends Context>(world: World<C>) {
    world.deleteWhere([Transition], () => true)
  }

  static Create<C extends Context>(world: World<C>) {
    world.system.on.before([Transition], tickTransition, TRANSITION_TICK_PRIORITY)
    world.system.on.after([Transition], cleanupDeadTransitions, TRANSITION_CLEANUP_PRIORITY)
  }

  static Enable<C extends Context>(world: World<C>) {
    world.system.on.before([Transition], tickTransition, TRANSITION_TICK_PRIORITY)
  }

  static Disable<C extends Context>(world: World<C>) {
    world.system.delete(tickTransition)
  }

  static Delete<C extends Context>(world: World<C>) {
    Transitions.Clear(world)

    world.system.delete(tickTransition)
    world.system.delete(cleanupDeadTransitions)
  }
}
