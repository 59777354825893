import { Maybe } from '@cog/func'

import { Repository } from '../Repository/Repository'
import { NinePatchSprite } from './Data/NinePatchSprite'
import { Sprite } from './Data/Sprite'
import { SpriteAtlas } from './Data/SpriteAtlas'

type GetApi = {
  readonly FontSprite: (id: number) => Maybe<SpriteAtlas>,
  readonly NinePatchSprite: (id: number) => Maybe<NinePatchSprite>,
  readonly Sprite: (id: number) => Maybe<Sprite>,
}

type StoreApi = {
  readonly FontSprite: (sprite: SpriteAtlas) => number,
  readonly NinePatchSprite: (sprite: NinePatchSprite) => number,
  readonly Sprite: (sprite: Sprite) => number,
}

export class GuiRepository {
  static readonly Get: GetApi = {
    FontSprite: (id) => GuiRepository.repository.get(SpriteAtlas, id),
    NinePatchSprite: (id) => GuiRepository.repository.get(NinePatchSprite, id),
    Sprite: (id) => GuiRepository.repository.get(Sprite, id),
  }

  static readonly Store: StoreApi = {
    FontSprite: (sprite): number => GuiRepository.repository.store(SpriteAtlas, sprite),
    NinePatchSprite: (sprite): number => GuiRepository.repository.store(NinePatchSprite, sprite),
    Sprite: (sprite): number => GuiRepository.repository.store(Sprite, sprite),
  }

  private static repository = new Repository()
}
