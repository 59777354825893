import { Context, World } from '@cog/ecs'

import { Timer } from './Components/Timer'
import { cleanupDeadTimers } from './Systems/cleanupDeadTimers'
import { tickTimers } from './Systems/tickTimers'


const TIMER_TICK_PRIORITY = -100000
const TIMER_CLEANUP_PRIORITY = 100000

export class Timers {
  static Timer = <C extends Context>(
    world: World<C>,
    duration: number,
    callback: (ctx: C, timer: Timer<C>) => void,
  ) =>
    world.entity([Timer], (args) => {
      const timer = args[0] as unknown as Timer<C>
      timer.duration = duration
      timer.current = duration
      timer.callback = callback
    })

  static Clear <C extends Context>(world: World<C>) {
    world.deleteWhere([Timer], () => true)
  }

  static Create<C extends Context>(world: World<C>) {
    world.system.on.before([Timer], tickTimers, TIMER_TICK_PRIORITY)
    world.task.on.after(cleanupDeadTimers, TIMER_CLEANUP_PRIORITY)
  }

  static Enable<C extends Context>(world: World<C>) {
    world.system.on.before([Timer], tickTimers, TIMER_TICK_PRIORITY)
  }

  static Disable<C extends Context>(world: World<C>) {
    world.system.delete(tickTimers)
  }

  static Delete<C extends Context>(world: World<C>) {
    Timers.Clear(world)

    world.system.delete(tickTimers)
    world.task.delete(cleanupDeadTimers)
  }
}
