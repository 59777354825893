import { World } from '@cog/ecs'

import { CanvasContext } from '../../Context/CanvasContext'
import { Label } from '../Components/Label'
import { State } from '../Components/State'
import { Style } from '../Components/Style'


export const makePane = <C extends CanvasContext>(
  world: World<C>,
  props: Partial<{ style: Parameters<typeof Style.prototype.init>[0] }>,
) =>
  world.entity([Style, State, Label], (args) => {
    const style = args[0]

    style.init(props.style)
  })
