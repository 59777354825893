import fastMemoize from 'fast-memoize'

const cache: any = {
  create: <T>() => {
    let store: {[key: string]: T} = {}
    return {
      get: (key: string): T => store[key],
      has: (key: string) => key in store,
      set: (key: string, value: T) => {
        store = {} // ensure we only have last invocation cached
        store[key] = value
      },
    }
  },
}

export const memoizeOnce = <F extends (...args: any[]) => any>(fn: F) => fastMemoize(fn, { cache } as any)
export const memoize = <F extends (...args: any[]) => any>(fn: F) => fastMemoize(fn)
