import { Vec2 } from '@cog/math'

export class Transform {
  private _position: Vec2
  private _moved: boolean
  constructor(
    position: Vec2 = Vec2.Null(),
    public direction: Vec2 = Vec2.X(),
  ) {
    this._position = position
    this._moved = true
  }

  public get position() { return this._position }
  public set position (val) {
    this._moved = !this._position.equals(val)
    this._position = val
  }

  public get moved() { return this._moved }
}
