// NOTE: taken from https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
// NOTE: it is actually a murmur hash 3 mixing function

export const SeedGenerator = (str: string) => {
  let h = 1779033703 ^ str.length
  for (let i = 0; i < str.length; i++) {
    h = Math.imul(h ^ str.charCodeAt(i), 3432918353)
  }

  h = h << 13 | h >>> 19

  return () => {
    h = Math.imul(h ^ h >>> 16, 2246822507)
    h = Math.imul(h ^ h >>> 13, 3266489909)
    return (h ^= h >>> 16) >>> 0
  }
}