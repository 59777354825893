import { Vec2 } from '@cog/math'

export class Transform {
  constructor(
    public position = Vec2.Null(),
  ) {}
}

export class Physics {
  constructor(
    public velocity = Vec2.Null(),
    public speed = 0.0,
  ) {}
}

export class Material {
  constructor(
    public r = 255,
    public g = 255,
    public b = 255,
  ) {}
}
