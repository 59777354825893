import { Context } from './Context'
import {
  ContextlessSystemFunction,
  Systems,
  SystemFunction,
  SystemFunctionPredicate,
  Trigger,
} from './Systems'
import {
  shallowCloneInto,
  ClassArgs,
  ClassType,
  ComponentArgsFromSignature,
  Entity,
  QuerySignature,
  ReadonlyComponentArgsFromSignature,
  Signature,
} from './internal'

export class Archetype<C extends Context> {
  private signature: Signature
  private signatureArray: QuerySignature
  private ctorMap: Map<ClassType<any>, ClassType<any>> = new Map()
  private entities: Map<ClassType<any>, any[]> = new Map()
  private entityIdToEntityIndex: Map<Entity, number> = new Map()
  private entityIndexToEntityId: Map<number, Entity> = new Map()
  private size: number = 0
  private poolSize: number = 0
  private argArrayCache: any[]

  constructor(
    signature: QuerySignature,
    private generateId: () => Entity,
    initialSize: number,
  ) {
    for (let i = 0; i < signature.length; ++i) {
      const ctor = signature[i]
      const table = new Array(initialSize)
      for (let j = 0; j < table.length; ++j) {
        table[j] = new ctor()
      }
      this.entities.set(ctor, table)
      this.ctorMap.set(ctor, ctor)
    }
    this.signature = new Set(signature)
    this.signatureArray = signature.map((ctor) => ctor)
    this.poolSize = initialSize
    this.argArrayCache = new Array(signature.length)
  }

  public create(): Entity {
    const id = this.generateId()
    const index = this.size
    if (index === this.poolSize) {
      this.resize()
    }
    this.entityIdToEntityIndex.set(id, index)
    this.entityIndexToEntityId.set(index, id)
    this.size++
    return id as Entity
  }

  public createAndInit(init: ContextlessSystemFunction<any>): Entity {
    const id = this.create()
    const index = this.entityIdToEntityIndex.get(id)
    const args = new Array(this.signature.size)
    let i = 0
    this.signature.forEach((ctor) => {
      args[i] = this.entities.get(ctor)![index!]
      ++i
    })
    init(args, id)
    return id
  }

  public receiveFromOtherArchetype(
    entity: Entity,
    archetype: Archetype<C>,
    Component: ClassType<any>,
    init: (component: InstanceType<any>, entity: Entity) => void,
  ) {
    const index = this.size
    this.entityIdToEntityIndex.set(entity, index)
    this.entityIndexToEntityId.set(index, entity)
    this.size++

    const query = archetype.getSignature()
    const signature = [...query, Component]
    archetype.mutate(entity, query, (components) => {
      this.mutate(entity, signature, (ownedComponents) => {
        for (let i = 0; i < query.length; ++i) {
          const component = components[i]
          const ownedComponent = ownedComponents[i]
          shallowCloneInto(component, ownedComponent)
        }
        init(this.entities.get(Component)![index], entity)
      })
    })

    archetype.delete(entity, null)
  }

  public delete(id: Entity, freeEntityIdPool: number[] | null) {
    const slot = this.entityIdToEntityIndex.get(id)!
    if (slot != null) {
      if (freeEntityIdPool != null) {
        freeEntityIdPool.push(id)
      }
      this.entityIdToEntityIndex.delete(id)
      this.size--
      const entityId = this.entityIndexToEntityId.get(this.size)!
      this.entityIndexToEntityId.delete(this.size)

      // entity being deleted is neither first nor last, we need to swap the
      // last one into the empty slot to ensure no indices are skipped when
      // executing systems over the archetype
      if (slot < this.size) {
        this.entityIndexToEntityId.set(slot, entityId)
        this.entityIdToEntityIndex.set(entityId, slot)
        this.signature.forEach((ctor) => {
          const table = this.entities.get(ctor)!
          const destination = table[slot]
          const source = table[this.size]
          shallowCloneInto(source, destination)
        })
      }
    } else {
      throw new Error(`FIXME: This should not happen: deleting entity ${id} which has no associated slot in this archetype!`)
    }
  }

  public deleteWhere<T extends ClassArgs>(systems: Systems<C>, query: T, predicate: (args: ReadonlyComponentArgsFromSignature<T>) => boolean, entityIndex: Map<number, number>, freeEntityIdPool: number[]) {
    if (this.satisfies(query)) {
      const toDelete: Entity[] = []
      const index = []
      const l = this.size
      for (let i = 0; i < query.length; ++i) {
        index.push(this.entities.get(query[i]))
      }
      for (let i = 0; i < l; ++i) {
        for (let j = 0; j < query.length; ++j) {
          this.argArrayCache[j] = index[j]![i]
        }
        if (predicate(this.argArrayCache as unknown as ReadonlyComponentArgsFromSignature<T>)) {
          toDelete.push(this.entityIndexToEntityId.get(i)!)
        }
      }
      for (let i = 0; i < toDelete.length; ++i) {
        const id = toDelete[i]
        systems.on(Trigger.Delete, id, this)
        this.delete(id, freeEntityIdPool)
        entityIndex.delete(id)
      }
    }
  }

  public run<T extends ClassArgs>(context: C, query: T, mutation: SystemFunction<C, T>) {
    if (this.satisfies(query)) {
      const index = []
      for (let i = 0; i < query.length; ++i) {
        index.push(this.entities.get(query[i]))
      }
      const l = this.size
      for (let i = 0; i < l; ++i) {
        for (let j = 0; j < query.length; ++j) {
          this.argArrayCache[j] = index[j]![i]
        }
        mutation(context, this.argArrayCache as unknown as ComponentArgsFromSignature<T>, this.entityIndexToEntityId.get(i)!)
      }
    }
  }

  public runWhere<T extends ClassArgs>(
    context: C,
    query: T,
    predicate: SystemFunctionPredicate<C, T>,
    mutation: SystemFunction<C, T>,
  ) {
    if (this.satisfies(query)) {
      let entity: Entity
      const index = []
      for (let i = 0; i < query.length; ++i) {
        index.push(this.entities.get(query[i]))
      }
      const l = this.size
      for (let i = 0; i < l; ++i) {
        for (let j = 0; j < query.length; ++j) {
          this.argArrayCache[j] = index[j]![i]
        }
        entity = this.entityIndexToEntityId.get(i)!
        if (predicate(context, this.argArrayCache as unknown as ComponentArgsFromSignature<T>, entity)) {
          mutation(context, this.argArrayCache as unknown as ComponentArgsFromSignature<T>, entity)
        }
      }
    }
  }

  public runForEntity<T extends ClassArgs>(context: C, query: T, entity: Entity, mutation: SystemFunction<C, T>) {
    const found = this.entityIdToEntityIndex.get(entity)
    if (this.satisfies(query) && found != null) {
      const index = []
      for (let i = 0; i < query.length; ++i) {
        index.push(this.entities.get(query[i]))
      }
      for (let j = 0; j < query.length; ++j) {
        this.argArrayCache[j] = index[j]![found]
      }
      mutation(context, this.argArrayCache as unknown as ComponentArgsFromSignature<T>, found as Entity)
    }
  }

  public mutate(entity: Entity, query: QuerySignature, mutation: ContextlessSystemFunction<any>) {
    if (this.entityIdToEntityIndex.has(entity)) {
      const index = this.entityIdToEntityIndex.get(entity)!
      const args = new Array(query.length)
      for (let j = 0; j < query.length; ++j) {
        args[j] = this.entities.get(query[j])![index]
      }
      mutation(args, entity)
    }
  }

  public count<T extends ClassArgs>(query: T) {
    return this.satisfies(query) ? this.entityIdToEntityIndex.size : 0
  }

  public countWhere<T extends ClassArgs>(context: C, query: T, predicate: SystemFunctionPredicate<C, T>) {
    if (this.satisfies(query)) {
      const index = []
      for (let i = 0; i < query.length; ++i) {
        index.push(this.entities.get(query[i]))
      }
      const l = this.size
      let count = 0
      for (let i = 0; i < l; ++i) {
        for (let j = 0; j < query.length; ++j) {
          this.argArrayCache[j] = index[j]![i]
        }
        if (predicate(context, this.argArrayCache as unknown as ComponentArgsFromSignature<T>, this.entityIndexToEntityId.get(i)!)) {
          count++
        }
      }
      return count
    } else {
      return 0
    }
  }

  public has<C extends ClassType<any>>(component: C) {
    return this.signature.has(component)
  }

  public owns(entity: Entity) {
    return this.entityIdToEntityIndex.has(entity)
  }

  public getSignature() {
    return this.signatureArray
  }

  public getEntities() {
    return this.entities
  }

  public getSize() {
    return this.size
  }

  public satisfies(signature: QuerySignature) {
    for (let i = 0; i < signature.length; ++i) {
      if (!this.signature.has(signature[i])) {
        return false
      }
    }
    return true
  }

  private resize() {
    const batchSize = Math.max(this.poolSize >> 1, 1)
    this.poolSize = this.poolSize + batchSize

    for (let i = 0; i < this.signatureArray.length; ++i) {
      const ctor = this.signatureArray[i]
      const table = this.entities.get(ctor)!
      for (let j = 0; j < batchSize; ++j) {
        table.push(new ctor())
      }
    }
  }
}
