import { Init } from '../../types'
import { isActive, isDisabled, isFocused, isHovered, ElementState } from '../State'
import { Attributes, AttributesProps } from './Attributes'
import { AttributesOverrides } from './AttributesOverrides'


type StyleProps = {
  idle: Partial<AttributesProps>,
  disabled: Partial<AttributesProps>,
  hovered: Partial<AttributesProps>,
  focused: Partial<AttributesProps>,
  active: Partial<AttributesProps>,
}
export class Style implements Init<StyleProps> {
  public idle!: Attributes
  public disabled!: AttributesOverrides
  public hovered!: AttributesOverrides
  public focused!: AttributesOverrides
  public active!: AttributesOverrides
  public state: ElementState = ElementState.Idle

  constructor(props?: Partial<StyleProps>) {
    this.idle = new Attributes()
    this.disabled = new AttributesOverrides()
    this.hovered = new AttributesOverrides()
    this.focused = new AttributesOverrides()
    this.active = new AttributesOverrides()
    this.init(props)
  }

  public init(props?: Partial<StyleProps>) {
    this.idle.init(props?.idle)
    this.disabled.init(props?.disabled)
    this.hovered.init(props?.hovered)
    this.focused.init(props?.focused)
    this.active.init(props?.active)
  }

  public get backgroundColor() { return this.getOverrides().background?.color ?? this.idle.background.color }
  public get backgroundSprite() { return this.getOverrides().background?.sprite ?? this.idle.background.sprite }

  public get borderLeft() { return this.getOverrides().border?.left ?? this.idle.border.left }
  public get borderTop() { return this.getOverrides().border?.top ?? this.idle.border.top }
  public get borderRight() { return this.getOverrides().border?.right ?? this.idle.border.right }
  public get borderBottom() { return this.getOverrides().border?.bottom ?? this.idle.border.bottom }
  public get borderWidth() { return this.getOverrides().border?.width ?? this.idle.border.width }
  public get borderColor() { return this.getOverrides().border?.color ?? this.idle.border.color }

  public get fontAtlas() { return this.getOverrides().font?.atlas ?? this.idle.font.atlas }
  public get fontLetterSpacing() { return this.getOverrides().font?.letterSpacing ?? this.idle.font.letterSpacing }
  public get fontColor() { return this.getOverrides().font?.color ?? this.idle.font.color }

  public get marginLeft() { return this.getOverrides().margin?.left ?? this.idle.margin.left }
  public get marginTop() { return this.getOverrides().margin?.top ?? this.idle.margin.top }
  public get marginRight() { return this.getOverrides().margin?.right ?? this.idle.margin.right }
  public get marginBottom() { return this.getOverrides().margin?.bottom ?? this.idle.margin.bottom }

  public get paddingLeft() { return this.getOverrides().padding?.left ?? this.idle.padding.left }
  public get paddingTop() { return this.getOverrides().padding?.top ?? this.idle.padding.top }
  public get paddingRight() { return this.getOverrides().padding?.right ?? this.idle.padding.right }
  public get paddingBottom() { return this.getOverrides().padding?.bottom ?? this.idle.padding.bottom }

  public get positionLeft() {
    return (
      (this.getOverrides().position?.left ?? this.idle.position.left) +
      (this.getOverrides().position?.leftOffset ?? this.idle.position.leftOffset)
    )
  }
  public get positionTop() {
    return (
      (this.getOverrides().position?.top ?? this.idle.position.top) +
      (this.getOverrides().position?.topOffset ?? this.idle.position.topOffset)
    )
  }
  public get positionRight() {
    return (
      (this.getOverrides().position?.right ?? this.idle.position.right) +
      (this.getOverrides().position?.rightOffset ?? this.idle.position.rightOffset)
    )
  }
  public get positionBottom() {
    return (
      (this.getOverrides().position?.bottom ?? this.idle.position.bottom) +
      (this.getOverrides().position?.bottomOffset ?? this.idle.position.bottomOffset)
    )
  }
  public get positionZ() { return this.getOverrides().position?.z ?? this.idle.position.z }
  public get positionMode() { return this.getOverrides().position?.mode ?? this.idle.position.mode }

  private getOverrides(): AttributesOverrides {
    if (isActive(this.state)) { return this.active }
    if (isDisabled(this.state)) { return this.disabled }
    if (isFocused(this.state)) { return this.focused }
    if (isHovered(this.state)) { return this.hovered }
    return this.idle
  }
}
