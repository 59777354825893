import axios from 'axios'

import { identity, tail, Either, Left, Right } from '@cog/func'

const JSON_HEADERS = { 'content-type': 'application/json' }

export class File<T = unknown> {
  constructor(
    public readonly filename: string,
    public readonly path: string,
    public readonly data: T,
  ) {}
}

export const SUPPORTED_EXTENSIONS = ['json', 'frag', 'vert', 'png', 'jpg', 'jpeg', 'bmp', 'webm', 'obj']
const supportedExtensionsRegex = /\.(?:json|frag|vert|png|jpe?g|bmp|webm|obj)$/
const isImageRegex = /\.(?:png|jpe?g|bmp)$/

export const getFilenameFromPath = (path: string): Either<Error, string> => {
  const segments = path.split('/').filter(identity)
  if (segments.length) {
    const fileName = tail(segments).extract()
    if (supportedExtensionsRegex.test(fileName)) {
      return new Right(fileName)
    } else {
      return new Left(new Error(`File '${path}' has unrecognized extension, supported extensions are: ${SUPPORTED_EXTENSIONS.join(', ')}`))
    }
  } else {
    return new Left(new Error(`String '${path}' is not a valid file path`))
  }
}

export const openImageFile = async (url: string, filename: string): Promise<Either<Error, File<ImageData>>> =>
  new Promise((resolve, reject) => {
    const img = document.createElement('img')
    img.src = `/${url}`
    img.onerror = reject
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const [w, h] = [img.width, img.height]
      canvas.width = w
      canvas.height = h
      const ctx = canvas.getContext('2d')
      if (ctx) {
        ctx.drawImage(img, 0, 0, w, h)
        resolve(new Right(new File(filename, url, ctx.getImageData(0, 0, w, h))))
      } else {
        reject('Unable to create canvas 2d context')
      }
    }
  })

export const openGenericFile = async <T>(url: string, filename: string): Promise<Either<Error, File<T>>> =>
  axios
    .get(`${url}`, { headers: JSON_HEADERS })
    .then((res) => new Right(new File<T>(filename, url, res.data)))

export const openFile = async <T = unknown>(path: string): Promise<Either<Error, File<T>>> => {
  const filename = getFilenameFromPath(path)
  if (filename.isLeft()) {
    return Promise.reject(filename)
  } else {
    const extractedFilename = filename.extract()
    return (isImageRegex.test(extractedFilename)
      ? openImageFile(path, extractedFilename)
      : openGenericFile(path, extractedFilename)) as Promise<Either<Error, File<T>>>
  }
}
