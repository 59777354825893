// import './Examples/Arkanoid/arkanoid'
// import './Platformer/main'
// import './old'

import './index.css'

import { Game as Arkanoid } from './Examples/Arkanoid/Scenes/Game'
import { BasicEcsScene } from './Examples/BasicEcs/BasicEcsScene'
import { CaveScene } from './Examples/Cave/CaveScene'
import { IKScene } from './Examples/IK/IK'
import { IKProceduralBiped } from './Examples/IK/IKProceduralBiped'
import { Physics2dScene } from './Examples/Physics/main'
import { LoadAssets } from './Scenes/LoadAssets'
import { MainMenu } from './Scenes/MainMenu'
import { Splash } from './Scenes/Splash'
import { SceneRegistry } from './sceneRegistry'
import { world } from './world'

SceneRegistry.MainMenu = MainMenu
SceneRegistry.Splash = Splash
SceneRegistry.LoadAssets = LoadAssets
SceneRegistry.ArkanoidExample = Arkanoid
SceneRegistry.BasicEcsScene = BasicEcsScene
SceneRegistry.PhysicsECSExample = Physics2dScene
SceneRegistry.Cave = CaveScene
SceneRegistry.IK = IKScene
SceneRegistry.IKProceduralBiped = IKProceduralBiped

let old = 0
const loop = (elapsed: number) => {
  // limit to 15 fps to reduce the load on the battery
  if (((elapsed - old) / 1000) > (1 / 60)) {
    old = elapsed
    world.tick(elapsed)
  }
  requestAnimationFrame(loop)
}
loop(0)

world.task.execute.immediate((ctx) => {
  ctx.sceneManager.push(SceneRegistry.LoadAssets)
})
