import { World } from '@cog/ecs'
import {
  CanvasContext,
  Gui,
  Loaders,
  Parallel,
  Scene,
  Sequence,
  Timers,
  Transitions,
} from '@cog/ecs-canvas-2d-plugin'

import {
  FadeFromBlack,
  FadeToBlack,
  LoadAsset,
  ProgressLoading,
  ReplaceCurrentScene,
} from '../Actions'
import { Resource } from '../Resource'
import { clearScreen } from '../commonSystems'
import { SceneRegistry } from '../sceneRegistry'
import { background, loadingBar } from './commonStyles'

export class LoadAssets extends Scene<CanvasContext> {
  public create(world: World<CanvasContext>) {
    Gui.Create(world)
    Timers.Create(world)
    Transitions.Create(world)

    Gui.Element.Pane(world, { style: background })
    const overlay = Gui.Element.Pane(world, { style: { ...background, idle: { ...background.idle, background: { color: 0xff }}} })
    const loading = Gui.Element.Pane(world, { style: loadingBar })

    // TODO: this should ideally be generated in its own file by resource compiler script (which is still not written)
    const toLoad = [
      () => Loaders.loadNinePatch('sprites/default_button')
        .then((res) => res
          .map((data) => Gui.Repository.Store.NinePatchSprite(data))
          .map((id) => Resource.Sprite.NinePatch.DefaultButton = id)),
      () => Loaders.loadNinePatch('sprites/outline_button')
        .then((res) => res
          .map((data) => Gui.Repository.Store.NinePatchSprite(data))
          .map((id) => Resource.Sprite.NinePatch.OutlineButton = id)),
      () => Loaders.loadSpriteAtlas('sprites/default_font')
        .then((res) => res
          .map((data) => Gui.Repository.Store.FontSprite(data))
          .map((id) => Resource.Sprite.FontAtlas.DefaultFont = id)),
      () => Loaders.loadNinePatch('sprites/backdrop')
        .then((res) => res
          .map((data) => Gui.Repository.Store.NinePatchSprite(data))
          .map((id) => Resource.Sprite.NinePatch.Waterfall = id)),
    ]

    world.context.actionManager.run(
      new Sequence([
        new FadeFromBlack(overlay),
        new Parallel(toLoad.map((thunk, count) =>
          new Sequence([
            new LoadAsset(thunk),
            new ProgressLoading(loading, count / toLoad.length, (count + 1) / toLoad.length),
          ])),
        ),
        new FadeToBlack(overlay),
        new ReplaceCurrentScene(SceneRegistry.Splash),
        // new ReplaceCurrentScene(SceneRegistry.IKProceduralBiped),
      ]),
    )

    world.task.on.before(clearScreen)
  }

  public delete(world: World<CanvasContext>) {
    Gui.Delete(world)
    Timers.Delete(world)
    Transitions.Delete(world)

    world.task.delete(clearScreen)
  }
}
