import { CanvasContext } from '../../Context'
import { Init } from '../types'


const noop = () => {}

export type ButtonProps<C extends CanvasContext> = { onClick: (ctx: C, id: number) => void }
export class Button<C extends CanvasContext> implements Init<ButtonProps<C>> {
  public onClick!: (ctx: C, id: number) => void

  constructor(props?: Partial<{ onClick: typeof Button.prototype.onClick }>) {
    this.init(props)
  }

  public init(props?: Partial<{ onClick: typeof Button.prototype.onClick }>) {
    this.onClick = props?.onClick ?? noop
  }
}
