import { Either, Left, Right } from '@cog/func'

import { File } from '../filesystem'

export const parseImage = (file: File): Either<Error, ImageData> => {
  if (file.data instanceof ImageData) {
    return new Right(file.data)
  } else {
    return new Left(new Error(`File '${file.path}' is not an image file`))
  }
}
