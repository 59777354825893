import { Context } from '@cog/ecs'


const noop = () => {}

export class Timer<C extends Context> {
  public current: number = 0.0
  public duration: number = 0.0
  public callback: (ctx: C, timer: this) => void = noop
}
