import { Vec2 } from '@cog/math'

export class Collision {
  constructor(
    public fst: number | null = null,
    public snd: number | null = null,
    public normal: Vec2 = Vec2.Null(),
    public penetration: number = 0.0,
    // just for debugging
    public middle: Vec2 = Vec2.Null(),
  ) {}
}
