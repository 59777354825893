import { World } from '@cog/ecs'
import { CanvasContext, Gui, Scene, Sequence, Transitions } from '@cog/ecs-canvas-2d-plugin'

import { FadeFromBlack, FadeToBlack, ReplaceCurrentScene } from '../../Actions'
import { background, defaultIdleStyle, defaultModifiers } from '../../Scenes/commonStyles'
import { SceneRegistry } from '../../sceneRegistry'
import { CaveGenerator } from './CaveGenerator'


const SCALE = 1
export class CaveScene extends Scene<CanvasContext> {
  private generator!: CaveGenerator
  private steps = 0
  private shouldGenerate = 0

  public create(world: World<CanvasContext>) {
    this.using(Gui, Transitions)

    this.generator = new CaveGenerator(world.context.width >> SCALE, world.context.height >> SCALE)
    this.generator.generate(this.shouldGenerate < 0)

    world.task.on.update(this.stepCaveGeneration)
    world.task.on.update(this.renderCave)

    const overlay = Gui.Element.Pane(world, { style: { ...background, idle: { ...background.idle, background: { color: 0xff }}} })
    world.context.actionManager.run(new FadeFromBlack(overlay))

    Gui.Element.Button(world, {
      onClick: () => {
        world.context.actionManager.run(
          new Sequence([
            new FadeToBlack(overlay),
            new ReplaceCurrentScene(SceneRegistry.MainMenu),
          ]),
        )
      },

      label: { text: '<-' },
      style: { ...defaultModifiers, idle: {
        ...defaultIdleStyle().idle,
        position: { left: 5, top: 5, right: 25, bottom: 25 },
      }},
    })

    Gui.Element.Button(world, {
      onClick: () => {
        this.shouldGenerate = -1
      },

      label: { text: '<' },
      style: { ...defaultModifiers, idle: {
        ...defaultIdleStyle().idle,
        position: { left: world.context.width - 45, top: 5, right: world.context.width - 25, bottom: 25 },
      }},
    })

    Gui.Element.Button(world, {
      onClick: () => {
        this.shouldGenerate = 1
      },

      label: { text: ' >' },
      style: { ...defaultModifiers, idle: {
        ...defaultIdleStyle().idle,
        position: { left: world.context.width - 25, top: 5, right: world.context.width - 5, bottom: 25 },
      }},
    })
  }

  public delete(world: World<CanvasContext>) {
    world.task.delete(this.renderCave)
    world.task.delete(this.stepCaveGeneration)
  }

  private stepCaveGeneration = (ctx: CanvasContext) => {
    if (this.steps < 40) {
      this.generator.runCellularAutomataIteration()
      this.steps++

      if (this.steps === 40) {
        this.generator.findIslands()
        this.generator.findCoastline()
        this.generator.bridgeIslands()
        this.generator.findIslands()
        this.generator.findCoastline()
      }
    }

    if (this.shouldGenerate !== 0) {
      this.steps = 0
      this.generator.generate(this.shouldGenerate < 1)
      this.shouldGenerate = 0
    }
  }

  private renderCave = (ctx: CanvasContext) => {
    const g = ctx.graphics.gfx

    g.clearRect(0, 0, ctx.width, ctx.height)

    for (let i = 0; i < this.generator.width; ++i) {
      for (let j = 0; j < this.generator.height; ++j) {
        g.fillStyle = this.generator.cells[i][j].color
        g.fillRect(i << SCALE, j << SCALE, 1 << SCALE, 1 << SCALE)
      }
    }
  }
}
