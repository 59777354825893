import { World } from './World'

class Timer {
  private _delta: number = 0.0
  private _elapsed: number = 0.0

  public tick(elapsed: number) {
    this._delta = (elapsed * 0.001) - this._elapsed
    this._elapsed = (elapsed * 0.001)
  }

  public get delta() { return this._delta }
  public get elapsed() { return this._elapsed }
}

export class Context {
  private _timer = new Timer()
  private _world!: World<this>

  public get world() { return this._world }

  public tick(elapsed: number) {
    this._timer.tick(elapsed)
  }

  public swap() {}

  public get timer(): Readonly<Timer> { return this._timer }
}
