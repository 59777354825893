// NOTE: all of these guys have been taken from this awesome answer on stack overflow:
// NOTE: https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript

export const Sfc32 = (seedA: number, seedB: number, seedC: number, seedD: number) =>
  () => {
    seedA >>>= 0
    seedB >>>= 0
    seedC >>>= 0
    seedD >>>= 0

    let  t = (seedA + seedB) | 0
    seedA = seedB ^ seedB >>> 9
    seedB = seedC + (seedC << 3) | 0
    seedC = (seedC << 21 | seedC >>> 11)
    seedD = seedD + 1 | 0
    t = t + seedD | 0
    seedC = seedC + t | 0
    return (t >>> 0) / 4294967296
  }

export const Mulberry32 = (seed: number) =>
  () => {
    let t = seed += 0x6D2B79F5
    t = Math.imul(t ^ t >>> 15, t | 1)
    t ^= t + Math.imul(t ^ t >>> 7, t | 61)
    return ((t ^ t >>> 14) >>> 0) / 4294967296
  }

export const Xoshiro128ss = (seedA: number, seedB: number, seedC: number, seedD: number) =>
  () => {
    const t = seedB << 9
    let r = seedA * 5
    r = (r << 7 | r >>> 25) * 9
    seedC ^= seedA; seedD ^= seedB
    seedB ^= seedC; seedA ^= seedD; seedC ^= t
    seedD = seedD << 11 | seedD >>> 21
    return (r >>> 0) / 4294967296
  }

export const Jsf = (seed: number) => {
  seed >>>= 0
  const s = [0xf1ea5eed, seed, seed, seed]

  const jsf = () => {
    const e = s[0] - (s[1]<<27 | s[1]>>5)
    s[0] = s[1] ^ (s[2]<<17 | s[2]>>15)
    s[1] = s[2] + s[3]
    s[2] = s[3] + e
    s[3] = s[0] + e
    return (s[3] >>> 0) / 4294967295 // 2^32-1
  }

  for (let i = 0; i < 20; i++) {
    jsf()
  }
  return jsf
}