import { Left, Right } from '@cog/func'

import { NinePatchSprite } from '../GUI/Data/NinePatchSprite'
import { Sprite } from '../GUI/Data/Sprite'
import { AtlasEntry, SpriteAtlas } from '../GUI/Data/SpriteAtlas'
import { parseImage } from './Parsers/parseImage'
import { openFile } from './filesystem'

export const loadImage = async (path: string) =>
  (await openFile(path))
    .chain(parseImage)
    .caseOf({
      Left: (error) => {
        console.error(error)
        return error
      },
      Right: (imageData) => {
        const canvas = document.createElement('canvas')
        canvas.width = imageData.width
        canvas.height = imageData.height

        const ctx = canvas.getContext('2d')
        if (ctx == null) {
          return new Left<Error, typeof canvas>(new Error(`Unable to render the image '${path}' to offscreen canvas, unable to acquire 2d context`))
        } else {
          ctx.putImageData(imageData, 0, 0)
          return new Right<Error, typeof canvas>(canvas)
        }
      },
    })

export const loadJSON = async (path: string) =>
  (await openFile(path))
    .caseOf({
      Left: (error) => {
        console.error(error)
        return error
      },
      Right: (json) => {
        console.log(json.data)
        return new Right<Error, object>(json)
      },
    })

type NinePatchDescriptor = {
  left: number,
  top: number,
  right: number,
  bottom: number,
}

export const loadSprite = async (path: string) => {
  const spritePath =`${path}.png`

  const [image] = await Promise.all([
    loadImage(spritePath),
  ])

  return image
    .chain((canvas) => {
      const imageData = canvas.getContext('2d')?.getImageData(0, 0, canvas.width, canvas.height)
      return imageData == null
        ? new Left<Error, Sprite>(new Error(`Unable to fetch image data for '${spritePath}'`))
        : new Right(new Sprite(canvas, imageData, imageData.width, imageData.height))
    })
}

export const loadNinePatch = async (path: string) => {
  const jsonPath =`${path}.json`
  const spritePath =`${path}.png`

  const [file, image] = await Promise.all([
    openFile(jsonPath),
    loadImage(spritePath),
  ])

  return file
    .chain((file) => new Right(file.data as NinePatchDescriptor))
    .chain((definition) => image
      .chain((canvas) => {
        const { left, top, right, bottom } = definition
        const imageData = canvas.getContext('2d')?.getImageData(0, 0, canvas.width, canvas.height)
        return imageData == null
          ? new Left<Error, NinePatchSprite>(new Error(`Unable to fetch image data for '${spritePath}'`))
          : new Right(new NinePatchSprite(canvas, imageData, canvas.width, canvas.height, left, top, right, bottom))
      })
    )
}

export const loadSpriteAtlas = async (path: string) => {
  const jsonPath =`${path}.json`
  const spritePath =`${path}.png`

  const [file, image] = await Promise.all([
    openFile(jsonPath),
    loadImage(spritePath),
  ])

  return file
    .chain((file) => new Right(file.data as { sprites: { [key: string]: [number, number, number, number] }}))
    .chain((definition) => image
      .chain((canvas) => {
        const { sprites } = definition
        const atlas = Object.entries(sprites).reduce((memo: { [key: string]: AtlasEntry }, [name, rect]) => {
          memo[name] = new AtlasEntry(name, ...rect)
          return memo
        }, {})
        const imageData = canvas.getContext('2d')?.getImageData(0, 0, canvas.width, canvas.height)
        return imageData == null
          ? new Left<Error, SpriteAtlas>(new Error(`Unable to fetch image data for '${spritePath}'`))
          : new Right(new SpriteAtlas(canvas, imageData, atlas))
      })
    )
}
