
export class AABB {
  constructor(
    public minX: number = 0.0,
    public maxX: number = 0.0,
    public minY: number = 0.0,
    public maxY: number = 0.0,
  ) {}
}

export class Collider {
  private _radius!: number

  constructor(
    radius = 1.0,
    public aabb = new AABB(),
  ) {
    this.radius = radius
  }

  public get radius() { return this._radius }
  public set radius(val) {
    this.aabb.minX = -val
    this.aabb.maxX = val
    this.aabb.minY = -val
    this.aabb.maxY = val
    this._radius = val
  }
}