import { CanvasContext, HEIGHT, WIDTH } from '@cog/ecs-canvas-2d-plugin'
import { AABRect, Vec2 } from '@cog/math'

import { world } from '../../../world'
import { BallProps } from '../Components/Ball'
import { BrickProps } from '../Components/Brick'
import { Physics, Transform } from '../Components/Common'
import { PaddleProps } from '../Components/Paddle'
import { Ball, Paddle } from '../Components/Tags'

export const resolveBallCollisions = (ctx: CanvasContext, [transform, physics, props]: [Transform, Physics, BallProps, Ball]) => {
  if (transform.position.x - props.radius < 0) {
    physics.velocity = new Vec2(-physics.velocity.x, physics.velocity.y)
    transform.position = new Vec2(props.radius, transform.position.y)
  }
  if (transform.position.x + props.radius > WIDTH) {
    physics.velocity = new Vec2(-physics.velocity.x, physics.velocity.y)
    transform.position = new Vec2(WIDTH - props.radius, transform.position.y)
  }

  if (transform.position.y - props.radius < 0) {
    physics.velocity = new Vec2(physics.velocity.x, -physics.velocity.y)
    transform.position = new Vec2(transform.position.x, props.radius)
  }
  if (transform.position.y + props.radius > HEIGHT) {
    physics.velocity = new Vec2(physics.velocity.x, -physics.velocity.y)
    transform.position = new Vec2(transform.position.x, HEIGHT - props.radius)
  }

  let ballAABRect = new AABRect(
    new Vec2(transform.position.x - props.radius, transform.position.y - props.radius),
    new Vec2(transform.position.x + props.radius, transform.position.y + props.radius),
  )

  world.query([Transform, PaddleProps, Paddle], (_, [paddleTransform, paddleProps]) => {
    const paddleAABRect = new AABRect(
      new Vec2(paddleTransform.position.x - (paddleProps.width >> 1), paddleTransform.position.y),
      new Vec2(paddleTransform.position.x + (paddleProps.width >> 1), paddleTransform.position.y),
    )

    if (paddleAABRect.intersects(ballAABRect)) {
      const midBall = transform.position
      const midPaddle = new Vec2(paddleTransform.position.x, paddleTransform.position.y + paddleProps.width)
      const newDirection = midBall.sub(midPaddle).normalize()

      physics.velocity = newDirection
      transform.position = new Vec2(transform.position.x, paddleTransform.position.y - props.radius)
    }
  })

  world.query([Transform, BrickProps], (_, args) => {
    const brickProps = args[1]

    const intersection = ballAABRect.intersection(brickProps.aabr)
    if (intersection != null) {
      brickProps.health -= 1

      const brickPosition = brickProps.aabr.center()
      const brickToBall = brickPosition.sub(transform.position)

      if (intersection.height < intersection.width) {
        if (brickToBall.y < 0) {
          // ball is bellow the brick
          transform.position = new Vec2(transform.position.x, transform.position.y + intersection.height + 1)
          physics.velocity = new Vec2(physics.velocity.x, -physics.velocity.y)
        } else {
          // ball is above the brick
          transform.position = new Vec2(transform.position.x, transform.position.y - intersection.height - 1)
          physics.velocity = new Vec2(physics.velocity.x, -physics.velocity.y)
        }
      } else {
        if (brickToBall.x < 0) {
          // ball is to the left of brick
          transform.position = new Vec2(transform.position.x - intersection.width - 1, transform.position.y)
          physics.velocity = new Vec2(-physics.velocity.x, physics.velocity.y)
        } else {
          // ball is to the right of the brick
          transform.position = new Vec2(transform.position.x + intersection.width + 1, transform.position.y)
          physics.velocity = new Vec2(-physics.velocity.x, physics.velocity.y)
        }
      }

      // update the ball bounding box
      ballAABRect = new AABRect(
        new Vec2(transform.position.x - props.radius, transform.position.y - props.radius),
        new Vec2(transform.position.x + props.radius, transform.position.y + props.radius),
      )

      physics.speed += 0.5
    }
  })
}
