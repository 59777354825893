import { head, tail, zip } from './array'
import { compose } from './compose'
import { all, any, identity, not } from './func'
import { memoize, memoizeOnce } from './memoize'
import { pipe } from './pipe'
import { tagFactory, TagFactory } from './tagFactory'

export {
  compose,
  pipe,
  identity,
  not,
  all,
  any,
  memoize,
  memoizeOnce,
  zip,
  head,
  tail,
  tagFactory,
  TagFactory,
}
