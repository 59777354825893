import { Context, Reference } from '@cog/ecs'

import { Transition, TransitionRepeatMode } from '../Components/Transition'


export const tickTransition = <C extends Context>(ctx: C, args: [Transition<any>]) => {
  const transition = args[0]
  const t = transition.current + transition.direction * ctx.timer.delta

  const l = transition.delayStart / transition.total
  const h = (transition.delayStart + transition.duration) / transition.total
  transition.current = Math.max(Math.min(transition.total, t), 0.0)
  const tt = transition.current / transition.total
  transition.t = Math.max(l, Math.min(h, tt)) / (h - l)

  switch (transition.type) {
  case TransitionRepeatMode.NoRepeat:
    if (t >= transition.total && transition.repeats > 0) {
      transition.loopCount++
    }
    break
  case TransitionRepeatMode.Loop:
    if (t >= transition.total) {
      if (transition.repeats > 0) {
        transition.loopCount++
      }
      transition.current -= transition.total
    }
    break
  case TransitionRepeatMode.BackAndForth:
    if (t >= transition.total || t <= 0.0) {
      if (transition.repeats > 0) {
        transition.loopCount++
      }
      transition.direction = -transition.direction as any
    }
    break
  default:
  }

  if (transition.reference !== Reference.Null) {
    transition.mutation(transition.t, transition.reference)
  }
}
