import { Vec2 } from '@cog/math'
import { Jsf } from '@cog/random'

import { Cell, CellType } from './Cell'
import { Island } from './Island'

export class CaveGenerator {
  private _rng: () => number
  private _cells: [Cell[][], Cell[][]] = [[], []]
  private _islands: Map<number, Island> = new Map()
  private _activeBuffer = 0

  private seed = 0

  constructor(
    public readonly width: number = 32,
    public readonly height: number = 32,
  ) {
    this._rng = Jsf(this.seed)
    this._cells = [new Array(this.width), new Array(this.width)]
    for (let i = 0; i < this.width; ++i) {
      this._cells[0][i] = new Array(this.height)
      this._cells[1][i] = new Array(this.height)
      for (let j = 0; j < this.height; ++j) {
        this._cells[0][i][j] = new Cell(CellType.Water, new Vec2(i, j), '#000000')
        this._cells[1][i][j] = new Cell(CellType.Water, new Vec2(i, j), '#000000')
      }
    }
  }

  public get cells() { return this._cells[this._activeBuffer] as ReadonlyArray<ReadonlyArray<Cell>> }

  public generate(prevSeed: boolean) {
    this.seed = prevSeed ? Math.max(0, this.seed - 1) : this.seed + 1
    this._rng = Jsf(this.seed)

    this.clear()
    this.fillWithRandomWaterCells()
  }

  public runCellularAutomataIteration() {
    const front = this._cells[this._activeBuffer]
    const back = this._cells[this._activeBuffer = (this._activeBuffer + 1) % 2]

    for (let i = 1; i < this.width - 1; ++i) {
      for (let j = 1; j < this.height - 1; ++j) {
        const cell = front[i][j]
        back[i][j].type = front[i][j].type
        back[i][j].color = front[i][j].color
        if (cell.type === CellType.Water && this.waterCellsAroundPoint(i, j) < 5) {
          back[i][j].type = CellType.Ground
          back[i][j].color = '#819A93'
        } else if (cell.type === CellType.Ground && this.waterCellsAroundPoint(i, j) > 4) {
          back[i][j].type = CellType.Water
          back[i][j].color = '#000000'
        }
      }
    }
  }

  public findIslands() {
    const front = this.cells
    const visited: Map<number, Map<number, number>> = new Map()
    for (let i = 0; i < this.width; ++i) {
      visited.set(i, new Map())
    }

    const floodFill = (x: number, y: number, id: number) => {
      const queue = [[x, y] as const]

      while (queue.length > 0) {
        const [x, y] = queue.shift()!
        if (visited.get(x)?.get(y) != null) {
          continue
        }
        visited.get(x)!.set(y, id)
        const l = x - 1
        const r = x + 1
        const t = y - 1
        const b = y + 1
        if (front[l][y].type !== CellType.Water && visited.get(l)?.get(y) == null) {
          queue.push([l, y])
        }
        if (front[r][y].type !== CellType.Water && visited.get(r)?.get(y) == null) {
          queue.push([r, y])
        }
        if (front[x][t].type !== CellType.Water && visited.get(x)?.get(t) == null) {
          queue.push([x, t])
        }
        if (front[x][b].type !== CellType.Water && visited.get(x)?.get(b) == null) {
          queue.push([x, b])
        }
      }
    }

    let islandId = 0
    this._islands.clear()

    for (let i = 1; i < this.width - 1; ++i) {
      for (let j = 1; j < this.height - 1; ++j) {
        if (front[i][j].type !== CellType.Water && visited.get(i)?.get(j) == null) {
          this._islands.set(islandId, new Island([], []))
          floodFill(i, j, islandId++)
        }
      }
    }

    for (const [x, m] of visited.entries()) {
      for (const [y, id] of m.entries()) {
        front[x][y].color = '#819A93'
        this._islands.get(id)!.cells.push(front[x][y])
      }
    }
  }

  public findCoastline() {
    for (const [, island] of this._islands) {
      for (const cell of island.cells) {
        cell.color = `#819A93`
        if (this.isCoastlineCell(cell.position.x, cell.position.y)) {
          // cell.type = CellType.Ground
          cell.color = `#CCCCCC`
          island.coastline.push(cell)
        }
      }
    }
  }

  public bridgeIslands() {
    if (this._islands.size < 1) { return }

    const bridges: Array<[number, Cell, number, Cell, number]> = []

    const lenSq = (ax: number, ay: number, bx: number, by: number) => {
      return (bx - ax)**2 + (by - ay)**2
    }

    for (const [fstId, fstIsland] of this._islands) {
      for (const [sndId, sndIsland] of this._islands) {
        // ensure no duplicate checks were made
        if (sndId > fstId) {
          let smaller = fstIsland.coastline
          let larger = sndIsland.coastline

          if (sndIsland.coastline.length < fstIsland.coastline.length) {
            smaller = sndIsland.coastline
            larger = fstIsland.coastline
          }

          // do the dumb exhaustive search to find the closest path from smaller to larger island
          let shortestBridge: [number, Cell, number, Cell, number] = [+Infinity, null, -1, null, -1] as any
          for (const fstCell of smaller) {
            for (const sndCell of larger) {
              const l = lenSq(fstCell.position.x, fstCell.position.y, sndCell.position.x, sndCell.position.y)
              if (l < shortestBridge[0]) {
                shortestBridge = [l, fstCell, fstId, sndCell, sndId]
              }
            }
          }
          bridges.push(shortestBridge)
        }
      }
    }

    const front = this.cells
    bridges.sort((a, b) => a[0] < b[0] ? -1 : 1)

    const visitedIslands: Set<number> = new Set()
    const relevantBridges: typeof bridges =[]
    while (bridges.length > 0) {
      const bridge = bridges.shift()!
      const [, , aId, , bId] = bridge

      if (
        visitedIslands.has(aId) && !visitedIslands.has(bId) ||
        !visitedIslands.has(aId) && visitedIslands.has(bId) || 
        visitedIslands.size === 0 && !visitedIslands.has(aId) && !visitedIslands.has(bId)
      ) {
        relevantBridges.push(bridge)
        visitedIslands.add(aId)
        visitedIslands.add(bId)
      } else if (!visitedIslands.has(aId) && !visitedIslands.has(bId)) {
        bridges.push(bridge)
      }
    }

    for (const [, a, , b, ] of relevantBridges) {

      const dx = (b.position.x - a.position.x) / Math.abs(b.position.x - a.position.x)
      const dy = (b.position.y - a.position.y) / Math.abs(b.position.y - a.position.y)

      for (let m = -2; m < 3; ++m) {
        for (let i = a.position.x; i !== b.position.x; i += dx) {
          front[i][a.position.y + m].type = CellType.Ground
          front[i][a.position.y + m].color = '#819A93'
        }

        for (let j = a.position.y; j !== b.position.y; j += dy) {
          front[b.position.x + m][j].type = CellType.Ground
          front[b.position.x + m][j].color = '#819A93'
        }
      }
    }
  }

  private clear() {
    const front = this._cells[0]
    const back = this._cells[1]

    for (let i = 1; i < this.width - 1; ++i) {
      for (let j = 1; j < this.height - 1; ++j) {
        front[i][j].color = back[i][j].color = '#000000'
        front[i][j].type = back[i][j].type = CellType.Water
      }
    }

    this._islands.clear()
    this._activeBuffer = 0
  }

  private fillWithRandomWaterCells() {
    const front = this._cells[this._activeBuffer]
    const back = this._cells[(this._activeBuffer + 1) % 2]
    for (let i = 1; i < this.width - 1; ++i) {
      for (let j = 1; j < this.height - 1; ++j) {
        const val = this._rng()
        front[i][j].type = back[i][j].type = val > 0.502 ? CellType.Water : CellType.Ground
        front[i][j].color = back[i][j].color = front[i][j].type === CellType.Water ? '#000000' : '#819A93'
      }
    }
  }

  private isCoastlineCell(x: number, y: number) {
    const buffer = this.cells

    let wallsAroundPoint = 0
    wallsAroundPoint += buffer[x    ][y - 1].type === CellType.Water ? 1 : 0
    wallsAroundPoint += buffer[x - 1][y    ].type === CellType.Water ? 1 : 0
    wallsAroundPoint += buffer[x + 1][y    ].type === CellType.Water ? 1 : 0
    wallsAroundPoint += buffer[x    ][y + 1].type === CellType.Water ? 1 : 0

    return wallsAroundPoint > 0
  }

  private waterCellsAroundPoint(x: number, y: number) {
    const buffer = this.cells

    let wallsAroundPoint = 0
    wallsAroundPoint += buffer[x - 1][y - 1].type === CellType.Water ? 1 : 0
    wallsAroundPoint += buffer[x    ][y - 1].type === CellType.Water ? 1 : 0
    wallsAroundPoint += buffer[x + 1][y - 1].type === CellType.Water ? 1 : 0

    wallsAroundPoint += buffer[x - 1][y    ].type === CellType.Water ? 1 : 0
    wallsAroundPoint += buffer[x    ][y    ].type === CellType.Water ? 1 : 0
    wallsAroundPoint += buffer[x + 1][y    ].type === CellType.Water ? 1 : 0

    wallsAroundPoint += buffer[x - 1][y + 1].type === CellType.Water ? 1 : 0
    wallsAroundPoint += buffer[x    ][y + 1].type === CellType.Water ? 1 : 0
    wallsAroundPoint += buffer[x + 1][y + 1].type === CellType.Water ? 1 : 0

    return wallsAroundPoint
  }

}
