import { World } from '@cog/ecs'
import { CanvasContext, Gui, Scene, Sequence, Transitions } from '@cog/ecs-canvas-2d-plugin'

import { FadeFromBlack, FadeToBlack, ReplaceCurrentScene } from '../../Actions'
import { background, defaultIdleStyle, defaultModifiers } from '../../Scenes/commonStyles'
import { SceneRegistry } from '../../sceneRegistry'


class Vec2 {
  constructor(
    public x: number = 0.0,
    public y: number = 0.0,
  ) {}
}

class Position extends Vec2 {}
class Velocity extends Vec2 {}
class Aging {
  constructor(public life: number = 0.0) {}
}
class Mouse {}

class GravityWell {
  constructor(
    public radius: number = 0.0,
    public power: number = 0.0,
  ) {}
}

export class BasicEcsScene extends Scene<CanvasContext> {
  public create(world: World<CanvasContext>) {
    this.using(Gui, Transitions)

    const overlay = Gui.Element.Pane(world, { style: { ...background, idle: { ...background.idle, background: { color: 0xff }}} })
    world.context.actionManager.run(new FadeFromBlack(overlay))

    Gui.Element.Button(world, {
      onClick: () => {
        world.context.actionManager.run(
          new Sequence([
            new FadeToBlack(overlay),
            new ReplaceCurrentScene(SceneRegistry.MainMenu),
          ]),
        )
      },

      label: { text: '<-' },
      style: { ...defaultModifiers, idle: {
        ...defaultIdleStyle().idle,
        position: { left: 5, top: 5, right: 25, bottom: 25 },
      }},
    })

    world.task.execute.immediate(initial)
    world.system.on.update([Position, Velocity], wrap)
    world.system.on.update([Position], processKeyboard)
    world.system.on.update([Position, Velocity], applyGravity)
    world.system.on.update([Position, Velocity], integrate)
    world.system.on.after([Aging], age)

    world.task.on.before(begin)
    world.system.on.update([Position, Velocity], render)
    world.system.on.update([Mouse], renderMouse)
    world.system.on.update([Mouse], createGravityWell)
    world.task.on.after(end)
    world.task.on.after(deleteAged)

    world.entity([Mouse], () => {})

    for (let i = 0; i < 5000; ++i) {
      world.entity([Position, Velocity], ([pos, vel]) => {
        pos.x = Math.random() * world.context.width
        pos.y = Math.random() * world.context.height

        vel.x = 0.0
        vel.y = 0.0
      })
    }
  }

  public delete(world: World<CanvasContext>) {
    world.context.graphics.gfx.restore()

    world.deleteWhere([Mouse], () => true)
    world.deleteWhere([Position, Velocity], () => true)
    world.deleteWhere([GravityWell], () => true)

    world.system.delete(wrap)
    world.system.delete(processKeyboard)
    world.system.delete(applyGravity)
    world.system.delete(integrate)
    world.system.delete(age)

    world.task.delete(begin)
    world.system.delete(render)
    world.system.delete(renderMouse)
    world.system.delete(createGravityWell)
    world.task.delete(end)
    world.task.delete(deleteAged)
  }
}

const applyGravity = (ctx: CanvasContext, args: [Position, Velocity]) => {
  const pos = args[0]
  const vel = args[0]

  const dt = ctx.timer.delta

  ctx.world.query([Position, GravityWell], (_, args) => {
    const wellPos = args[0]
    const well = args[1]

    const wx = wellPos.x - pos.x
    const wy = wellPos.y - pos.y
    const dsq = wx**2 + wy**2
    if (dsq <= well.radius**2) {
      let influence = (1.0 - (Math.sqrt(dsq) / well.radius)) ** 3

      influence *= well.power

      vel.x += influence * dt * wx
      vel.y += influence * dt * wy
    }
  })
}

const integrate = (ctx: CanvasContext, args: [Position, Velocity]) => {
  const pos = args[0]
  const vel = args[1]

  const dt = ctx.timer.delta

  pos.x += vel.x * dt
  pos.y += vel.y * dt

  vel.x = 20.0 * (Math.random() * 2.0 - 1.0)
  vel.y = 20.0 * (Math.random() * 2.0 - 1.0)
}

const age = (ctx: CanvasContext, args: [Aging]) => {
  args[0].life -= ctx.timer.delta
}

const deleteAged = (ctx: CanvasContext) => {
  ctx.world.deleteWhere([Aging], (args) => {
    return args[0].life <= 0.0
  })
}

const wrap = (ctx: CanvasContext, args: [Position, Velocity]) => {
  const pos = args[0]

  if (pos.x < 0.0) {
    pos.x = ctx.width
  } else if (pos.x > ctx.width) {
    pos.x = 0.0
  }

  if (pos.y < 0.0) {
    pos.y = ctx.width
  } else if (pos.y > ctx.width) {
    pos.y = 0.0
  }
}

const processKeyboard = (ctx: CanvasContext, args: [Position]) => {
  if (ctx.io.keyboard.key(' ').isDown) {
    const dx = -(args[0].x - (ctx.width >> 1))
    const dy = -(args[0].y - (ctx.height >> 1))

    const dt = ctx.timer.delta

    args[0].x += dt * dx
    args[0].y += dt * dy
  }
}

const getMousePos = (ctx: CanvasContext) => {
  const mouse = ctx.io.mouse
  const rect = ctx.graphics.canvas.getBoundingClientRect()
  const dx = rect.left
  const dy = rect.top
  const w = rect.width
  const h = rect.height

  const mpx = Math.min(Math.max((mouse.pointer.position.x - dx) / w, 0.0), 1.0) * ctx.width
  const mpy = Math.min(Math.max((mouse.pointer.position.y - dy) / h, 0.0), 1.0) * ctx.height

  return new Vec2(mpx, mpy)
}

const createGravityWell = (ctx: CanvasContext, _: [Mouse]) => {
  const mouse = ctx.io.mouse
  if (mouse.left.wasPressed || mouse.right.wasPressed) {
    const mousePos = getMousePos(ctx)
    const radius = 40.0 + (Math.random() - 0.5) * 10.0
    const direction = mouse.left.wasPressed ? -1.0 : 1.0
    const power = direction * (Math.random() + 1.0) * 2
    const life = 6.0

    ctx.world.entity([Position, GravityWell, Aging], (args) => {
      const pos = args[0]
      const well = args[1]
      const aging = args[2]
      pos.x = mousePos.x
      pos.y = mousePos.y
      well.power = power
      well.radius = radius
      aging.life = life
    })

    if (mousePos.x - radius < 0) {
      ctx.world.entity([Position, GravityWell, Aging], (args) => {
        const pos = args[0]
        const well = args[1]
        const aging = args[2]
        pos.x = mousePos.x + ctx.width
        pos.y = mousePos.y
        well.power = power
        well.radius = radius
        aging.life = life
      })
    }

    if (mousePos.x + radius > ctx.width) {
      ctx.world.entity([Position, GravityWell, Aging], (args) => {
        const pos = args[0]
        const well = args[1]
        const aging = args[2]
        pos.x = mousePos.x - ctx.width
        pos.y = mousePos.y
        well.power = power
        well.radius = radius
        aging.life = life
      })
    }

    if (mousePos.y - radius < 0) {
      ctx.world.entity([Position, GravityWell, Aging], (args) => {
        const pos = args[0]
        const well = args[1]
        const aging = args[2]
        pos.x = mousePos.x
        pos.y = mousePos.y + ctx.height
        well.power = power
        well.radius = radius
        aging.life = life
      })
    }

    if (mousePos.y + radius > ctx.height) {
      ctx.world.entity([Position, GravityWell, Aging], (args) => {
        const pos = args[0]
        const well = args[1]
        const aging = args[2]
        pos.x = mousePos.x
        pos.y = mousePos.y - ctx.height
        well.power = power
        well.radius = radius
        aging.life = life
      })
    }

    if (mousePos.y + radius > ctx.height && mousePos.x + radius > ctx.width) {
      ctx.world.entity([Position, GravityWell, Aging], (args) => {
        const pos = args[0]
        const well = args[1]
        const aging = args[2]
        pos.x = mousePos.x - ctx.width
        pos.y = mousePos.y - ctx.height
        well.power = power
        well.radius = radius
        aging.life = life
      })
    }

    if (mousePos.y + radius > ctx.height && mousePos.x - radius < 0) {
      ctx.world.entity([Position, GravityWell, Aging], (args) => {
        const pos = args[0]
        const well = args[1]
        const aging = args[2]
        pos.x = mousePos.x + ctx.width
        pos.y = mousePos.y - ctx.height
        well.power = power
        well.radius = radius
        aging.life = life
      })
    }

    if (mousePos.y - radius < 0 && mousePos.x - radius < 0) {
      ctx.world.entity([Position, GravityWell, Aging], (args) => {
        const pos = args[0]
        const well = args[1]
        const aging = args[2]
        pos.x = mousePos.x + ctx.width
        pos.y = mousePos.y + ctx.height
        well.power = power
        well.radius = radius
        aging.life = life
      })
    }

    if (mousePos.y - radius < 0 && mousePos.x + radius > ctx.width) {
      ctx.world.entity([Position, GravityWell, Aging], (args) => {
        const pos = args[0]
        const well = args[1]
        const aging = args[2]
        pos.x = mousePos.x - ctx.width
        pos.y = mousePos.y + ctx.height
        well.power = power
        well.radius = radius
        aging.life = life
      })
    }
  }
}

const renderMouse = (ctx: CanvasContext, _: [Mouse]) => {
  const mousePos = getMousePos(ctx)
  const gfx = ctx.graphics.gfx
  gfx.moveTo(mousePos.x + 20, mousePos.y)
  gfx.arc(mousePos.x, mousePos.y, 20, 0, 2.0 * Math.PI)
}

const initial = (ctx: CanvasContext) => {
  const gfx = ctx.graphics.gfx
  gfx.save()
  gfx.fillStyle = 'rgba(255, 255, 255, 1.0)'
  gfx.strokeStyle = 'purple'
  gfx.lineWidth = 0.1
  gfx.fillRect(0, 0, ctx.width, ctx.height)
  gfx.fillStyle = 'rgba(255, 255, 255, 0.1)'
}

const begin = (ctx: CanvasContext) => {
  const gfx = ctx.graphics.gfx
  gfx.fillRect(0, 0, ctx.width, ctx.height)
  gfx.beginPath()
}

const end = (ctx: CanvasContext) => {
  const gfx = ctx.graphics.gfx
  gfx.stroke()
}

const render = (ctx: CanvasContext, args: [Position, Velocity]) => {
  const pos = args[0]
  const gfx = ctx.graphics.gfx
  gfx.rect(pos.x, pos.y, 1, 1)
}
