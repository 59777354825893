import { Entity, Init } from '@cog/ecs'
import { identity } from '@cog/func'

export class Relationship implements Init<Relationship> {
  constructor(
    public id: Entity = -1,
    public parent: Entity = -1,
    public children: Entity[] = [],
  ) {}

  public init(props: Relationship) {
    this.parent = props.parent
    this.id = props.id
    this.children = props.children.map(identity)
    return this
  }
}
