import { World } from '@cog/ecs'
import { Action, CanvasContext, Gui, Scene, Sequence, Transitions } from '@cog/ecs-canvas-2d-plugin'

import { FadeFromBlack, FadeToBlack, ReplaceCurrentScene } from '../../../Actions'
import { background, defaultIdleStyle, defaultModifiers } from '../../../Scenes/commonStyles'
import { SceneRegistry } from '../../../sceneRegistry'
import { BallProps } from '../Components/Ball'
import { BrickProps } from '../Components/Brick'
import { Material, Physics, Transform } from '../Components/Common'
import { PaddleProps } from '../Components/Paddle'
import { Ball, Brick, Paddle } from '../Components/Tags'
import { deleteBall, deleteBricks, deletePaddle, makeBall, makeBricks, makePaddle } from '../Entities'
import { cleanupDeadBricks } from '../Systems/brickCleanup'
import { resolveBallCollisions } from '../Systems/collisions'
import { integrate } from '../Systems/integrate'
import { updatePaddle } from '../Systems/paddleControls'
import { clearScreen, renderBall, renderBrick, renderPaddle } from '../Systems/render'


class InitArkanoid extends Action<CanvasContext> {
  public onStart(ctx: CanvasContext) {
    ctx.world.system.on.before([Transform, Physics, PaddleProps, Paddle], updatePaddle)
    ctx.world.system.on.before([Transform, Physics, BallProps], integrate)
    ctx.world.system.on.before([Transform, Physics, BallProps, Ball], resolveBallCollisions)
    this.finish()
  }
}

export class Game extends Scene<CanvasContext> {
  public create(world: World<CanvasContext>) {
    this.using(Gui, Transitions)

    const overlay = Gui.Element.Pane(world, { style: { ...background, idle: { ...background.idle, background: { color: 0xff }}} })

    world.context.actionManager.run(
      new Sequence([
        new FadeFromBlack(overlay),
        new InitArkanoid(),
      ]),
    )

    Gui.Element.Button(world, {
      onClick: () => {
        world.context.actionManager.run(
          new Sequence([
            new FadeToBlack(overlay),
            new ReplaceCurrentScene(SceneRegistry.MainMenu),
          ]),
        )
      },

      label: { text: '<-' },
      style: { ...defaultModifiers, idle: {
        ...defaultIdleStyle().idle,
        position: { left: 5, top: 5, right: 25, bottom: 25 },
      }},
    })
    // before update phase
    world.task.on.before(clearScreen)

    // update phase
    world.system.on.update([Transform, Material, PaddleProps, Paddle], renderPaddle)
    world.system.on.update([Transform, Material, BallProps, Ball], renderBall)
    world.system.on.update([Transform, Material, BrickProps, Brick], renderBrick)

    // after update phase
    world.task.on.after(cleanupDeadBricks)

    makeBall(world)
    makePaddle(world)
    makeBricks(world)
  }

  public delete(world: World<CanvasContext>) {
    world.task.delete(clearScreen)
    world.task.delete(cleanupDeadBricks)

    world.system.delete(updatePaddle)
    world.system.delete(integrate)
    world.system.delete(resolveBallCollisions)
    world.system.delete(renderPaddle)
    world.system.delete(renderBall)
    world.system.delete(renderBrick)

    deleteBall(world)
    deletePaddle(world)
    deleteBricks(world)
  }
}
