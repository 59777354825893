import { World } from '@cog/ecs'
import { CanvasContext, HEIGHT, WIDTH } from '@cog/ecs-canvas-2d-plugin'
import { Vec2 } from '@cog/math'

import { BallProps } from '../Components/Ball'
import { Material, Physics, Transform } from '../Components/Common'
import { Ball } from '../Components/Tags'


export const makeBall = (
  world: World<CanvasContext>,
  position: Vec2 = new Vec2(WIDTH >> 1, (HEIGHT >> 1)),
  physics: Physics = new Physics(new Vec2(0.0, 1.0), 120.0),
  props: BallProps = new BallProps(2.0),
  material: Material = new Material(255, 0, 0),
) => world.entity(
  [Transform, Physics, BallProps, Material, Ball],
  (args) => {
    args[0].position = position
    args[1].speed = physics.speed
    args[1].velocity = physics.velocity
    args[2].radius = props.radius
    args[3].r = material.r
    args[3].g = material.g
    args[3].b = material.b

  },
)

export const deleteBall = (world: World<CanvasContext>) =>
  world.deleteWhere([Transform, Physics, BallProps, Material, Ball], () => true)