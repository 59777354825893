import { Init } from '../../types'

const WHITE = 0xffffffff


export type BackgroundProps = { color: number, sprite: number }
export class Background implements Init<BackgroundProps> {
  public color!: number
  public sprite!: number

  constructor(props?: Partial<BackgroundProps>) {
    this.init(props)
  }

  public init(props?: Partial<BackgroundProps>) {
    this.color = props?.color ?? WHITE
    this.sprite = props?.sprite ?? -1
  }
}


export type BorderProps = { left: number, top: number, right: number, bottom: number, width: number, color: number }
export class Border implements Init<BorderProps> {
  public left!: number
  public top!: number
  public right!: number
  public bottom!: number
  public width!: number
  public color!: number

  constructor(props?: Partial<BorderProps>) {
    this.init(props)
  }

  public init(props?: Partial<BorderProps>) {
    this.left = props?.left ?? 0
    this.top = props?.top ?? 0
    this.right = props?.right ?? 0
    this.bottom = props?.bottom ?? 0
    this.width = props?.width ?? 0
    this.color = props?.color ?? WHITE
  }
}


export type FontProps = { color: number, atlas: number, letterSpacing: number }
export class Font implements Init<FontProps> {
  public atlas!: number
  public letterSpacing!: number
  public color!: number

  constructor(props?: Partial<FontProps>) {
    this.init(props)
  }

  public init(props?: Partial<FontProps>) {
    this.atlas = props?.atlas ?? -1
    this.letterSpacing = props?.letterSpacing ?? 0
    this.color = props?.color ?? WHITE
  }
}


export type MarginProps = { left: number, top: number, right: number, bottom: number }
export class Margin implements Init<MarginProps> {
  public left!: number
  public top!: number
  public right!: number
  public bottom!: number

  constructor(props?: Partial<MarginProps>) {
    this.init(props)
  }

  public init(props?: Partial<MarginProps>) {
    this.left = props?.left ?? 0
    this.top = props?.top ?? 0
    this.right = props?.right ?? 0
    this.bottom = props?.bottom ?? 0
  }
}


export type PaddingProps = { left: number, top: number, right: number, bottom: number }
export class Padding implements Init<PaddingProps> {
  public left!: number
  public top!: number
  public right!: number
  public bottom!: number

  constructor(props?: Partial<PaddingProps>) {
    this.init(props)
  }

  public init(props?: Partial<PaddingProps>) {
    this.left = props?.left ?? 0
    this.top = props?.top ?? 0
    this.right = props?.right ?? 0
    this.bottom = props?.bottom ?? 0
  }
}

export enum PositionMode {
  Absolute = 0,
  Relative,
}
export type PositionProps = {
  left: number,
  leftOffset: number,
  top: number,
  topOffset: number,
  right: number,
  rightOffset: number,
  bottom: number,
  bottomOffset: number,
  z: number,
  mode: PositionMode,
}
export class Position implements Init<PositionProps>{
  public left!: number
  public leftOffset!: number
  public top!: number
  public topOffset!: number
  public right!: number
  public rightOffset!: number
  public bottom!: number
  public bottomOffset!: number
  public z!: number
  public mode!: PositionMode

  constructor(props?: Partial<PositionProps>) {
    this.init(props)
  }

  public init(props?: Partial<PositionProps>) {
    this.left = props?.left ?? 0
    this.leftOffset = props?.leftOffset ?? 0
    this.top = props?.top ?? 0
    this.topOffset = props?.topOffset ?? 0
    this.right = props?.right ?? 0
    this.rightOffset = props?.rightOffset ?? 0
    this.bottom = props?.bottom ?? 0
    this.bottomOffset = props?.bottomOffset ?? 0
    this.z = props?.z ?? 0
    this.mode = props?.mode ?? PositionMode.Absolute
  }
}


export type AttributesProps = {
  position: Partial<PositionProps>,
  padding: Partial<PaddingProps>,
  margin: Partial<MarginProps>,
  background: Partial<BackgroundProps>,
  border: Partial<BorderProps>,
  font: Partial<FontProps>,
}
export class Attributes implements Init<AttributesProps> {
  public position: Position = new Position()
  public padding: Padding = new Padding()
  public margin: Margin = new Margin()
  public background: Background = new Background()
  public border: Border = new Border()
  public font: Font = new Font()

  constructor(props?: Partial<AttributesProps>) {
    this.init(props)
  }

  public init(props?: Partial<AttributesProps>) {
    this.position.init(props?.position)
    this.padding.init(props?.padding)
    this.margin.init(props?.margin)
    this.background.init(props?.background)
    this.border.init(props?.border)
    this.font.init(props?.font)
  }
}
