import { CanvasContext } from '../../Context/CanvasContext'
import { Label } from '../Components/Label'
import { State } from '../Components/State'
import { PositionMode } from '../Components/Style/Attributes'
import { Style } from '../Components/Style/Style'
import { GuiRepository } from '../GuiRepository'


const composite = document.createElement('canvas')
composite.width = 1024
composite.height = 1024
const compositeCtx = composite.getContext('2d')!
compositeCtx.clearRect(0, 0, 1024, 1024)

const mask = document.createElement('canvas')
mask.width = 1024
mask.height = 1024
const maskCtx = mask.getContext('2d')!
maskCtx.clearRect(0, 0, 1024, 1024)

export const renderElement = <C extends CanvasContext>(
  ctx: C,
  style: Style,
  state: State<C>,
  label: Label,
) => {
  const g = ctx.graphics.gfx

  let l = style.positionLeft
  let t = style.positionTop
  let r = style.positionRight
  let b = style.positionBottom

  if (style.positionMode === PositionMode.Relative) {
    const w = ctx.graphics.canvas.width|0
    const h = ctx.graphics.canvas.height|0

    l *= w
    r *= w
    t *= h
    b *= h
  }

  l |= 0
  r |= 0
  t |= 0
  b |= 0

  const x = l
  const y = t
  const w = r - l
  const h = b - t

  // NOTE: Render background nine-patch
  const maybePatch = GuiRepository.Get.NinePatchSprite(style.backgroundSprite)

  compositeCtx.clearRect(0, 0, w, h)
  maskCtx.clearRect(0, 0, w, h)
  const c = `rgba(${(style.backgroundColor >> 24) & 0xff}, ${(style.backgroundColor >> 16) & 0xff}, ${(style.backgroundColor >> 8) & 0xff}, ${(style.backgroundColor & 0xff) / 255.0})`
  compositeCtx.globalCompositeOperation = 'source-over'
  compositeCtx.fillStyle = c
  compositeCtx.fillRect(0, 0, w, h)

  if (maybePatch.isJust()) {
    const patch = maybePatch.extract()

    let dx = 0
    let dy = 0
    let sx = 0
    let sy = 0
    let width = 0
    let height = 0
    let fillWidth = 0
    let fillHeight = 0
    let filled = 0

    if (patch.left > 0 && patch.top > 0) {
      dx = /* x */ 0
      dy = /* y */ 0
      sx = 0
      sy = 0
      width = patch.left
      height = patch.top

      maskCtx.drawImage(patch.sprite, sx, sy, width, height, dx, dy, width, height)
    }

    if (patch.top > 0) {
      fillWidth = w - patch.left - patch.right
      filled = 0

      while (filled < fillWidth) {
        dx = /* x */ 0 + patch.left + filled
        dy = /* y */ 0
        sx = patch.left
        sy = 0
        width = Math.min(patch.sprite.width - patch.left - patch.right, fillWidth - filled)
        height = patch.top
        filled += width

        maskCtx.drawImage(patch.sprite, sx, sy, width, height, dx, dy, width, height)
      }
    }

    if (patch.right > 0 && patch.top > 0) {
      dx = /* x */ 0 + w - patch.right
      dy = /* y */ 0
      sx = patch.sprite.width - patch.right
      sy = 0
      width = patch.right
      height = patch.top

      maskCtx.drawImage(patch.sprite, sx, sy, width, height, dx, dy, width, height)
    }

    if (patch.left > 0 && patch.bottom > 0) {
      dx = /* x */ 0
      dy = /* y */ 0 + h - patch.bottom
      sx = 0
      sy = patch.sprite.height - patch.bottom
      width = patch.left
      height = patch.bottom

      maskCtx.drawImage(patch.sprite, sx, sy, width, height, dx, dy, width, height)
    }

    if (patch.bottom > 0) {
      fillWidth = w - patch.left - patch.right
      filled = 0

      while (filled < fillWidth) {
        dx = /* x */ 0 + patch.left + filled
        dy = /* y */ 0 + h - patch.bottom
        sx = patch.left
        sy = patch.sprite.height - patch.bottom
        width = Math.min(patch.sprite.width - patch.left - patch.right, fillWidth - filled)
        height = patch.bottom
        filled += width

        maskCtx.drawImage(patch.sprite, sx, sy, width, height, dx, dy, width, height)
      }
    }

    if (patch.right > 0 && patch.bottom > 0) {
      dx = /* x  */ 0 + w - patch.right
      dy = /* y  */ 0 + h - patch.bottom
      sx = patch.sprite.width - patch.right
      sy = patch.sprite.height - patch.bottom
      width = patch.right
      height = patch.top

      maskCtx.drawImage(patch.sprite, sx, sy, width, height, dx, dy, width, height)
    }

    if (patch.left > 0) {
      fillHeight = h - patch.top - patch.bottom
      filled = 0

      while (filled < fillHeight) {
        dx = /* x */ 0
        dy = /* y */ 0 + patch.top + filled
        sx = 0
        sy = patch.top
        width = patch.left
        height = Math.min(patch.sprite.height - patch.top - patch.bottom, fillHeight - filled)
        filled += height

        maskCtx.drawImage(patch.sprite, sx, sy, width, height, dx, dy, width, height)
      }
    }

    if (patch.right > 0) {
      fillHeight = h - patch.top - patch.bottom
      filled = 0

      while (filled < fillHeight) {
        dx = /* x */ 0 + w - patch.right
        dy = /* y */ 0 + patch.top + filled
        sx = patch.sprite.width - patch.right
        sy = patch.top
        width = patch.left
        height = Math.min(patch.sprite.height - patch.top - patch.bottom, fillHeight - filled)
        filled += height

        maskCtx.drawImage(patch.sprite, sx, sy, width, height, dx, dy, width, height)
      }
    }

    fillWidth = w - patch.left - patch.right
    fillHeight = h - patch.top - patch.bottom
    sx = patch.left
    sx = patch.top

    for (let filledHeight = 0; filledHeight < fillHeight;) {
      height = Math.min(patch.sprite.height - patch.top - patch.bottom, fillHeight - filledHeight)

      for (let filledWidth = 0; filledWidth < fillWidth;) {
        width = Math.min(patch.sprite.width - patch.left - patch.right, fillWidth - filledWidth)

        dx = /* x */ 0 + patch.left + filledWidth
        dy = /* y */ 0 + patch.top + filledHeight
        filledWidth += width

        maskCtx.drawImage(patch.sprite, sx, sy, width, height, dx, dy, width, height)
      }

      filledHeight += height
    }

    compositeCtx.globalCompositeOperation = 'destination-in'
    compositeCtx.drawImage(mask, 0, 0, w, h, 0, 0, w, h)
    compositeCtx.globalCompositeOperation = 'multiply'
    compositeCtx.drawImage(mask, 0, 0, w, h, 0, 0, w, h)
  }
  g.drawImage(composite, 0, 0, w, h, x, y, w, h)
  compositeCtx.clearRect(0, 0, w, h)
  maskCtx.clearRect(0, 0, w, h)

  // NOTE: Render font bitmap glyphs
  compositeCtx.clearRect(0, 0, w, h)
  maskCtx.clearRect(0, 0, w, h)
  const maybeFont = GuiRepository.Get.FontSprite(style.fontAtlas)
  if (maybeFont.isJust() && label.text.length > 0) {
    // TODO: implement proper line height, baseline, glyph offsets, clipping and text alignment
    // NOTE: assume left to right, align left and no justification
    const font = maybeFont.extract()
    const text = label.text
    const letterSpacing = style.fontLetterSpacing
    let px = x + style.paddingLeft
    let py = y + style.paddingTop

    const sprite = font.sprite
    const sw = sprite.width
    const sh = sprite.height
    const c = `rgba(${(style.fontColor >> 24) & 0xff}, ${(style.fontColor >> 16) & 0xff}, ${(style.fontColor >> 8) & 0xff}, ${(style.fontColor & 0xff) / 255.0})`
    compositeCtx.globalCompositeOperation = 'source-over'
    compositeCtx.fillStyle = c
    compositeCtx.fillRect(0, 0, sw, sh)
    compositeCtx.globalCompositeOperation = 'destination-in'
    compositeCtx.drawImage(sprite, 0, 0, sw, sh, 0, 0, sw, sh)
    compositeCtx.globalCompositeOperation = 'multiply'
    compositeCtx.drawImage(sprite, 0, 0, sw, sh, 0, 0, sw, sh)

    const glyph = font.atlas.fallback
    for (const char of text) {
      // TODO: bake-in the fallback glyph
      if (px >= l && px + glyph.w < l + w && py >= t && py + glyph.h < b) {
        const glyph = font.atlas[char] ?? font.atlas.fallback
        if (char !== '\n') {
          g.drawImage(composite, glyph.x, glyph.y, glyph.w, glyph.h, px, py, glyph.w, glyph.h)
          if (px + glyph.w > x + w - style.paddingLeft - style.paddingRight) {
            py += glyph.h // NOTE: this is not correct, font size should be read from font meta json and passed to font atlas
            px = x + style.paddingLeft
          } else {
            px += glyph.w + letterSpacing
          }
        } else {
          px = x + style.paddingLeft
          py += glyph.h // NOTE: this is not correct, font size should be read from font meta json and passed to font atlas
        }
      }
    }
  }
}
