import { Vec2 } from '@cog/math'
import { EndEffector2d } from './EndEffector2d'


export class Bone2d {
  public endEffector: EndEffector2d | null = null

  public parent: Bone2d | null = null
  public children: Bone2d[] = []

  public length: number

  public head: Vec2
  private _tail: Vec2

  private stagedTailPositions: Vec2[] = []
  private stagedHeadPositions: Vec2[] = []

  constructor(
    head: Vec2,
    offset: Vec2 = Vec2.Null()
  ) {
    this.head = head
    this._tail = this.head.add(offset)
    this.length = head.dist(this._tail)
  }

  public get tail() { return this._tail }
  public set tail(val) { this._tail = val }

  public setParent(bone: Bone2d | null) {
    this.parent = bone
    if (bone != null) {
      this.head = bone.tail
      this.length = this.head.dist(this.tail)
      bone.addChild(this)
    }
    return this
  }

  public stageHead(head: Vec2) {
    this.stagedHeadPositions.push(head)
  }

  public stageTail(tail: Vec2) {
    this.stagedTailPositions.push(tail)
  }

  public commit() {
    if (this.stagedTailPositions.length > 0) {
      let x = 0
      let y = 0
      for (let i = 0; i < this.stagedTailPositions.length; ++i) {
        x += this.stagedTailPositions[i].x
        y += this.stagedTailPositions[i].y
      }
      x /= this.stagedTailPositions.length
      y /= this.stagedTailPositions.length
      this.tail = new Vec2(x, y)

      this.stagedTailPositions = []
    }

    if (this.stagedHeadPositions.length > 0) {
      let x = 0
      let y = 0
      for (let i = 0; i < this.stagedHeadPositions.length; ++i) {
        x += this.stagedHeadPositions[i].x
        y += this.stagedHeadPositions[i].y
      }
      x /= this.stagedHeadPositions.length
      y /= this.stagedHeadPositions.length
      this.head = new Vec2(x, y)

      this.stagedHeadPositions = []
    }
  }

  public setEndEffector(endEffector: EndEffector2d) {
    this.endEffector = endEffector
    endEffector.addBone(this)
    return this
  }

  public addChild(bone: Bone2d) {
    if (this.children.find((j) => j === bone) == null) {
      this.children.push(bone)
      bone.setParent(this)
    }
    return this
  }

  public removeChild(bone: Bone2d) {
    this.children = this.children.filter((j) => j !== bone)
    return this
  }
}
