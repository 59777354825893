import { Context } from '@cog/ecs'

import { Transition } from '../Components/Transition'


export const cleanupDeadTransitions = <C extends Context>(ctx: C) => {
  ctx.world.deleteWhere([Transition], (args) => {
    const transition = args[0]

    const shouldDelete = (
      transition.repeats !== 0 && transition.loopCount >= transition.repeats ||
      transition.type === 0 && transition.current >= transition.total
    )

    if (shouldDelete && transition.onEnd != null) {
      transition.onEnd(transition.reference)
    }

    return shouldDelete
  })
}
