import { Vec2 } from '@cog/math'

export enum CellType {
  Water     = 0,
  Ground    = 1,
  Coastline = 2,
}

export class Cell {
  constructor(
    public type: CellType,
    public position: Vec2,
    public color: string, // TODO: this should be a tile reference in the future
  ) {}
}
