import { IApplicative, ICaseOf, IFunctor, IMonad } from '../control'

enum EitherTypes {
  Right = 'Right',
  Left = 'Left',
}

interface IEitherPatterns<T, S, L, R> {
  Left: (val: S) => L | Either<L, R>
  Right: (val: T) => R | Either<L, R>
}

export abstract class Either<S, T> implements IFunctor<T>, IApplicative<T>, IMonad<T>, ICaseOf<T> {
  readonly tag: string = 'Either'
  abstract map<R = T>(f: (val: T) => R): Either<S, R>
  abstract ap<R = T>(f: Either<S, (val: T) => R>): Either<S, R>
  abstract chain<R = T>(f: (val: T) => Either<S, R>): Either<S, R>
  abstract caseOf<L = T, R = S>(patterns: IEitherPatterns<T, S, L, R>): Either<L, R>
  abstract extract(): Readonly<T>

  isLeft() { return this.tag === EitherTypes.Left }
  isRight() { return this.tag === EitherTypes.Right }
}

export class Left<S, T> extends Either<S, T> {
  static of<L, R>(val: L) {
    return new Left<L, R>(val)
  }

  readonly tag: string = EitherTypes.Left
  readonly val: S

  constructor(val: S) {
    super()
    this.val = val
  }

  map<R = T>(f: (val: T) => R): Either<S, R> {
    return (this as any) as Left<S, R>
  }

  ap<R = T>(f: Either<S, (val: T) => R>): Either<S, R> {
    return (this as any) as Left<S, R>
  }

  chain<R = T>(f: (val: T) => Either<S, R>): Either<S, R> {
    return (this as any) as Left<S, R>
  }

  caseOf<L = T, R = S>(patterns: IEitherPatterns<T, S, L, R>): Either<L, R> {
    const ret = patterns.Left(this.val)
    return ret instanceof Either ? ret : new Left(ret)
  }

  extract(): Readonly<T> {
    throw new Error('Can\'t extract a value from Left')
  }
}

export class Right<S, T> extends Either<S, T> {
  static of<L, R>(val: R) {
    return new Right<L, R>(val)
  }

  readonly tag: string = EitherTypes.Right
  readonly val: T

  constructor(val: T) {
    super()
    this.val = val
  }

  map<R = T>(f: (val: T) => R): Either<S, R> {
    return Right.of(f(this.val))
  }

  ap<R = T>(f: Either<S, (val: T) => R>): Either<S, R> {
    if (f.isLeft()) {
      return (f as any) as Left<S, R>
    } else {
      return Right.of<S, R>((f as Right<S, (val: T) => R>).val(this.val))
    }
  }

  chain<R = T>(f: (val: T) => Either<S, R>): Either<S, R> {
    return f(this.val) as Either<S, R>
  }

  caseOf<L = T, R = S>(patterns: IEitherPatterns<T, S, L, R>): Either<L, R> {
    const ret = patterns.Right(this.val)
    return ret instanceof Either ? ret : new Right(ret)
  }

  extract(): Readonly<T> {
    return this.val
  }
}
