import { Init } from '../types'


type LabelProps = { text: string }
export class Label implements Init<LabelProps> {
  public text!: string

  constructor(props?: Partial<LabelProps>) {
    this.init(props)
  }

  public init(props?: Partial<LabelProps>) {
    this.text = props?.text ?? ''
  }
}
