import { Mat2 } from './Mat2'
import { Vec2 } from './Vec2'

const SQRT_3 = Math.sqrt(3)
const TRANSFORM_FROM_CUBE_TO_R2 = new Mat2(
  SQRT_3, SQRT_3 * 0.5,
       0,         -1.5,
).transpose()

const TRANSFORM_FROM_R2_TO_CUBE = new Mat2(
  SQRT_3 / 3,  1 / 3,
           0, -2 / 3,
).transpose()

export class HexVec {
  static Rounded = (vec: HexVec): HexVec => {
    const rx = Math.round(vec.x)
    const ry = Math.round(vec.y)
    const rz = Math.round(vec.z)

    const dx = Math.abs(rx - vec.x)
    const dy = Math.abs(ry - vec.y)
    const dz = Math.abs(rz - vec.z)

    if (dx > dy && dx > dz) {
      return new HexVec(-ry -rz, ry)
    } else if (dy > dz) {
      return new HexVec(rx, -rx -rz)
    } else {
      return new HexVec(rx, ry)
    }
  }

  static FromVec2 = (vec: Vec2, cellRadius: number): HexVec => {
    const transformed = TRANSFORM_FROM_R2_TO_CUBE
      .transform(vec)
      .scale(1 / cellRadius)

    return HexVec.Rounded(new HexVec(transformed.x, transformed.y))
  }

  public readonly z: number

  constructor(
    public readonly x: number = 0.0,
    public readonly y: number = 0.0,
  ) {
    this.z = (- this.x - this.y)
  }

  public dist(other: HexVec) {
    const dx = Math.abs(other.x - this.x)
    const dy = Math.abs(other.y - this.y)
    const dz = Math.abs(other.z - this.z)

    // NOTE: same as Math.max(dx, dy, dz)
    return (dx + dy + dz) >> 1
  }

  public len() {
    return (Math.abs(this.x) + Math.abs(this.y) + Math.abs(this.z)) >> 1
  }

  public add(other: HexVec) {
    return new HexVec(this.x + other.x, this.y + other.y)
  }

  public sub(other: HexVec) {
    return new HexVec(this.x - other.x, this.y - other.y)
  }

  public scale(factor: number) {
    return new HexVec(this.x * factor, this.y * factor)
  }

  public toVec2(cellRadius: number) {
    return TRANSFORM_FROM_CUBE_TO_R2
      .transform(new Vec2(this.x, this.y))
      .scale(cellRadius)
  }

  public equals(other: HexVec) {
    return this === other || this.x === other.x && this.y === other.y
  }
}