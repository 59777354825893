import { World } from '@cog/ecs'
import { CanvasContext, HEIGHT, WIDTH } from '@cog/ecs-canvas-2d-plugin'
import { AABRect, Vec2 } from '@cog/math'

import { BrickProps } from '../Components/Brick'
import { Material, Transform } from '../Components/Common'
import { Brick } from '../Components/Tags'


export const makeBricks = (world: World<CanvasContext>) => {
  const brickWidth = WIDTH / 20.0
  const brickHeight = HEIGHT / 30.0
  const brickOffset = new Vec2(brickWidth, brickHeight)

  for (let i = 0; i < 9; ++i) {
    for (let j = 0; j < 20; ++j) {
      world.entity(
        [Transform, BrickProps, Material, Brick],
        (args) => {
          const position = new Vec2(j * brickWidth, i * brickHeight)
          const aabr = new AABRect(position, position.add(brickOffset))
          const material = new Material(0, 255, 0)

          args[0].position = position
          args[1].aabr = aabr
          args[2].r = material.r
          args[2].g = material.g
          args[2].b = material.b
        },
      )
    }
  }
}

export const deleteBricks = (world: World<CanvasContext>) =>
  world.deleteWhere([Transform, BrickProps, Material, Brick], () => true)