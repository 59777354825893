import {
  PositionMode,
  Styles,
} from '@cog/ecs-canvas-2d-plugin'
import { Resource } from '../Resource'

export const defaultIdleStyle = (): Styles => ({
  idle: {
    background: {
      sprite: Resource.Sprite.NinePatch.DefaultButton
    },
    border: {
      bottom: 1,
      color: 0xffffff20,
      left: 1,
      right: 1,
      top: 1,
    },
    font: {
      atlas: Resource.Sprite.FontAtlas.DefaultFont,
      color: 0x404040ff,
      letterSpacing: -3,
    },
    padding: {
      bottom: 3,
      left: 3,
      right: 3,
      top: 5,
    }
  }
})

export const defaultModifiers: Styles = {
  active: { background: { color: 0xeeeeeeff }, position: { topOffset: 1, bottomOffset: 1 } },
  disabled: { background: { color: 0x999999ff } },
}


export const background: Styles = {
  idle: {
    background: {
      color: 0xffffffff
    },
    position: {
      bottom: 1.0,
      mode: PositionMode.Relative,
      right: 1.0,
      z: 5,
    },
  },
}

export const splashText = (): Styles => ({
  idle: {
    background: {
      color: 0x0,
    },
    font: {
      atlas: Resource.Sprite.FontAtlas.DefaultFont,
      color: 0xff,
    },
    position: {
      bottom: 1.0,
      left: 0.2,
      mode: PositionMode.Relative,
      right: 1.0,
      top: 0.45,
      z: 6,
    },
  },
})

export const loadingBar: Styles = {
  idle: {
    background: {
      color: 0x0,
    },
    position: {
      bottom: 1.0,
      left: 0.0,
      mode: PositionMode.Relative,
      right: 1.0,
      top: 0.95,
      z: 10,
    },
  },
}
