import { identity } from './func'
import { fn1, fn10, fn11, fn12, fn2, fn3, fn4, fn5, fn6, fn7, fn8, fn9 } from './typeUtils'

type Pipe =
  & (() => (<r>(x: r) => r))
  & (<a0, r>(m0: fn1<a0, r>) => fn1<a0, r>)

  & (<a0, a1, r>(m1: fn1<a0, a1>, m0: fn1<a1, r>) => fn1<a0, r>)
  & (<a0, a1, r>(m0: fn2<a0, a1, r>) => fn2<a0, a1, r>)

  & (<a0, a1, a2, r>(m2: fn1<a0, a1>, m1: fn1<a1, a2>, m0: fn1<a2, r>) => fn1<a0, r>)
  & (<a0, a1, a2, r>(m1: fn2<a0, a1, a2>, m0: fn1<a2, r>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, r>(m0: fn3<a0, a1, a2, r>) => fn3<a0, a1, a2, r>)

  & (<a0, a1, a2, a3, r>(m3: fn1<a0, a1>, m2: fn1<a1, a2>, m1: fn1<a2, a3>, m0: fn1<a3, r>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, r>(m2: fn2<a0, a1, a2>, m1: fn1<a2, a3>, m0: fn1<a3, r>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, r>(m1: fn3<a0, a1, a2, a3>, m0: fn1<a3, r>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, r>(m0: fn4<a0, a1, a2, a3, r>) => fn4<a0, a1, a2, a3, r>)

  & (<a0, a1, a2, a3, a4, r>(m4: fn1<a0, a1>, m3: fn1<a1, a2>, m2: fn1<a2, a3>, m1: fn1<a3, a4>, m0: fn1<a4, r>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, r>(m3: fn2<a0, a1, a2>, m2: fn1<a2, a3>, m1: fn1<a3, a4>, m0: fn1<a4, r>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, r>(m2: fn3<a0, a1, a2, a3>, m1: fn1<a3, a4>, m0: fn1<a4, r>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, r>(m1: fn4<a0, a1, a2, a3, a4>, m0: fn1<a4, r>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, r>(m0: fn5<a0, a1, a2, a3, a4, r>) => fn5<a0, a1, a2, a3, a4, r>)

  & (<a0, a1, a2, a3, a4, a5, r>(m5: fn1<a0, a1>, m4: fn1<a1, a2>, m3: fn1<a2, a3>, m2: fn1<a3, a4>, m1: fn1<a4, a5>, m0: fn1<a5, r>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, a5, r>(m4: fn2<a0, a1, a2>, m3: fn1<a2, a3>, m2: fn1<a3, a4>, m1: fn1<a4, a5>, m0: fn1<a5, r>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, a5, r>(m3: fn3<a0, a1, a2, a3>, m2: fn1<a3, a4>, m1: fn1<a4, a5>, m0: fn1<a5, r>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, a5, r>(m2: fn4<a0, a1, a2, a3, a4>, m1: fn1<a4, a5>, m0: fn1<a5, r>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, a5, r>(m1: fn5<a0, a1, a2, a3, a4, a5>, m0: fn1<a5, r>) => fn5<a0, a1, a2, a3, a4, r>)
  & (<a0, a1, a2, a3, a4, a5, r>(m0: fn6<a0, a1, a2, a3, a4, a5, r>) => fn6<a0, a1, a2, a3, a4, a5, r>)

  & (<a0, a1, a2, a3, a4, a5, a6, r>(m6: fn1<a0, a1>, m5: fn1<a1, a2>, m4: fn1<a2, a3>, m3: fn1<a3, a4>, m2: fn1<a4, a5>, m1: fn1<a5, a6>, m0: fn1<a6, r>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, r>(m5: fn2<a0, a1, a2>, m4: fn1<a2, a3>, m3: fn1<a3, a4>, m2: fn1<a4, a5>, m1: fn1<a5, a6>, m0: fn1<a6, r>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, r>(m4: fn3<a0, a1, a2, a3>, m3: fn1<a3, a4>, m2: fn1<a4, a5>, m1: fn1<a5, a6>, m0: fn1<a6, r>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, r>(m3: fn4<a0, a1, a2, a3, a4>, m2: fn1<a4, a5>, m1: fn1<a5, a6>, m0: fn1<a6, r>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, r>(m2: fn5<a0, a1, a2, a3, a4, a5>, m1: fn1<a5, a6>, m0: fn1<a6, r>) => fn5<a0, a1, a2, a3, a4, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, r>(m1: fn6<a0, a1, a2, a3, a4, a5, a6>, m0: fn1<a6, r>) => fn6<a0, a1, a2, a3, a4, a5, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, r>(m0: fn7<a0, a1, a2, a3, a4, a5, a6, r>) => fn7<a0, a1, a2, a3, a4, a5, a6, r>)

  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m7: fn1<a0, a1>, m6: fn1<a1, a2>, m5: fn1<a2, a3>, m4: fn1<a3, a4>, m3: fn1<a4, a5>, m2: fn1<a5, a6>, m1: fn1<a6, a7>, m0: fn1<a7, r>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m6: fn2<a0, a1, a2>, m5: fn1<a2, a3>, m4: fn1<a3, a4>, m3: fn1<a4, a5>, m2: fn1<a5, a6>, m1: fn1<a6, a7>, m0: fn1<a7, r>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m5: fn3<a0, a1, a2, a3>, m4: fn1<a3, a4>, m3: fn1<a4, a5>, m2: fn1<a5, a6>, m1: fn1<a6, a7>, m0: fn1<a7, r>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m4: fn4<a0, a1, a2, a3, a4>, m3: fn1<a4, a5>, m2: fn1<a5, a6>, m1: fn1<a6, a7>, m0: fn1<a7, r>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m3: fn5<a0, a1, a2, a3, a4, a5>, m2: fn1<a5, a6>, m1: fn1<a6, a7>, m0: fn1<a7, r>) => fn5<a0, a1, a2, a3, a4, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m2: fn6<a0, a1, a2, a3, a4, a5, a6>, m1: fn1<a6, a7>, m0: fn1<a7, r>) => fn6<a0, a1, a2, a3, a4, a5, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m1: fn7<a0, a1, a2, a3, a4, a5, a6, a7>, m0: fn1<a7, r>) => fn7<a0, a1, a2, a3, a4, a5, a6, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, r>(m0: fn8<a0, a1, a2, a3, a4, a5, a6, a7, r>) => fn8<a0, a1, a2, a3, a4, a5, a6, a7, r>)

  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m8: fn1<a0, a1>, m7: fn1<a1, a2>, m6: fn1<a2, a3>, m5: fn1<a3, a4>, m4: fn1<a4, a5>, m3: fn1<a5, a6>, m2: fn1<a6, a7>, m1: fn1<a7, a8>, m0: fn1<a8, r>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m7: fn2<a0, a1, a2>, m6: fn1<a2, a3>, m5: fn1<a3, a4>, m4: fn1<a4, a5>, m3: fn1<a5, a6>, m2: fn1<a6, a7>, m1: fn1<a7, a8>, m0: fn1<a8, r>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m6: fn3<a0, a1, a2, a3>, m5: fn1<a3, a4>, m4: fn1<a4, a5>, m3: fn1<a5, a6>, m2: fn1<a6, a7>, m1: fn1<a7, a8>, m0: fn1<a8, r>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m5: fn4<a0, a1, a2, a3, a4>, m4: fn1<a4, a5>, m3: fn1<a5, a6>, m2: fn1<a6, a7>, m1: fn1<a7, a8>, m0: fn1<a8, r>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m4: fn5<a0, a1, a2, a3, a4, a5>, m3: fn1<a5, a6>, m2: fn1<a6, a7>, m1: fn1<a7, a8>, m0: fn1<a8, r>) => fn5<a0, a1, a2, a3, a4, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m3: fn6<a0, a1, a2, a3, a4, a5, a6>, m2: fn1<a6, a7>, m1: fn1<a7, a8>, m0: fn1<a8, r>) => fn6<a0, a1, a2, a3, a4, a5, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m2: fn7<a0, a1, a2, a3, a4, a5, a6, a7>, m1: fn1<a7, a8>, m0: fn1<a8, r>) => fn7<a0, a1, a2, a3, a4, a5, a6, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m1: fn8<a0, a1, a2, a3, a4, a5, a6, a7, a8>, m0: fn1<a8, r>) => fn8<a0, a1, a2, a3, a4, a5, a6, a7, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>(m0: fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>) => fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>)

  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m9: fn1<a0, a1>, m8: fn1<a1, a2>, m7: fn1<a2, a3>, m6: fn1<a3, a4>, m5: fn1<a4, a5>, m4: fn1<a5, a6>, m3: fn1<a6, a7>, m2: fn1<a7, a8>, m1: fn1<a8, a9>, m0: fn1<a9, r>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m8: fn2<a0, a1, a2>, m7: fn1<a2, a3>, m6: fn1<a3, a4>, m5: fn1<a4, a5>, m4: fn1<a5, a6>, m3: fn1<a6, a7>, m2: fn1<a7, a8>, m1: fn1<a8, a9>, m0: fn1<a9, r>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m7: fn3<a0, a1, a2, a3>, m6: fn1<a3, a4>, m5: fn1<a4, a5>, m4: fn1<a5, a6>, m3: fn1<a6, a7>, m2: fn1<a7, a8>, m1: fn1<a8, a9>, m0: fn1<a9, r>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m6: fn4<a0, a1, a2, a3, a4>, m5: fn1<a4, a5>, m4: fn1<a5, a6>, m3: fn1<a6, a7>, m2: fn1<a7, a8>, m1: fn1<a8, a9>, m0: fn1<a9, r>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m5: fn5<a0, a1, a2, a3, a4, a5>, m4: fn1<a5, a6>, m3: fn1<a6, a7>, m2: fn1<a7, a8>, m1: fn1<a8, a9>, m0: fn1<a9, r>) => fn5<a0, a1, a2, a3, a4, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m4: fn6<a0, a1, a2, a3, a4, a5, a6>, m3: fn1<a6, a7>, m2: fn1<a7, a8>, m1: fn1<a8, a9>, m0: fn1<a9, r>) => fn6<a0, a1, a2, a3, a4, a5, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m3: fn7<a0, a1, a2, a3, a4, a5, a6, a7>, m2: fn1<a7, a8>, m1: fn1<a8, a9>, m0: fn1<a9, r>) => fn7<a0, a1, a2, a3, a4, a5, a6, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m2: fn8<a0, a1, a2, a3, a4, a5, a6, a7, a8>, m1: fn1<a8, a9>, m0: fn1<a9, r>) => fn8<a0, a1, a2, a3, a4, a5, a6, a7, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m1: fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9>, m0: fn1<a9, r>) => fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>(m0: fn10<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>) => fn10<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>)

  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m10: fn1<a0, a1>, m9: fn1<a1, a2>, m8: fn1<a2, a3>, m7: fn1<a3, a4>, m6: fn1<a4, a5>, m5: fn1<a5, a6>, m4: fn1<a6, a7>, m3: fn1<a7, a8>, m2: fn1<a8, a9>, m1: fn1<a9, a10>, m0: fn1<a10, r>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m9: fn2<a0, a1, a2>, m8: fn1<a2, a3>, m7: fn1<a3, a4>, m6: fn1<a4, a5>, m5: fn1<a5, a6>, m4: fn1<a6, a7>, m3: fn1<a7, a8>, m2: fn1<a8, a9>, m1: fn1<a9, a10>, m0: fn1<a10, r>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m8: fn3<a0, a1, a2, a3>, m7: fn1<a3, a4>, m6: fn1<a4, a5>, m5: fn1<a5, a6>, m4: fn1<a6, a7>, m3: fn1<a7, a8>, m2: fn1<a8, a9>, m1: fn1<a9, a10>, m0: fn1<a10, r>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m7: fn4<a0, a1, a2, a3, a4>, m6: fn1<a4, a5>, m5: fn1<a5, a6>, m4: fn1<a6, a7>, m3: fn1<a7, a8>, m2: fn1<a8, a9>, m1: fn1<a9, a10>, m0: fn1<a10, r>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m6: fn5<a0, a1, a2, a3, a4, a5>, m5: fn1<a5, a6>, m4: fn1<a6, a7>, m3: fn1<a7, a8>, m2: fn1<a8, a9>, m1: fn1<a9, a10>, m0: fn1<a10, r>) => fn5<a0, a1, a2, a3, a4, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m5: fn6<a0, a1, a2, a3, a4, a5, a6>, m4: fn1<a6, a7>, m3: fn1<a7, a8>, m2: fn1<a8, a9>, m1: fn1<a9, a10>, m0: fn1<a10, r>) => fn6<a0, a1, a2, a3, a4, a5, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m4: fn7<a0, a1, a2, a3, a4, a5, a6, a7>, m3: fn1<a7, a8>, m2: fn1<a8, a9>, m1: fn1<a9, a10>, m0: fn1<a10, r>) => fn7<a0, a1, a2, a3, a4, a5, a6, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m3: fn8<a0, a1, a2, a3, a4, a5, a6, a7, a8>, m2: fn1<a8, a9>, m1: fn1<a9, a10>, m0: fn1<a10, r>) => fn8<a0, a1, a2, a3, a4, a5, a6, a7, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m2: fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9>, m1: fn1<a9, a10>, m0: fn1<a10, r>) => fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m1: fn10<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10>, m0: fn1<a10, r>) => fn10<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>(m0: fn11<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>) => fn11<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>)

  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m11: fn1<a0, a1>, m10: fn1<a1, a2>, m9: fn1<a2, a3>, m8: fn1<a3, a4>, m7: fn1<a4, a5>, m6: fn1<a5, a6>, m5: fn1<a6, a7>, m4: fn1<a7, a8>, m3: fn1<a8, a9>, m2: fn1<a9, a10>, m1: fn1<a10, a11>, m0: fn1<a11, r>) => fn1<a0, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m10: fn2<a0, a1, a2>, m9: fn1<a2, a3>, m8: fn1<a3, a4>, m7: fn1<a4, a5>, m6: fn1<a5, a6>, m5: fn1<a6, a7>, m4: fn1<a7, a8>, m3: fn1<a8, a9>, m2: fn1<a9, a10>, m1: fn1<a10, a11>, m0: fn1<a11, r>) => fn2<a0, a1, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m9: fn3<a0, a1, a2, a3>, m8: fn1<a3, a4>, m7: fn1<a4, a5>, m6: fn1<a5, a6>, m5: fn1<a6, a7>, m4: fn1<a7, a8>, m3: fn1<a8, a9>, m2: fn1<a9, a10>, m1: fn1<a10, a11>, m0: fn1<a11, r>) => fn3<a0, a1, a2, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m8: fn4<a0, a1, a2, a3, a4>, m7: fn1<a4, a5>, m6: fn1<a5, a6>, m5: fn1<a6, a7>, m4: fn1<a7, a8>, m3: fn1<a8, a9>, m2: fn1<a9, a10>, m1: fn1<a10, a11>, m0: fn1<a11, r>) => fn4<a0, a1, a2, a3, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m7: fn5<a0, a1, a2, a3, a4, a5>, m6: fn1<a5, a6>, m5: fn1<a6, a7>, m4: fn1<a7, a8>, m3: fn1<a8, a9>, m2: fn1<a9, a10>, m1: fn1<a10, a11>, m0: fn1<a11, r>) => fn5<a0, a1, a2, a3, a4, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m6: fn6<a0, a1, a2, a3, a4, a5, a6>, m5: fn1<a6, a7>, m4: fn1<a7, a8>, m3: fn1<a8, a9>, m2: fn1<a9, a10>, m1: fn1<a10, a11>, m0: fn1<a11, r>) => fn6<a0, a1, a2, a3, a4, a5, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m5: fn7<a0, a1, a2, a3, a4, a5, a6, a7>, m4: fn1<a7, a8>, m3: fn1<a8, a9>, m2: fn1<a9, a10>, m1: fn1<a10, a11>, m0: fn1<a11, r>) => fn7<a0, a1, a2, a3, a4, a5, a6, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m4: fn8<a0, a1, a2, a3, a4, a5, a6, a7, a8>, m3: fn1<a8, a9>, m2: fn1<a9, a10>, m1: fn1<a10, a11>, m0: fn1<a11, r>) => fn8<a0, a1, a2, a3, a4, a5, a6, a7, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m3: fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9>, m2: fn1<a9, a10>, m1: fn1<a10, a11>, m0: fn1<a11, r>) => fn9<a0, a1, a2, a3, a4, a5, a6, a7, a8, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m2: fn10<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10>, m1: fn1<a10, a11>, m0: fn1<a11, r>) => fn10<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m1: fn11<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11>, m0: fn1<a11, r>) => fn11<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, r>)
  & (<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>(m0: fn12<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>) => fn12<a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, r>)

const pipeImpl: any = (...f: any[]) => {
  if (f.length === 0) {
    return identity
  } else if (f.length === 1) {
    return f[0]
  } else {
    return f.reduce((a, b) => (...args: any[]) => b(a(...args)))
  }
}
export const pipe: Pipe = pipeImpl
